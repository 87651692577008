/**
  Create encapsulated group of dom nodes (ie a component) based on selectors
  and executes a callback for each occuring component, based on `component` selector.

  Components have a parent selector and optionally child selectors.

  Components can exist multiple time in the dom and can be nested

  Useful for creating javascript functions tied to components when not using
  a js framework such as React.

  Example:
  ```
  ui({
    component: '.js-component',
    children: {
      el: '.js-componentChild', // keys are arbitrary
      els: ['.js-componentChildren'] // executes querySelectorAll, returns array
    },
    container: document // optional. can be a any dom node, default to document
  }, ({ component, el, els }) => {
    // executed for each occurance of .js-component
    console.log(component instanceof HTMLElement) // true
    console.log(el instanceof HTMLElement) // true if .js-componentChild exists
    console.log(els instanceof Array) // true if at least one .js-componentChildren exists: HTMLElement[]
  })
  ```
*/

type ChildSelectors = undefined | { [key: string]: string | string[] }
type ChildElements = HTMLElement[] | HTMLElement
type Children = { [key: string]: ChildElements }
type Callback<C extends Children> = (args: { component: HTMLElement } & C) => void
type Props = {
  component: string
  children?: ChildSelectors
  container?: HTMLElement | HTMLDocument
}

export function ui<C extends Children>(props: Props, callback: Callback<C>) {
  if (!props.component) throw new Error('ui expects at least a `component` selector')

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', (_) => ready(props, callback))
  } else {
    ready(props, callback) // `DOMContentLoaded` already fired
  }
}

function ready<C extends Children>(
  { component: componentSelector, children: childSelectors, container = document }: Props,
  callback: Callback<C>
) {
  const componentNodes = container.querySelectorAll<HTMLElement>(componentSelector)

  componentNodes.forEach((componentNode) => {
    const children = getChildElements({ componentNode, componentSelector, childSelectors }) as C
    callback({ component: componentNode, ...children })
  })
}

type GetChildNodeProps = {
  componentNode: HTMLElement
  componentSelector: string
  childSelectors?: ChildSelectors
}
function getChildElements<C extends Children>({
  componentNode,
  componentSelector,
  childSelectors,
}: GetChildNodeProps): C {
  if (!childSelectors) return {} as C
  const nestedComponentNode = componentNode.querySelector<HTMLElement>(componentSelector)
  return Object.keys(childSelectors).reduce((result, key) => {
    const selector = childSelectors[key]
    const nodes = Array.isArray(selector)
      ? getChildren({ selector, componentNode, nestedComponentNode })
      : componentNode.querySelector(selector as string)

    return { ...result, [key]: nodes }
  }, {}) as C
}

type GetChildrenProps = {
  selector: string[]
  componentNode: HTMLElement
  nestedComponentNode: HTMLElement | null
}
function getChildren({
  selector,
  componentNode,
  nestedComponentNode,
}: GetChildrenProps): HTMLElement[] {
  return [
    ...Array.from(componentNode.querySelectorAll<HTMLElement>(`${selector[0]}`)).filter(
      (x) => !nestedComponentNode || !nestedComponentNode.contains(x)
    ),
  ]
}
