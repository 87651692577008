import clsx from 'clsx'
import React, { ChangeEvent, FocusEvent, KeyboardEvent } from 'react'

type Props = {
  label: string
  id: string
  name: string
  type?: string
  maxLength?: number
  value?: string
  className?: string
  inputClassName?: string
  endAdornment?: React.ReactNode
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void
}

const InputText = ({
  label,
  id,
  name,
  type = 'text',
  maxLength,
  value,
  onChange,
  onKeyPress,
  className,
  inputClassName,
  onBlur,
  endAdornment,
}: Props) => (
  <div className={clsx('form-group', className)}>
    <label className="input-label" htmlFor={id}>
      {label}
    </label>
    <div className="input-group">
      <input
        className={clsx('form-control input-text', inputClassName)}
        id={id}
        name={name}
        type={type}
        min={type == 'number' ? '1' : null}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
      />
      <div className="input-group__endAdornment">{endAdornment}</div>
    </div>
    <div className="invalid-feedback"></div>
  </div>
)

export default InputText
