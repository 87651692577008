import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import FinanceTable from '~src/apps/Occasion/FinanceTable'
import PaymentPlanSummary from '~src/apps/Occasion/PaymentPlanSummary'
import OccasionPriceSummary from '~src/apps/Occasion/PriceSummary'
import CarSpecificationsSummary from '~src/apps/TradeIn/CarSpecificationsSummary'
import TradeInModal from '~src/apps/TradeIn/TradeInModal'
import { useModel, useRootState } from '~src/hooks/store'
import { setOccasion } from '~src/store/api/actions'
import { IOccasion } from '~src/store/api/types'
import { setTradeInCar, setTradeInModalStatus } from '~src/store/trade-in/actions'
import {
  DEFAULT_FINAL_PAYMENT_PERCENTAGE,
  findFinancialConfig,
  findPaymentPlan,
  getDefaultDownpaymentPercentage,
  getMonthlyCreditPayment,
} from '~src/utils/price'
import ButtonArrow from '~svg/button-arrow.svg'
import IconCarTradeIn from '~svg/car-trade-in.svg'
import IconFinance from '~svg/finance.svg'
import IconRemove from '~svg/remove.svg'

type Props = {
  licensePlateApiUrl: string
  louwmanHubBrand: string
  financeApiUrl: string
  occasionData: IOccasion
  apiRootUrl: string
}

const OccasionOptions = ({
  louwmanHubBrand,
  licensePlateApiUrl,
  financeApiUrl,
  occasionData,
  apiRootUrl,
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const model = useModel()
  const {
    api: { financialProducts, usingFinancialPlan, occasion },
    tradeIn: { oldCar },
    configuration,
  } = useRootState()

  const occasionAdded = oldCar && oldCar.isCompleted

  const totalPrice = Number(occasion?.price_online)

  const downPayment =
    configuration.basic.downPayment > 0
      ? configuration.basic.downPayment
      : Math.floor(totalPrice * getDefaultDownpaymentPercentage(configuration.productGroup))

  const credit = totalPrice - downPayment

  const finalPayment =
    configuration.basic.finalPayment > 0
      ? configuration.basic.finalPayment
      : credit * DEFAULT_FINAL_PAYMENT_PERCENTAGE

  const product =
    financialProducts && findPaymentPlan(financialProducts, configuration.productGroup, model)
  const financialConfig = product && findFinancialConfig(credit, product, configuration)
  const monthlyPayment =
    financialConfig &&
    getMonthlyCreditPayment(
      credit,
      Number(financialConfig.interest_rate) / 100,
      finalPayment,
      configuration.basic.durationMonths
    )

  useEffect(() => {
    // Put occasion data from prop in store
    if (occasionData) {
      dispatch(setOccasion(occasionData))
    }

    // Put trade-in data from session in store
    const tradeInSession = JSON.parse(sessionStorage.getItem('trade_in'))
    if (tradeInSession) {
      dispatch(setTradeInCar(tradeInSession))
    }
  }, [])

  // Set tradein/finance data in form
  const updateDomForms = () => {
    const leadModal = document.querySelector('#modal-occasion-lead')
    const testDriveModal = document.querySelector('#modal-occasion-test-drive')

    // Show/hide financing title
    const financeText: HTMLElement = document.getElementById(
      'occasion_lead_form_car_insurance_text'
    )
    if (usingFinancialPlan) {
      financeText.classList.remove('d-none')
    } else {
      financeText.classList.add('d-none')
    }

    // Update hidden fields with values
    const tradeInData = [
      ['trade_in', !!occasionAdded],
      ['trade_in_license_plate', oldCar?.license_plate],
      ['trade_in_mileage', oldCar?.mileage],
      ['trade_in_brand', oldCar?.brand],
      ['trade_in_model', oldCar?.model],
      ['trade_in_version', oldCar?.version],
      ['trade_in_smokers_car', oldCar?.isSmokeCar],
      ['trade_in_dealer_maintained', oldCar?.isDealerMaintained],
      ['trade_in_damage_description', oldCar?.isDamaged],

      ['finance_interest', !!usingFinancialPlan],
      ['finance_type', usingFinancialPlan ? 'betaalplan' : ''],
      ['finance_downpayment', configuration?.basic?.downPayment],
      ['finance_final_term', configuration?.basic?.finalPayment],
      ['finance_duration', configuration?.basic?.durationMonths],
      ['finance_amount', credit],
      ['finance_amount_monthly', monthlyPayment],
      ['finance_km_per_year', configuration?.basic?.yearlyKilometers],
      ['finance_no_claim_years', configuration?.basic?.claimFreeYears],
      ['finance_tax_scale', configuration?.basic?.taxScale],

      [
        'occasion_info',
        `Proefrit voor occasion ${occasion.title} met kenteken ${occasion.license_plate}.`,
      ],
    ]
    ;[leadModal, testDriveModal].forEach((form) => {
      tradeInData.forEach((item) => {
        const input: HTMLInputElement = form.querySelector(`[name="${item[0]}"]`)
        if (input) {
          input.value = typeof item[1] !== 'undefined' ? String(item[1]) : ''
        }
      })
    })
  }

  if (!occasion) {
    return (
      <div className="container">
        <p>Er gaat iets mis met het inladen van de occasion gegevens.</p>
      </div>
    )
  }

  return (
    <>
      <div className="occasion-options-block container">
        <div className="row mb-5">
          <div className="col-12 col-lg-8 offset-lg-2">
            <div className="text-center">
              <h2 className="d-inline-block">Kies je opties</h2>
            </div>

            {occasionAdded ? (
              <div className="position-relative mb-3">
                <CarSpecificationsSummary
                  editEdition={false}
                  editLicensePlate={true}
                  onEdit={() => dispatch(setTradeInModalStatus('flow-3-enter-license-plate'))}
                />
                <button
                  className="financial-step-trade-in__remove-old-car"
                  onClick={() => dispatch(setTradeInModalStatus('flow-3-remove-car'))}
                >
                  <IconRemove className="icon--trash" height={16} width={16} />
                </button>
              </div>
            ) : (
              <div className="occasion-options-block__section">
                <IconCarTradeIn className="occasion-options-block__icon" />

                <div className="occasion-options-block__content">
                  <h3 className="h5 mb-1">Wil je een auto inruilen?</h3>

                  <p>Ontvang een gegarandeerd bod</p>
                </div>

                <button
                  className="occasion-options-block__button button button--toggle open"
                  onClick={() => dispatch(setTradeInModalStatus('flow-3-enter-license-plate'))}
                >
                  Inruil aanbod toevoegen
                </button>
              </div>
            )}

            {!usingFinancialPlan ? (
              <div className="occasion-options-block__section">
                <IconFinance className="occasion-options-block__icon" />

                <div className="occasion-options-block__content">
                  <h3 className="h5 mb-1">Financieren</h3>

                  <p>Bekijk je opties</p>
                </div>

                <div className="d-flex flex-column align-items-md-end">
                  <button
                    className="occasion-options-block__button button button--toggle"
                    onClick={() =>
                      dispatch(
                        setTradeInModalStatus(
                          occasionAdded ? 'flow-4-calculate-monthly-payment' : 'flow-4-trade-option'
                        )
                      )
                    }
                    disabled={occasionData.year <= 2009}
                  >
                    Bereken je maandbedrag
                  </button>
                  {occasionData.year <= 2009 && (
                    <p className="text-small text-danger mt-1">
                      Deze auto is helaas te oud voor een financiering.
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <PaymentPlanSummary />
            )}

            <OccasionPriceSummary />

            <div className="occasion-options-block__ctas row">
              <div className="col-12 col-md-6 order-md-2">
                <button
                  id="occasion-options-block-lead"
                  className="button button--primary"
                  data-toggle="modal"
                  data-target="#modal-occasion-lead"
                  onClick={updateDomForms}
                >
                  Offerte aanvragen
                  <ButtonArrow className="icon" />
                </button>
              </div>

              <div className="col-12 col-md-6">
                <button
                  id="occasion-options-block-test-drive"
                  className="button button--secondary"
                  data-toggle="modal"
                  data-target="#modal-occasion-test-drive"
                  onClick={updateDomForms}
                >
                  Proefrit aanvragen
                  <ButtonArrow className="icon" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <FinanceTable showTableHorizontal={true} apiRootUrl={apiRootUrl} />
      </div>

      <TradeInModal
        licensePlateApiUrl={licensePlateApiUrl}
        louwmanHubBrand={louwmanHubBrand}
        financeApiUrl={financeApiUrl}
        apiRootUrl={apiRootUrl}
      />
    </>
  )
}

export default OccasionOptions
