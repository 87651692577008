import clsx from 'clsx'
import React, { ReactNode } from 'react'

type Props = {
  name: string
  id: string
  label: string
  className?: string
  children?: ReactNode
}

export const TextArea = ({ name, id, label, className, children }: Props) => (
  <div className={clsx('form-group', className)}>
    <label className="input-label" htmlFor={id}>
      {label}
    </label>
    <textarea className="form-control input-text" id={id} name={name}>
      {children}
    </textarea>
    <div className="invalid-feedback"></div>
  </div>
)
