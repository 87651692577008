/***
 * @name Overlay - React component
 *
 */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade'
import React, { useEffect, useState } from 'react'
import IconCross from '~svg/cross-fat.svg'

interface IOverlay {
  show?: boolean
  type?: string
  icon?: string
  onClose?(): void
  children?: React.ReactNode
}

// Transition constants
const TRANSITION_PROGRESS: number = 1
const TRANSITION_IN_COMPLETE: number = 2
const TRANSITION_OUT_COMPLETE: number = 3

// TODO: Use hook for previous value
let prevShow: boolean = null
const $root: HTMLElement = document.querySelector('.js-overlay')

//
//
const Overlay: React.FC<IOverlay> = ({ show, children, type = 'is-iframe', onClose }) => {
  //
  // State
  const [showButton, setShowButton] = useState(false)
  const [showBody, setShowBody] = useState(false)
  const [showBackground, setShowBackground] = useState(false)
  const [transition, setTransition] = useState(TRANSITION_OUT_COMPLETE)

  //
  // Methods
  const transitionIn = () => {
    disableBodyScroll($root)

    setTransition(TRANSITION_PROGRESS)

    setTimeout(() => {
      setShowBackground(true)
    }, 25)

    setTimeout(() => {
      setShowBody(true)
    }, 500)

    setTimeout(() => {
      setShowButton(true)
    }, 500)

    setTimeout(() => {
      setTransition(TRANSITION_IN_COMPLETE)
    }, 1025)
  }

  const transitionOut = () => {
    setTransition(TRANSITION_PROGRESS)

    setShowButton(false)

    setTimeout(() => {
      setShowBody(false)
    }, 50)

    setTimeout(() => {
      setShowBackground(false)
    }, 300)

    setTimeout(() => {
      setTransition(TRANSITION_OUT_COMPLETE)
    }, 1000)
  }

  // If you click on the black background, close the modal
  function outsideClickListener(event: Event) {
    const target = event.target as HTMLElement

    // Ignore clicking on the images, slick arrows, or close button
    const ignoreElement =
      target.tagName === 'IMG' ||
      target.classList.contains('slick-arrow') ||
      target.classList.contains('overlay__btn-close') ||
      target.classList.contains('thumbnail-group__container')

    if (show && !ignoreElement) {
      onClose()
    }
  }

  //
  // Hook
  useEffect(() => {
    if (prevShow !== show) {
      prevShow = show

      if (show) {
        transitionIn()
        document.addEventListener('click', outsideClickListener)

        setTimeout(() => {
          const child = document.querySelector('.overlay__body .slick-active img') as HTMLElement

          if (child) {
            child.click()
          }
        })
      } else {
        transitionOut()
      }
    }

    return () => {
      document.removeEventListener('click', outsideClickListener)
    }
  }, [show])

  //
  // Event handler
  // We add support for a close callback in case we don't want to use the router
  const clickCloseHandler = (event: any) => {
    if (onClose) {
      event.preventDefault()

      onClose()
    }
  }

  //
  // Render
  if (!show && transition === TRANSITION_OUT_COMPLETE) {
    enableBodyScroll($root)
    return <></>
  }

  return (
    <div className={`overlay ${type}`}>
      <div
        className={showBackground ? 'overlay__background show-background' : 'overlay__background'}
        onClick={clickCloseHandler}
      />
      <div className={showBody ? 'overlay__body show-body' : 'overlay__body'}>{children}</div>
      <a
        href="#"
        className={showButton ? 'overlay__btn-close show-button' : 'overlay__btn-close'}
        onClick={clickCloseHandler}
      >
        <IconCross className="icon" />
      </a>
    </div>
  )
}

export default Overlay
