// // There is a form for mobile (in a modal) and for desktop
// // TODO: remove this code after the e-commerce optimalisatons

import { breakpoints } from '~src/utils/breakpoints'
import { ui } from '~src/utils/ui'

type Elements = {
  component: HTMLFormElement
  filterButton: HTMLButtonElement
  selectEls: HTMLSelectElement[]
  categoryLinks: HTMLAnchorElement[]
}

ui(
  {
    component: '.js-accessories-overview-form',
    children: {
      filterButton: '.accessories-overview-form__filter-button',
      selectEls: ['.js-accessory-filter select'],
      categoryLinks: ['.accessories-overview-form__categories a'],
    },
  },
  ({ component, filterButton, selectEls, categoryLinks }: Elements) => {
    // Open modal for mobile if filter was applied
    if (
      window.innerWidth < breakpoints.lg &&
      window.location.search.indexOf('openmodal=true') > -1
    ) {
      filterButton && filterButton.click()
    }

    selectEls.forEach((select) => select.addEventListener('change', handleSelectChange))
    categoryLinks.forEach((link) => link.addEventListener('click', handleCategoryLink))
  }
)

function handleSelectChange(event: Event) {
  const target = event.target as HTMLSelectElement
  const form = target.closest<HTMLFormElement>('.js-accessories-overview-form')
  const currentFilter = target.closest<HTMLDivElement>('.js-accessory-filter')
  const children = currentFilter ? Array.from(currentFilter.parentElement.children) : undefined

  if (currentFilter) {
    const index = children.indexOf(currentFilter)
    const nextFiltersFirstOption = currentFilter.parentElement.querySelectorAll<HTMLOptionElement>(
      `.js-accessory-filter:nth-child(${
        index + 1
      }) ~ .js-accessory-filter select option:first-child`
    )

    // Set all next filters to first option ('all')
    if (nextFiltersFirstOption) {
      nextFiltersFirstOption.forEach((option) => {
        option.selected = true
      })
    }
  }

  // Submit form
  form.submit()
}

// // For mobile, add an extra query string, so that the modal will open on reload
function handleCategoryLink(event: Event) {
  if (window.innerWidth < breakpoints.lg) {
    event.preventDefault()
    const target = event.target as HTMLAnchorElement
    const url = `${target.href}${target.href.includes('?') ? '&' : '?'}openmodal=true`
    window.location.href = url
  }
}
