import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { copyToClipboard } from '~src/utils/copyToClipboard'
import IconAnchorLink from '~svg/anchor-link.svg'
import IconEmail from '~svg/email.svg'
import IconFacebook from '~svg/facebook.svg'
import IconLinkedIn from '~svg/linkedin.svg'
import IconMessenger from '~svg/messenger.svg'
import IconShare from '~svg/share.svg'
import IconTwitter from '~svg/twitter.svg'
import IconWhatsapp from '~svg/whatsapp.svg'

type Props = {
  buttonText?: string
  reversed?: boolean
}

const ShareButtons = ({ buttonText, reversed }: Props): JSX.Element => {
  const [showCopyMessage, setShowCopyMessage] = useState(false)
  const [openOnMobile, setOpenOnMobile] = useState(false)
  const shareLink = encodeURIComponent(window.location.href)

  useEffect(() => {
    let timeout: any

    if (showCopyMessage) {
      timeout = setTimeout(() => {
        setShowCopyMessage(false)
      }, 2000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [showCopyMessage])

  return (
    <div className={clsx('share-buttons', reversed && 'share-buttons--reversed')}>
      <div className="button-link share-buttons__tooltip-toggle">
        <button type="button" onClick={() => setOpenOnMobile(!openOnMobile)}>
          <IconShare className="share-icon" />
          {buttonText || 'Deel deze pagina'}
        </button>

        <div
          className={`tooltip fade show bs-tooltip-bottom ${openOnMobile ? 'open' : ''}`}
          role="tooltip"
        >
          <div className="arrow"></div>
          <div className="tooltip-inner">
            <div className="share-buttons__list">
              <a
                href={`https://twitter.com/intent/tweet?url=${shareLink}&hashtags=suzuki`}
                target="_blank"
                rel="noopener noreferrer"
                className="share-buttons__button"
              >
                <div className="icon">
                  <IconTwitter />
                </div>
                <div>Twitter</div>
              </a>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
                className="share-buttons__button"
              >
                <div className="icon">
                  <IconLinkedIn />
                </div>
                <div>LinkedIn</div>
              </a>
              <a
                href={`http://www.facebook.com/share.php?u=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
                className="share-buttons__button"
              >
                <div className="icon">
                  <IconFacebook />
                </div>
                <div>Facebook</div>
              </a>

              <a
                href={`whatsapp://send?text=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
                className="share-buttons__button d-lg-none"
              >
                <div className="icon">
                  <IconWhatsapp />
                </div>
                <div>Whatsapp</div>
              </a>

              <a
                href={`https://web.whatsapp.com/send?text=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
                className="share-buttons__button d-none d-lg-flex"
              >
                <div className="icon">
                  <IconWhatsapp />
                </div>
                <div>Whatsapp</div>
              </a>

              <a
                href={`fb-messenger://share/?link=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
                className="share-buttons__button d-lg-none"
              >
                <div className="icon">
                  <IconMessenger />
                </div>
                <div>Messenger</div>
              </a>

              <a
                href={`mailto:?body=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
                className="share-buttons__button"
              >
                <div className="icon">
                  <IconEmail />
                </div>
                <div>E-mail</div>
              </a>
              <a
                className="share-buttons__button"
                onClick={() => {
                  setShowCopyMessage(true)
                  copyToClipboard(window.location.href)
                }}
              >
                <div className="icon">
                  <IconAnchorLink />
                </div>
                <div>Link kopiëren</div>
              </a>
              {showCopyMessage && (
                <div className="share-buttons__button copied">
                  <div className="icon"> </div>Gekopieerd!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareButtons
