document.addEventListener('DOMContentLoaded', function () {
  const serviceTools = document.querySelectorAll('.js-service-tool')
  ;[].forEach.call(serviceTools, createScript)
})

function createScript(serviceTool: HTMLElement) {
  const params = new URLSearchParams(window.location.search || '')
  const token = params.get('prcode')

  let url = new URL(serviceTool.dataset.includeUrl)

  if (token) {
    url.searchParams.append('prcode', token)
  }

  let script = document.createElement('script')
  script.setAttribute('src', url.toString())

  document.body.appendChild(script)
}
