import React from 'react'
import Tooltip from '~src/common/Tooltip'
import { useRootState } from '~src/hooks/store'
import { currencyFormatter } from '~src/utils/number-formatter'

const PersonalProposal = () => {
  const {
    tradeIn: { oldCar, newCar },
  } = useRootState()

  let totalTradeInValue = Number(oldCar.trade_in_value)
  if (newCar.extra_trade_in_value) totalTradeInValue += Number(newCar.extra_trade_in_value)

  return (
    <div className="trade-in-modal__section">
      <div className="trade-in-modal__proposal">
        {oldCar.trade_in_value && (
          <>
            <div className="trade-in-modal__proposal-block">
              <h4 className="trade-in-modal__title">Jouw persoonlijke inruilvoorstel</h4>
              <p>Prijs nieuwe auto</p>
              <div className="trade-in-modal__proposal-row mb-3">
                {/* @TODO: add brand name from backend */}
                <span className="text-uppercase">Suzuki {newCar.edition.name}</span>
                <span>{currencyFormatter.format(Number(newCar.edition.price))}</span>
              </div>

              <p>Inruilwaarde huidige auto</p>
              <div className="trade-in-modal__proposal-row">
                <span className="text-uppercase">
                  {oldCar.brand} {oldCar.model} ({oldCar.license_plate}){' '}
                  <Tooltip text="Bij de aanvraag zal de indicatieve inruilwaarde meegenomen zijn." />
                </span>
                <span>- {currencyFormatter.format(Number(oldCar.trade_in_value))}</span>
              </div>
              <div className="trade-in-modal__proposal-row">
                <span className="text-uppercase">Extra Suzuki inruilwaarde</span>
                <span>
                  {newCar.extra_trade_in_value
                    ? `- ${currencyFormatter.format(Number(newCar.extra_trade_in_value))}`
                    : '-'}
                </span>
              </div>

              <hr />

              <div className="trade-in-modal__proposal-row">
                <span className="text-uppercase">Geschatte totale inruilwaarde</span>
                <span>- {currencyFormatter.format(totalTradeInValue)}</span>
              </div>
            </div>
            <div className="trade-in-modal__proposal-block">
              <div className="trade-in-modal__proposal-row">
                <span className="text-uppercase">Jouw nieuwe {newCar.model} rijklaar vanaf</span>
                <span>
                  {currencyFormatter.format(Number(newCar.edition.price) - totalTradeInValue)}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PersonalProposal
