export const sanitizeZipCode = (input: string): string =>
  input
    // From a group of 4 numbers and a group of 2 numbers separated by any spaces
    // remove all spaces and add a single space between groups.
    // The i-flag makes the regex case insensitive.
    .replace(/([0-9]+)\s*([A-Z]+)/gi, '$1 $2')
    // Then, transform the string to uppercase and trim it.
    .toUpperCase()
    .trim()

export const validZipcode = (input: string): boolean => {
  if (!input) return false
  const zipcodeRegex = /^\d{4} ?[a-z]{2}$/i
  return zipcodeRegex.test(input)
}

export const validHouseNumber = (input: string): boolean => {
  if (!input) return false
  const houseNumberRegex = /^[0-9]*$/
  return houseNumberRegex.test(input)
}
