import clsx from 'clsx'
import React from 'react'
import ArrowCaretCircleIcon from '~svg/arrow-caret-circle.svg'

const BackButton = ({
  onClick,
  children,
  className,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
  className?: string
}) => {
  return (
    <button
      className={clsx('button button--text button--text-dark my-4', className)}
      onClick={onClick}
    >
      <ArrowCaretCircleIcon height={20} style={{ transform: 'rotate(180deg)' }} fill="#323232" />
      {children}
    </button>
  )
}

export default BackButton
