/**
 * @name Script loader
 *
 */

class ScriptLoader {
  private scripts: any

  constructor() {
    this.scripts = {}
  }

  public loadScript(url: string, id?: string) {
    return new Promise<void>((resolve, reject) => {
      const requestedScript = this.scripts[url]

      if (requestedScript) {
        if (requestedScript.loaded) {
          resolve()
        }

        if (requestedScript.error) {
          reject()
        }
        return
      }

      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      if (id) script.id = id
      window.document.body.appendChild(script)

      const scriptHandler = (e: Event) => {
        script.removeEventListener('load', scriptHandler)
        script.removeEventListener('error', scriptHandler)

        this.scripts[url] = e.type === 'load'

        if (e.type === 'load') {
          resolve()
        } else {
          reject()
        }
      }

      script.addEventListener('load', scriptHandler)
      script.addEventListener('error', scriptHandler)
    })
  }
}

const scriptLoader = new ScriptLoader()

export default scriptLoader
