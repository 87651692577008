import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { IEdition, IFinancialData, IFinancialProduct } from '~src/api/types/configurator'
import { setInitialState } from '~src/store/initialization'
import { RootState } from '~src/store'
import { IApiState, IOccasion } from '~src/store/api/types'
import { setEdition } from '~src/store/configuration/actions'
import {
  setFinancialData,
  setFinancialProducts,
  setOccasion,
  setUsingFinancialPlan,
} from '~src/store/api/actions'

const initialData: IApiState = {
  models: [],
  edition: null,
  editionFinancialData: null,
  financialProducts: [],
  occasion: null,
  usingFinancialPlan: null,
}

const apiReducer = createReducer(initialData, {
  [setInitialState.toString()]: (state, action: PayloadAction<RootState>) => ({
    ...state,
    ...action.payload.api,
  }),
  [setEdition.toString()]: (state, action: PayloadAction<IEdition>) => ({
    ...state,
    edition: action.payload,
  }),
  [setFinancialData.toString()]: (state, action: PayloadAction<IFinancialData>) => ({
    ...state,
    editionFinancialData: action.payload,
  }),
  [setFinancialProducts.toString()]: (state, action: PayloadAction<IFinancialProduct[]>) => ({
    ...state,
    financialProducts: action.payload,
  }),
  [setOccasion.toString()]: (state, action: PayloadAction<IOccasion>) => {
    return {
      ...state,
      occasion: action.payload,
    }
  },
  [setUsingFinancialPlan.toString()]: (state, action: PayloadAction<boolean>) => ({
    ...state,
    usingFinancialPlan: action.payload,
  }),
})

export default apiReducer
