/***
 * @name Dealer list
 *
 */

import React, { useEffect, useRef } from 'react'
import ScrollLock from 'react-scrolllock'
import { IDealerInfo, IDealerList } from '~src/apps/DealerMap/types'
import DealerItem from './DealerItem'

const DealerList: React.FC<IDealerList> = ({
  locations,
  showDistances,
  viewMode,
  showInfo,
  dealer,
  productGroup,
  onDealerSelect,
  formType,
  configuratorQueryString,
  isModal,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  // Life-cycle hook: When user clicks on an marker
  useEffect(() => {
    if (scrollContainerRef?.current) {
      // Scroll to dealer in list

      if (dealer && showInfo) {
        const $dealerItem: HTMLDivElement = scrollContainerRef.current.querySelector(
          `[data-dealer-id="${dealer.id}"]`
        )

        if ($dealerItem) {
          scrollContainerRef.current.scrollTo({ top: $dealerItem.offsetTop, behavior: 'smooth' })
        }
      } else {
        scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }, [scrollContainerRef, showInfo, dealer, locations])

  const renderItems = () => {
    if (locations.length === 0) {
      return <div className="no-results-found">Geen resultaten gevonden.</div>
    }

    if (productGroup === 'car' && !locations[0].is_sales_dealer) {
      // Sort locations to always have a SALES dealer at the top.
      // Even though it's not the nearest dealer location.
      const nearestSalesDealer = locations.find((location) => location.is_sales_dealer)

      if (nearestSalesDealer) {
        locations = [
          nearestSalesDealer,
          ...locations.filter((location) => location.id !== nearestSalesDealer.id),
        ]
      }
    }

    return locations.map((item: IDealerInfo, index: number) => (
      <DealerItem
        key={`item-${index}`}
        onDealerSelect={onDealerSelect}
        showDistances={showDistances}
        viewMode={viewMode}
        productGroup={productGroup}
        formType={formType}
        configuratorQueryString={configuratorQueryString}
        {...item}
      />
    ))
  }

  return (
    <div className="dealer-list__container">
      <ScrollLock isActive={isModal}>
        <div className="scroll-container" ref={scrollContainerRef}>
          <>{renderItems()}</>
        </div>
      </ScrollLock>
    </div>
  )
}

export default DealerList
