import React from 'react'
import { OccasionBannerProps } from '~src/api/types/occasion'

export const OccasionBanner = ({
  label,
  title,
  text,
  image_url,
  promotion_page,
}: OccasionBannerProps): React.JSX.Element => {
  return (
    <a href={promotion_page} className="occasion-banner">
      <img src={image_url} alt={title} />
      <div className="occasion-banner__content">
        <p className="occasion-banner__title  h4">{title}</p>
        <p className="occasion-banner__text  text-small">{text}</p>
      </div>

      {label && <span className="occasion-banner__label">{label}</span>}
    </a>
  )
}
