import React from 'react'
import { useDispatch } from 'react-redux'
import { useRootState } from '~src/hooks/store'
import CheckmarkIcon from '~svg/checkmark.svg'
import { setNewCar } from '~src/store/trade-in/actions'
import { ITradeInSuggestion } from '~src/store/trade-in/types'

const ChooseModel = () => {
  const {
    tradeIn: { oldCar },
  } = useRootState()

  return (
    <div className="trade-in-modal__section">
      <h4 className="trade-in-modal__title">Jouw nieuwe Suzuki</h4>

      {oldCar.trade_in_suggestions?.length > 0 ? (
        <>
          <p className="trade-in-modal__paragraph mb-4">
            Kies onderstaand jouw nieuwe Suzuki. Staat het gewenste model er niet bij? Neem dan
            contact op met je dealer, voor een voorstel op maat!
          </p>
          <div className="trade-in-modal__suggestions">
            {oldCar.trade_in_suggestions.map((version: ITradeInSuggestion) => (
              <TradeInSuggestion key={version.model} {...version} />
            ))}
          </div>
        </>
      ) : (
        <p className="trade-in-modal__paragraph mb-4">
          Er zijn helaas geen modellen gevonden, neem contact op met je dealer voor een voorstel op
          maat!
        </p>
      )}
    </div>
  )
}

const TradeInSuggestion = (version: ITradeInSuggestion) => {
  const dispatch = useDispatch()
  const {
    tradeIn: { newCar },
  } = useRootState()

  return (
    <a
      className={`trade-in-modal__suggestions-item${
        newCar?.model === version.model ? ' is-selected' : ''
      }`}
      onClick={() => dispatch(setNewCar(version))}
    >
      <h5>{version.model}</h5>
      <img src={version.image_url} alt={version.model} />
      <div>
        {newCar?.model === version.model ? (
          <>
            <CheckmarkIcon width={16} height={14} className="icon" />
            <span>Geselecteerd</span>
          </>
        ) : (
          <span>Selecteer</span>
        )}
      </div>
    </a>
  )
}

export default ChooseModel
