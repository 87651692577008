import { configureStore, combineReducers, createReducer } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import navigationReducer from './navigation/navigationReducer'
import configurationReducer from './configuration/configurationReducer'
import { urlMiddleware } from './url-middleware'
import apiReducer from './api/apiReducer'
import { INavigationState } from './navigation/types'
import { IConfigurationState } from './configuration/types'
import { IApiState } from '~src/store/api/types'
import { setInitialState } from '~src/store/initialization'
import comparisonReducer from '~src/store/comparison/comparisonReducer'
import { IComparisonState } from '~src/store/comparison/types'
import { gtmMiddleware } from '~src/store/gtm-middleware'
import tradeInReducer from '~src/store/trade-in/tradeInReducer'
import { ITradeInState } from '~src/store/trade-in/types'

const reducer = combineReducers({
  api: apiReducer,
  navigation: navigationReducer,
  configuration: configurationReducer,
  comparison: comparisonReducer,
  tradeIn: tradeInReducer,
  initialized: createReducer(false, {
    [setInitialState.toString()]: () => true,
  }),
})

export type RootState = {
  api: IApiState
  navigation: INavigationState
  configuration: IConfigurationState
  comparison: IComparisonState
  tradeIn: ITradeInState
  initialized?: boolean
}

export const makeStore = () =>
  configureStore({
    reducer,
    middleware: [
      urlMiddleware,
      gtmMiddleware,
      ...(['development', 'test'].includes(process.env.NODE_ENV) ? [logger] : []),
    ],
  })
