import React from 'react'
import { AddressFields } from '~src/common/AddressFields'
import { DealerSearch } from '~src/common/DealerSearch'
import InputCheckbox from '~src/common/InputCheckbox'
import InputRadio from '~src/common/InputRadio'
import InputText from '~src/common/InputText'
import { TextArea } from '~src/common/TextArea'
import { handleFormSubmit } from '~src/dom_components/forms'
import ButtonArrow from '~svg/button-arrow.svg'

type Props = {
  productGroup: string
  dealersApiUrl: string
  apiUrl: string
  apiRootUrl: string
  louwmanHubBrand: string
  editionName: string
  configuratorUrl: string
  addressApiUrl: string
  addressApiKey: string
  modelCode?: string
  privacyUrl?: string
}

const privacyText =
  'Als je jouw aanvraag verstuurt ga je akkoord met onze privacyverklaring. De door jou verstrekte gegevens worden uitsluitend gebruikt voor het door jou gevraagde lead of de door jou gevraagde informatie.'

export const FinanceRequest = ({
  dealersApiUrl,
  apiUrl,
  apiRootUrl,
  louwmanHubBrand,
  productGroup,
  modelCode,
  editionName,
  configuratorUrl,
  addressApiUrl,
  addressApiKey,
  privacyUrl,
}: Props) => (
  <>
    <div className="hero-small-block has-background">
      <div className="container">
        <div className="hero-small-block__center">
          <h1 className="hero-small-block__title">Financiering aanvragen</h1>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 offset-lg-3">
          <form
            className="form form--page"
            data-api-url={apiUrl}
            onSubmit={(e) => handleFormSubmit(e.nativeEvent)}
          >
            <div className="form__input-block js-form-fields">
              <div className="mb-4">
                <h5 className="mb-2">
                  {productGroup === 'car'
                    ? 'Geselecteerde auto'
                    : productGroup === 'motors'
                    ? 'Geselecteerde motor'
                    : productGroup === 'marine'
                    ? 'Geselecteerde buitenboordmotor'
                    : 'Geselecteerd product'}
                </h5>
                <p>{editionName}</p>
              </div>

              <div className="form__section">
                <h4>Jouw gegevens</h4>

                <div className="form-group">
                  <label className="input-label">Aanhef*</label>

                  <div>
                    <InputRadio
                      label="Dhr."
                      id="finance_request_form_genderMale"
                      name="gender"
                      value="m"
                    />
                    <InputRadio
                      label="Mevr."
                      id="finance_request_form_genderFemale"
                      name="gender"
                      value="v"
                    />
                  </div>

                  <div className="invalid-feedback text-left"></div>
                </div>

                <div className="row row-md-gutter-16">
                  <div className="col-12 col-md-4">
                    <InputText
                      label="Voorletters*"
                      id="finance_request_form_initials"
                      name="initials"
                    />
                  </div>

                  <div className="col-12 col-md-3">
                    <InputText
                      label="Tussenvg."
                      id="finance_request_form_last_name_prefix"
                      name="last_name_prefix"
                    />
                  </div>

                  <div className="col-12 col-md-5">
                    <InputText
                      label="Achternaam*"
                      id="finance_request_form_last_name"
                      name="last_name"
                    />
                  </div>
                </div>

                <AddressFields addressApiUrl={addressApiUrl} addressApiKey={addressApiKey} />

                <div
                  className="form-group js-date-field"
                  id="finance_request_form_dob"
                  data-input-name="date_of_birth"
                >
                  <label className="input-label" htmlFor="finance_request_form_dob_day">
                    Geboortedatum*
                  </label>
                  <div className="row row-md-gutter-16">
                    <div className="col-4 col-md-4">
                      <input
                        className="form-control input-text"
                        id="finance_request_form_dob_day"
                        name="dob_day"
                        type="number"
                        min="1"
                        max="31"
                      />
                    </div>
                    <div className="col-4 col-md-3">
                      <input
                        className="form-control input-text"
                        id="finance_request_form_dob_month"
                        name="dob_month"
                        type="number"
                        min="1"
                        max="12"
                      />
                    </div>
                    <div className="col-4 col-md-5">
                      <input
                        className="form-control input-text"
                        id="finance_request_form_dob_year"
                        name="dob_year"
                        type="number"
                        min="1"
                      />
                    </div>
                  </div>
                  <div className="invalid-feedback"></div>
                </div>

                <InputText
                  type="email"
                  label="E-mail*"
                  id="finance_request_form_email_address"
                  name="email_address"
                />

                <InputText
                  type="tel"
                  label="Telefoonnummer*"
                  id="finance_request_form_phone_number"
                  name="phone_number"
                  className="hide-arrows"
                />

                <TextArea
                  label="Opmerkingen"
                  id="finance_request_form_comments"
                  name="request_comments"
                  className="mb-4"
                />
              </div>

              <hr className="my-4" />

              <DealerSearch
                apiRootUrl={apiRootUrl}
                dealersApiUrl={dealersApiUrl}
                louwmanHubBrand={louwmanHubBrand}
              />

              <hr className="my-4" />

              {privacyUrl ? (
                <InputCheckbox id="finance_request_form_privacy_statement" name="privacy_statement">
                  Ik ga akkoord met het{' '}
                  <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
                    privacy statement
                  </a>
                </InputCheckbox>
              ) : (
                <InputCheckbox id="finance_request_form_privacy_statement" name="privacy_statement">
                  Ik ga akkoord met het privacy statement
                </InputCheckbox>
              )}

              <input type="hidden" name="privacy_statement_text" value={privacyText} />

              <input type="hidden" name="product_group" value={productGroup} />

              {/* Only non-marine products have a `model_code` (and hence sending the code is only applicable for non-marine */}
              {modelCode && productGroup !== 'marine' && (
                <input type="hidden" name="model_codes" value={modelCode} />
              )}

              <input type="hidden" name="vehicle_brand" value="Suzuki" />
              <input type="hidden" name="vehicle_model" value={editionName} />
              <input type="hidden" name="car_configurator_url" value={configuratorUrl} />

              <div className="block text-block text-small mb-5">
                <div className="rich-text">
                  <p>{privacyText}</p>

                  <p>
                    <strong>*</strong> Deze velden zijn verplicht
                  </p>
                </div>
              </div>

              <div className="form-group">
                <button type="submit" className="button button--primary">
                  Verstuur
                  <ButtonArrow className="icon" />
                </button>
              </div>

              <div className="form-error error js-form-error"></div>
            </div>

            <div className="form__success-block d-none js-form-success">
              <div className="block text-block js-form-success-message"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
)
