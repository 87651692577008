import React from 'react'
import { FacetName, IFacetChoices, IFacetState } from '~src/api/types/occasion'
import { IDealerInfo } from '~src/apps/DealerMap/types'
import { formattedOptionName } from '~src/apps/Occasion/occasion-label'
import CrossIcon from '~svg/cross-fat.svg'

type Props = {
  facets: IFacetChoices
  dealers: IDealerInfo[]
  onRemove: (id: string) => void
  onRemoveAll: () => void
}

const FilterSummary = ({ facets, dealers, onRemove, onRemoveAll }: Props): JSX.Element => (
  <div className="mb-3">
    {toFilterChips(facets, dealers).map(({ id, label }, index) => (
      <button key={index} onClick={() => onRemove(id)} className="chip m-1">
        {label}
        <CrossIcon className="ml-1" height={10} width={10} />
      </button>
    ))}
    {atLeastOneFilter(facets) && (
      <button
        onClick={onRemoveAll}
        className="button-link button--small occasion-overview__clear-filters"
      >
        <CrossIcon className="mr-1" height={10} width={10} fill="#de0039" />
        Alle filters verwijderen
      </button>
    )}
  </div>
)

export default FilterSummary

const toFilterChips = (facets: IFacetChoices, dealers: IDealerInfo[]) =>
  Object.entries(facets)
    .filter(([_, facet]) => nonEmptyFacet(facet))
    .flatMap(([id, { range, values }]: [FacetName, IFacetState]) => {
      if (range?.length > 0) {
        return [
          {
            id,
            label: !range[0]
              ? `Tot ${formattedOptionName(id, range[1])}`
              : !range[1]
              ? `Vanaf ${formattedOptionName(id, range[0])}`
              : range.join(' - '),
          },
        ]
      } else if (id === 'ClientId') {
        return values.map((value) => ({
          id,
          label: dealers.find((dealer) => dealer.dealer_number.toString() === value)?.name,
        }))
      } else {
        return values.map((value) => ({ id, label: value }))
      }
    })

const atLeastOneFilter = (facets: IFacetChoices): boolean =>
  Object.values(facets).some(nonEmptyFacet)

export const nonEmptyFacet = (facet?: IFacetState): number =>
  facet?.values?.length || facet?.range?.length
