import Glide from '@glidejs/glide'
import { FixBoundPeek } from '../utils/glide-functions.js'

const carouselGlide: HTMLElement = document.querySelector('.occasion-summary-block .glide--image')
const thumbnailsCarouselGlide: HTMLElement = document.querySelector(
  '.occasion-summary-block .glide--thumbnails'
)
const firstImage = carouselGlide?.querySelector('img')

document.addEventListener('DOMContentLoaded', function () {
  if (!carouselGlide) {
    return
  }

  if (firstImage) {
    lazyLoadImages()
  }

  // Carousel
  const carousel = createCarousel()
  carousel
    .on('run', () => {
      // Set thumbnail selected state
      const thumbnails = [
        ...Array.from(thumbnailsCarouselGlide?.querySelectorAll<HTMLImageElement>('.glide__slide')),
      ]

      thumbnails.forEach((t) => {
        t.classList.remove('selected')
        if (Number(t.dataset.index) === carousel.index) {
          t.classList.add('selected')
        }
      })

      // Check if image is visible in thumbnails carousel
      if (
        carousel.index > thumbnailsCarousel.index + 3 ||
        carousel.index < thumbnailsCarousel.index
      ) {
        thumbnailsCarousel.go('=' + carousel.index)
      }
    })
    .mount({})

  // Thumbnails carousel
  const thumbnailsCarousel = createThumbnailsCarousel()

  type RunEventHandler = {
    direction: '>' | '<'
  }

  const step = 4
  thumbnailsCarousel
    // @ts-ignore glidejs type definitions are not working correctly
    .on('run.before', (evt) => {
      if (evt.direction === '>' || evt.direction === '<') {
        evt.steps = evt.direction === '>' ? -step : step
      }
    })
    .on('run.after', () => {
      loadImages()
    })
    .on('mount.after', () => {
      // handle click on thumbnail
      const thumbnails = [
        ...Array.from(thumbnailsCarouselGlide?.querySelectorAll<HTMLImageElement>('.glide__slide')),
      ]

      // Select fist item
      thumbnails.forEach((t) => {
        if (Number(t.dataset.index) === 0) {
          t.classList.add('selected')
        }
      })

      // Handle click
      thumbnails.map((thumbnail: HTMLElement) =>
        thumbnail.addEventListener('click', (event: Event) => {
          const target = event.target as HTMLElement
          const index = Number(target.dataset.index)

          // Remove/set selected
          thumbnails.forEach((t) => t.classList.remove('selected'))
          target.classList.add('selected')

          // Change big image
          carousel.go('=' + index)
        })
      )
    })
    .mutate([FixBoundPeek])
    .mount({})
})

function lazyLoadImages() {
  // Check if the first image has loaded
  const image = firstImage
  let count = 0

  if (!image || image.complete) {
    // Image is loaded, or there is no image
    loadImages()
  } else {
    // Image is not loaded, start a timer to see when it is
    let interval = window.setInterval(() => {
      if (image.complete) {
        loadImages()
        window.clearInterval(interval)
        return
      }

      // Wait for 10 seconds before giving up
      if (count > 50) {
        window.clearInterval(interval)
        console.error('Image did not load in time, can not apply clip path')
        return
      }

      count++
    }, 200)
  }
}

function loadImages() {
  const allImages = [
    ...Array.from(
      document.querySelectorAll<HTMLImageElement>('.occasion-summary-block .glide img')
    ),
  ]
  const allSources = [
    ...Array.from<HTMLImageElement>(
      document.querySelectorAll('.occasion-summary-block .glide source')
    ),
  ]

  // Load images
  ;[...allImages].map((image) => {
    if (image.src === '' && image.dataset.src) {
      image.src = image.dataset.src
    }
  })

  // Load srcsets
  ;[...allSources].map((image) => {
    if (image.srcset === '' && image.dataset.srcset) {
      image.srcset = image.dataset.srcset
    }
  })
}

// Create a carousel with Glide
function createCarousel() {
  const options = {
    type: 'carousel',
    bound: true,
    focusAt: 0,
    autoplay: false,
    keyboard: true,
    swipe: true,
    animationTimingFunc: 'ease-in-out',
    perView: 1,
    gap: 0,
  }

  // @ts-ignore - glide types is not up to date
  return new Glide(carouselGlide, options)
}

// Create a carousel with Glide
function createThumbnailsCarousel() {
  const isCarousel = Number(carouselGlide.dataset.length) > 4

  const options = {
    type: isCarousel ? 'carousel' : 'slider',
    bound: true,
    focusAt: 0,
    autoplay: false,
    keyboard: isCarousel ? true : false,
    swipe: isCarousel ? true : false,
    rewind: false,
    animationTimingFunc: 'ease-in-out',
    perView: 4,
    gap: 2,
    peek: isCarousel ? { before: 40, after: 40 } : 0,
  }

  // @ts-ignore - glide types is not up to date
  return new Glide(thumbnailsCarouselGlide, options)
}
