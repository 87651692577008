/**
 * Groups a list based on a callback.
 * Returns a Map where the keys are the result of the callback.
 *
 * @example
 * ```ts
 * groupBy(
 *   [{age: 18, name: 'John'}, {age: 18, name: 'Joe'}, {age: 16, name: 'Jack'}],
 *   p => p.age,
 * )
 * ```
 *
 * results in
 *
 * ```
 * Map {
 *  16: [{age: 16, name: 'Jack'}],
 *  18: [{age: 18, name: 'John'}, {age: 18, name: 'Joe'}],
 * }
 * ```
 */
export const groupBy = <T, S>(list: T[], keyGetter: (i: T) => S) => {
  const map = new Map<S, T[]>()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

/**
 * Same as `groupBy` but returns entries as key-list tuples.
 * Groups a list based on a callback.
 *
 * @example
 * ```ts
 * groupByAsEntries(
 *   [{age: 18, name: 'John'}, {age: 18, name: 'Joe'}, {age: 16, name: 'Jack'}],
 *   p => p.age,
 * )
 * ```
 *
 * results in
 *
 * ```
 * [
 *  [16, [{age: 16, name: 'Jack'}]],
 *  [18, [{age: 18, name: 'John'}, {age: 18, name: 'Joe'}]],
 * ]
 * ```
 */
export const groupByAsEntries = <T, S>(list: T[], keyGetter: (i: T) => S) => [
  ...groupBy(list, keyGetter).entries(),
]

/**
 * Returns an array of unique items
 *
 * @example
 * ```js
 * unique(['a', 'a', 'b']) // ['a', 'b']
 * ```
 */
export const unique = <T>(list: T[]) => [...new Set(list)]

/**
 * Based on 2 objects of the same type, returns the partial object of `mainObject` with the properties that are different.
 *
 * @example
 * ```ts
 * objectsDifference({a: 1, b: 2}, {a:2, b: 2}) // {a: 1}
 * ```
 */
export const objectsDifference = <T extends { [key: string]: any }>(
  mainObject: T,
  compareObject: T
): Partial<T> =>
  Object.keys(mainObject).reduce((diff, key) => {
    if (mainObject[key] === compareObject[key]) return diff
    return {
      ...diff,
      [key]: mainObject[key],
    }
  }, {} as Partial<T>)

/**
 * Finds an entry in a list in O(log(n)) time.
 * https://en.wikipedia.org/wiki/Binary_search_algorithm
 *
 * @param list A list **that must be sorted**
 *
 * @param compare A comparison function that returns
 * - 0 if the entry is found
 * - a negative number if the entry is too low
 * - a positive number if the entry is too high
 *
 * @param mode Either: 'strict' or 'best_fit'. Default 'strict.
 * - 'strict' will return `null` if nothing matches exactly.
 * - 'best_fit' will return the items that comes closest.
 *
 * @example
 * ```ts
 * const list = [{from: 1, to: 2, value: 'x'}, {from: 2, to: 3, value: 'y'}]
 * const value = 2.5
 * const result = binarySearch(list, x => x.from >= value && x.to < value ? 0 : x.to - value)
 * result.value // 'y'
 * ```
 */
export const binarySearch = <T>(
  list: T[],
  compare: (i: T) => number,
  mode: 'strict' | 'best_fit' = 'strict'
) => {
  let startIndex = 0
  let endIndex = list.length - 1
  let item: T = null

  while (startIndex <= endIndex) {
    const middleIndex = Math.floor((startIndex + endIndex) / 2)
    const comparison = compare(list[middleIndex])
    item = list[middleIndex]
    if (comparison === 0) {
      return item
    }
    if (comparison > 0) {
      startIndex = middleIndex + 1
    } else {
      endIndex = middleIndex - 1
    }
  }

  return mode === 'best_fit' ? item : null
}

/*
 * Returns a string or the last item of an array
 * Used for query string that can contain the same key multiple times
 */
export const getStringOrLastOfArray = (value: string | string[]) =>
  Array.isArray(value) ? value[0] : value
