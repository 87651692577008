import React from 'react'
import { useDispatch } from 'react-redux'
import { useRootState } from '~src/hooks/store'
import { addToCompareSelection, removeFromCompareSelection } from '~src/store/comparison/actions'
import { isMobile } from '~src/utils/breakpoints'

type Props = {
  editionCode: string
}

const ComparisonCheckbox = ({ editionCode }: Props) => {
  const dispatch = useDispatch()
  const {
    comparison: { selectedCarCodes },
  } = useRootState()

  const id = `comparison_${editionCode}`
  const checked = selectedCarCodes.includes(editionCode.toString())

  const maxSelections = isMobile() ? 2 : 3
  const disabled = !checked && selectedCarCodes.length >= maxSelections

  return (
    <div className="compare-action form-group mb-0">
      <div className="form-check form-check-inline input-checkbox mr-0">
        <input
          className={`input-checkbox__input ${disabled ? 'input-checkbox__input--disabled' : ''}`}
          type="checkbox"
          id={id}
          value="true"
          checked={checked}
          disabled={disabled}
          onChange={() => {
            if (checked) {
              dispatch(removeFromCompareSelection(editionCode.toString()))
            } else {
              if (selectedCarCodes.length < maxSelections) {
                dispatch(addToCompareSelection(editionCode.toString()))
              }
            }
          }}
        />
        <label className="input-checkbox__label" htmlFor={id}>
          Vergelijk
        </label>
      </div>
    </div>
  )
}

export default ComparisonCheckbox
