import flatpickr from 'flatpickr'

export default function ArticleFilter() {
  const filterBlock = document.querySelector('.js-article-filter')
  const params = new URLSearchParams(window.location.search || '')

  if (filterBlock) {
    const filterType: HTMLSelectElement = filterBlock.querySelector(
      '#filter_type'
    ) as HTMLSelectElement
    const filterDateStart: HTMLSelectElement = filterBlock.querySelector(
      '#filter_date_start'
    ) as HTMLSelectElement
    const filterDateEnd: HTMLSelectElement = filterBlock.querySelector(
      '#filter_date_end'
    ) as HTMLSelectElement

    // Add filter params and redirect to page.
    filterType.addEventListener('change', () => {
      params.set('article_type', filterType.value)
      window.location.href = `${location.pathname}?${params}`
    })

    filterDateStart.addEventListener('change', () => {
      params.set('date', filterDateStart.value)
      window.location.href = `${location.pathname}?${params}`
    })

    filterDateEnd.addEventListener('change', () => {
      params.set('end_date', filterDateEnd.value)
      window.location.href = `${location.pathname}?${params}`
    })

    // Pre-fill the filter elements with data from query parameters.
    if (params.toString() !== '') {
      params.forEach((value, key) => {
        switch (key) {
          case 'article_type':
            filterType.value = value
            break
          case 'date':
            flatpickr(filterDateStart, {
              defaultDate: value,
              altInput: true,
              altFormat: 'd-m-Y',
              disableMobile: true,
            })
            break
          case 'end_date':
            flatpickr(filterDateEnd, {
              defaultDate: value,
              altInput: true,
              altFormat: 'd-m-Y',
              disableMobile: true,
            })
            break
        }
      })
    }
  }
}
