import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IEdition, ISimpleEdition } from '~src/api/types/configurator'
import BottomOverlay from '~src/apps/Comparison/BottomOverlay'
import ComparisonPage from '~src/apps/Comparison/ComparisonPage'
import { useRootState } from '~src/hooks/store'
import {
  addToCompareSelection,
  clearCompareSelection,
  removeFromCompareSelection,
  swapComparison,
} from '~src/store/comparison/actions'
import { buildInitialState, setInitialState } from '~src/store/initialization'
import { isMobile } from '~src/utils/breakpoints'
import RemoveIcon from '~svg/remove.svg'

type Props = {
  apiUrl?: string
  editionFilter?: (e: IEdition) => boolean
}

const ComparisonBar = ({ apiUrl = '', editionFilter }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const {
    api: { models },
    comparison: { selectedCarCodes },
  } = useRootState()
  const allEditions = models.flatMap((model) => model.editions)
  const allowedEditions = editionFilter ? allEditions.filter(editionFilter) : allEditions

  useEffect(() => {
    if (apiUrl) {
      buildInitialState(apiUrl)
        .then((s) => dispatch(setInitialState(s)))
        .catch((e) => console.log(e))
    }
    dispatch(clearCompareSelection())
  }, [])

  const maxSelections = isMobile() ? 2 : 3

  const slicedCarCodes = selectedCarCodes.slice(0, maxSelections)

  if (!selectedCarCodes.length) {
    document.body.classList.remove('comparebar-open')
    return null
  } else {
    document.body.classList.add('comparebar-open')
  }

  const selectedEditions = slicedCarCodes
    .map((c) => allowedEditions.find((e) => e.code === c))
    .filter(Boolean)
  const unselectedEditions = allowedEditions
    .filter((e) => !selectedEditions.includes(e))
    .filter(Boolean)

  return (
    <BottomOverlay
      getToggleLabel={(isOpen) => (isOpen ? <>Verbergen</> : 'Vergelijk modellen')}
      barChildren={(open) => (
        <div className="d-flex">
          {!isMobile() && <div className="col-lg-3"></div>}
          {selectedEditions.map((edition) => (
            <div key={edition.code} className="col-6 col-lg-3 comparison-bar__item">
              <div className="comparison-bar__item-title">
                <h5>{edition.name}</h5>
                {open ? (
                  <SwapSelect
                    currentEdition={edition}
                    editions={unselectedEditions}
                    onChange={(event) => {
                      dispatch(
                        swapComparison({ oldCode: edition.code, newCode: event.target.value })
                      )
                    }}
                  />
                ) : (
                  <button
                    className="comparison-bar__remove"
                    onClick={() => dispatch(removeFromCompareSelection(edition.code))}
                  >
                    <RemoveIcon />
                  </button>
                )}
              </div>
            </div>
          ))}
          {selectedEditions.length < maxSelections && (
            <div className="col-lg-3 comparison-bar__item comparison-bar__item--placeholder">
              {open ? (
                <div className="comparison-bar__item-title">
                  <h5>Uitvoering toevoegen</h5>
                  <SwapSelect
                    editions={unselectedEditions}
                    onChange={(event) => dispatch(addToCompareSelection(event.target.value))}
                  />
                </div>
              ) : selectedEditions.length === 1 && maxSelections === 3 ? (
                <p>Selecteer minimaal 2 modellen</p>
              ) : (
                <p>Selecteer maximaal {maxSelections} modellen</p>
              )}
            </div>
          )}
        </div>
      )}
    >
      <ComparisonPage />
    </BottomOverlay>
  )
}

export default ComparisonBar

const SwapSelect = ({
  currentEdition,
  editions,
  onChange,
}: {
  currentEdition?: ISimpleEdition
  editions: ISimpleEdition[]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}) => (
  <div className="comparison-bar__select">
    <select onChange={onChange} value={currentEdition?.code}>
      {currentEdition ? (
        <option disabled value={currentEdition.code}>
          {currentEdition.name}
        </option>
      ) : (
        <option value="Uitvoering toevoegen">Uitvoering toevoegen</option>
      )}
      {editions.map((option) => (
        <option key={option.code} value={option.code}>
          {option.name}
        </option>
      ))}
    </select>
  </div>
)
