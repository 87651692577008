import React from 'react'

const RangeInput = ({
  name,
  extraClassName,
  value,
  steps: { min, max, size },
  getLabel,
  onChange,
  children,
}: {
  extraClassName?: string
  name: string
  value: number
  steps: { min: number; max: number; size: number }
  getLabel: (v: number) => string
  onChange: (v: number) => void
  children?: React.ReactNode
}): JSX.Element => {
  const id = name
  return (
    <div className={`input-range ${extraClassName ? extraClassName : ''}`}>
      <div className="input-range-label-container">
        <label className="input-range-label" htmlFor={id}>
          {children}
        </label>
        <span className="input-range-value">{getLabel(value)}</span>
      </div>
      <input
        className="input-range-slider"
        type="range"
        min={min}
        max={max}
        step={size}
        id={id}
        name={name}
        value={value}
        style={{ background: rangeBackground(toPercentage(value, min, max)) }}
        onChange={(e) => onChange(+e.target.value)}
      />
    </div>
  )
}

const toPercentage = (value: number, min: number, max: number) => (value - min) / (max - min)

/** @param value in percentage [0,1] */
const rangeBackground = (value: number) =>
  `linear-gradient(to right, #323232 0%, #323232 ${Math.round(value * 100)}%, #eaeaea ${Math.round(
    value * 100
  )}%, #eaeaea 100%)`

export default RangeInput
