import React from 'react'
import { determineWarrantyDate, getRemainingWarranty } from '~src/apps/Occasion/remaining-warranty'
import { useRootState } from '~src/hooks/store'

const WarrantyLabel = (): JSX.Element => {
  const {
    api: { occasion },
  } = useRootState()
  const warrantyDate = determineWarrantyDate(occasion?.date_first_admission, occasion?.year)

  const remainingWarrantyInYears = getRemainingWarranty({
    isNew: occasion?.is_new,
    firstAdmission: warrantyDate,
    mileage: occasion?.mileage,
  })

  if (remainingWarrantyInYears && remainingWarrantyInYears <= 0) {
    return null
  }

  return (
    <div className="label label--red label--small label--right">
      Nog {remainingWarrantyInYears} jaar garantie
    </div>
  )
}

export default WarrantyLabel
