/***
 * @name Side panel header
 *
 */

import React from 'react'
import { INavigate } from '~src/apps/DealerMap/types'
import IconCaretCircle from '~svg/arrow-caret-circle.svg'

interface ISidePanelHeader {
  overviewUrl?: string
}

const SidePanelHeader: React.FC<ISidePanelHeader & INavigate> = ({ overviewUrl, onGoHome }) => {
  const onClick = () => {
    if (overviewUrl) {
      window.location.replace(overviewUrl)
    } else {
      onGoHome()
    }
  }

  return (
    <div className="dealer-info__back">
      <button className="dealer-info__back-btn button button--back" onClick={onClick}>
        <IconCaretCircle className="icon" />
        <span>{overviewUrl ? ' Vind jouw dealer' : 'Terug naar overzicht'}</span>
      </button>
    </div>
  )
}

export default SidePanelHeader
