import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { DealerSearch } from '~src/common/DealerSearch'
import InputRadio from '~src/common/InputRadio'
import InputText from '~src/common/InputText'
import { handleFormSubmit } from '~src/dom_components/forms'
import { prefillForm } from '~src/dom_components/prefill-forms'
import { useRootState } from '~src/hooks/store'
import { setSuccessMessage } from '~src/store/trade-in/actions'

const privacyStatementText =
  'De door jou verstrekte gegevens worden uitsluitend gebruikt voor het door jou gevraagde contact of de door jou gevraagde informatie. Een dealer kan telefonisch contact opnemen.'

const ContactInfoForm = ({
  onFormValid,
  dealersApiUrl,
  quoteApiUrl,
  showInsuranceOptIn,
  apiRootUrl,
  louwmanHubBrand,
  prcodeApi,
}: {
  onFormValid: () => void
  dealersApiUrl: string
  contactFormApiUrl?: string
  quoteApiUrl: string
  apiRootUrl: string
  showInsuranceOptIn: boolean
  louwmanHubBrand?: string
  prcodeApi?: string
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const dispatch = useDispatch()
  const {
    tradeIn: { oldCar, newCar },
  } = useRootState()

  function handleFormSuccess(message: string) {
    dispatch(setSuccessMessage(message))
    onFormValid()
  }

  useEffect(() => {
    prefillForm(formRef.current)
  }, [])

  return (
    <div className="trade-in-modal__section">
      <form
        data-api-url={quoteApiUrl}
        data-prcode-api={prcodeApi}
        onSubmit={(e) => handleFormSubmit(e.nativeEvent, handleFormSuccess)}
        ref={formRef}
      >
        <div className="js-form-fields">
          {showInsuranceOptIn && (
            <>
              <h4 className="trade-in-modal__title">Suzuki Autoverzekering</h4>
              <p className="mb-4">
                Wist je dat Suzuki ook passende autoverzekeringen aanbiedt? Laat het ons weten als
                je meer informatie wil ontvangen.
              </p>
              <div className="form-group">
                <div className="form-check form-check-inline input-checkbox">
                  <input type="hidden" name="insurance_opt_in" value="false" />
                  <input
                    className="input-checkbox__input"
                    type="checkbox"
                    name="insurance_opt_in"
                    id="trade_in_car_insurance"
                    value="true"
                  />
                  <label className="input-checkbox__label" htmlFor="trade_in_car_insurance">
                    Ja, ik wil graag meer weten
                  </label>
                </div>
                <div className="invalid-feedback text-left"></div>
              </div>

              <hr className="my-4" />
            </>
          )}
          <h3 className="trade-in-modal__title">Jouw contactgegevens</h3>
          <div className="mb-4">
            Vul hieronder je contactgegevens in en wij nemen binnen één werkdag contact met je op.
          </div>
          <div className="form-group">
            <label className="input-label">Aanhef*</label>

            <div>
              <InputRadio label="Dhr." id="trade_in_genderMale" name="gender" value="m" />
              <InputRadio label="Mevr." id="trade_in_genderFemale" name="gender" value="v" />
            </div>

            <div className="invalid-feedback text-left"></div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <InputText label="Voornaam*" id="trade_in_first_name" name="first_name" />
            </div>

            <div className="col-12 col-md-6">
              <InputText
                label="Tussenvoegsels"
                id="trade_in_last_name_prefix"
                name="last_name_prefix"
              />
            </div>
          </div>
          <InputText label="Achternaam*" id="trade_in_last_name" name="last_name" />
          <InputText
            label="E-mailadres*"
            id="trade_in_email"
            name="email_address"
            type="email_address"
          />
          <InputText
            label="Telefoonnummer*"
            id="trade_in_phone_number"
            name="phone_number"
            type="tel"
          />

          <hr className="my-4" />

          <DealerSearch
            apiRootUrl={apiRootUrl}
            dealersApiUrl={dealersApiUrl}
            louwmanHubBrand={louwmanHubBrand}
          />

          <hr className="my-4" />

          <div className="form-group">
            <div className="form-check form-check-inline input-checkbox">
              <input type="hidden" name="privacy_statement" value="false" />
              <input
                className="input-checkbox__input"
                type="checkbox"
                name="privacy_statement"
                id="trade_in_privacy_statement"
                value="true"
              />
              <label className="input-checkbox__label" htmlFor="trade_in_privacy_statement">
                Ik ga akkoord met het{' '}
                <a href="/privacy-verklaring/" target="_blank" rel="noopener noreferrer">
                  privacy statement
                </a>
              </label>
            </div>
            <div className="invalid-feedback text-left"></div>
          </div>

          <input type="hidden" name="privacy_statement_text" value={privacyStatementText} />

          <div className="form-group">
            <div className="form-check form-check-inline input-checkbox">
              <input type="hidden" name="commercial_use" value="false" />
              <input
                className="input-checkbox__input"
                type="checkbox"
                name="commercial_use"
                id="trade_in_commercial_use"
                value="true"
              />
              <label className="input-checkbox__label" htmlFor="trade_in_commercial_use">
                Ja, Suzuki/Nimag mag mij op de hoogte houden van acties, aanbiedingen en
                service-berichten
              </label>
            </div>
            <div className="invalid-feedback text-left"></div>
          </div>

          <div className="form-group mb-5">
            <div className="form-check form-check-inline input-checkbox">
              <input type="hidden" name="customer_survey" value="false" />
              <input
                className="input-checkbox__input"
                type="checkbox"
                name="customer_survey"
                id="trade_in_customer_survey"
                value="true"
              />
              <label className="input-checkbox__label" htmlFor="trade_in_customer_survey">
                Ja, Suzuki/Nimag mag mij benaderen voor klantonderzoek
              </label>
            </div>
            <div className="invalid-feedback text-left"></div>
          </div>

          {/* START: Extend form with data from the store */}
          {oldCar && (
            <>
              <input type="hidden" value={oldCar.license_plate} name="trade_in_license_plate" />
              <input type="hidden" value={oldCar.mileage} name="trade_in_mileage" />
              <input type="hidden" value={oldCar.brand} name="trade_in_brand" />
              <input type="hidden" value={oldCar.brand} name="trade_in_brand" />
              <input type="hidden" value={oldCar.model} name="trade_in_model" />
              <input type="hidden" value={oldCar.version} name="trade_in_version" />
            </>
          )}

          <input
            type="hidden"
            value={oldCar?.isSmokeCar ? 'true' : 'false'}
            name="trade_in_smokers_car"
          />

          <input
            type="hidden"
            value={oldCar?.isDealerMaintained ? 'true' : 'false'}
            name="trade_in_dealer_maintained"
          />

          <input
            type="hidden"
            value={oldCar?.isDamaged ? 'true' : 'false'}
            name="trade_in_damage_description"
          />

          {newCar?.code && <input type="hidden" value={newCar.code} name="model_codes" />}

          {newCar?.edition?.code && (
            <input type="hidden" value={newCar.edition.code} name="model_edition_code" />
          )}

          <button className="button button--primary" type="submit">
            Versturen
          </button>
          <div className="form-error error js-form-error"></div>
          <div className="text-small mt-4">{privacyStatementText}</div>
        </div>
      </form>
    </div>
  )
}

export default ContactInfoForm
