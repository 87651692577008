import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CalculateMonthlyPayment from '~src/apps/Occasion/CalculateMonthlyPayment'
import BuySellCtaBlock from '~src/apps/TradeIn/BuySellCtaBlock'
import CarSpecificationsSummary from '~src/apps/TradeIn/CarSpecificationsSummary'
import CharacteristicsForm from '~src/apps/TradeIn/CharacteristicsForm'
import ChooseModel from '~src/apps/TradeIn/ChooseModel'
import ContactInfoForm from '~src/apps/TradeIn/ContactInfoForm'
import PersonalProposal from '~src/apps/TradeIn/PersonalProposal'
import RemoveCar from '~src/apps/TradeIn/RemoveCar'
import TradeInForm from '~src/apps/TradeIn/TradeInForm'
import Modal from '~src/common/Modal'
import { useRootState } from '~src/hooks/store'
import { setUsingFinancialPlan } from '~src/store/api/actions'
import {
  removeTradeInCar,
  setTradeInModalStatus,
  updateTradeInCar,
} from '~src/store/trade-in/actions'
import { StatusName } from '~src/store/trade-in/types'
import ButtonArrow from '~svg/button-arrow.svg'

const TradeInModal = ({
  licensePlateApiUrl,
  dealersApiUrl,
  contactFormApiUrl,
  quoteApiUrl,
  apiRootUrl,
  louwmanHubBrand,
  prcodeApi,
  financeApiUrl,
  onClose,
}: {
  licensePlateApiUrl?: string
  dealersApiUrl?: string
  contactFormApiUrl?: string
  quoteApiUrl?: string
  apiRootUrl?: string
  louwmanHubBrand?: string
  prcodeApi?: string
  financeApiUrl?: string
  onClose?: () => void
}) => {
  const dispatch = useDispatch()
  const [followUpStatus, setFollowUpStatus] = useState(null)

  const {
    tradeIn: { status, oldCar, newCar, successMessage },
  } = useRootState()

  const characteristicsFilled =
    oldCar &&
    typeof oldCar.isDamaged === 'boolean' &&
    typeof oldCar.isSmokeCar === 'boolean' &&
    typeof oldCar.isDealerMaintained === 'boolean'

  function afterModalClosing() {
    onClose && onClose()
    setFollowUpStatus(null)
    dispatch(setTradeInModalStatus(null))
  }

  function content(type: StatusName) {
    switch (type) {
      case 'flow-1-request-trade-in-offer':
        return (
          <>
            <h2>Inruilaanbod opvragen</h2>
            <p className="trade-in-modal__paragraph">
              Vul de gegevens in van jouw auto. De Suzuki-dealer gaat op basis daarvan een
              persoonlijk inruilvoorstel maken.
            </p>

            <CarSpecificationsSummary
              editLicensePlate={true}
              editEdition={true}
              onEdit={() => dispatch(setTradeInModalStatus('flow-1-enter-license-plate'))}
            />
            <CharacteristicsForm />
            <hr />

            <BuySellCtaBlock disabled={!characteristicsFilled} />
          </>
        )

      case 'flow-1-enter-license-plate':
        return (
          <>
            <h2>Inruilaanbod opvragen</h2>
            <p className="trade-in-modal__paragraph">
              Vul de gegevens in van jouw auto. De Suzuki-dealer gaat op basis daarvan een
              persoonlijk inruilvoorstel maken.
            </p>
            <TradeInForm
              licensePlateApiUrl={licensePlateApiUrl}
              nextStepLabel="Gegevens opvragen"
              onFormValid={() => dispatch(setTradeInModalStatus('flow-1-request-trade-in-offer'))}
            />
          </>
        )

      case 'flow-1-trade-in-lead-form':
        return (
          <>
            <h2>Auto verkopen</h2>
            <p className="trade-in-modal__paragraph">
              Vul de gegevens in van jouw auto. De Suzuki-dealer gaat op basis daarvan een
              persoonlijk inruilvoorstel maken.
            </p>

            <CarSpecificationsSummary
              editEdition={true}
              onEdit={() => dispatch(setTradeInModalStatus('flow-1-enter-license-plate'))}
            />
            <ContactInfoForm
              showInsuranceOptIn={false}
              dealersApiUrl={dealersApiUrl}
              contactFormApiUrl={contactFormApiUrl}
              quoteApiUrl={quoteApiUrl}
              apiRootUrl={apiRootUrl}
              onFormValid={() => dispatch(setTradeInModalStatus('flow-1-thank-you'))}
              louwmanHubBrand={louwmanHubBrand}
              prcodeApi={prcodeApi}
            />
          </>
        )

      case 'flow-1-thank-you':
        return (
          <>
            <div className="rich-text" dangerouslySetInnerHTML={{ __html: successMessage }}></div>

            <button className="button button--primary mt-4" data-dismiss="modal">
              Sluit
            </button>
          </>
        )

      case 'flow-2-request-trade-in-offer-with-model':
        return (
          <>
            <h2>Inruilvoorstel</h2>
            <p className="trade-in-modal__paragraph">
              Vul hieronder je gegevens in en ontvang een vrijblijvend inruilvoorstel
            </p>

            <CarSpecificationsSummary
              editLicensePlate={false}
              editEdition={false}
              onEdit={() => dispatch(setTradeInModalStatus('flow-2-enter-license-plate'))}
            />
            <ChooseModel />

            {newCar && (
              <>
                <PersonalProposal />
                <ContactInfoForm
                  showInsuranceOptIn={true}
                  dealersApiUrl={dealersApiUrl}
                  contactFormApiUrl={contactFormApiUrl}
                  quoteApiUrl={quoteApiUrl}
                  apiRootUrl={apiRootUrl}
                  onFormValid={() => dispatch(setTradeInModalStatus('flow-2-thank-you'))}
                  louwmanHubBrand={louwmanHubBrand}
                  prcodeApi={prcodeApi}
                />
              </>
            )}
          </>
        )

      case 'flow-2-thank-you':
        return (
          <>
            <div className="rich-text" dangerouslySetInnerHTML={{ __html: successMessage }}></div>

            <button className="button button--primary mt-4" data-dismiss="modal">
              Sluit
            </button>
          </>
        )

      case 'flow-2-enter-license-plate':
        return (
          <>
            <h2>Inruilvoorstel</h2>
            <p className="trade-in-modal__paragraph">
              Vul de gegevens in van jouw auto en bekijk vrijblijvend je inruilvoorstel
            </p>
            <TradeInForm
              licensePlateApiUrl={licensePlateApiUrl}
              nextStepLabel="Gegevens opvragen"
              onFormValid={() =>
                dispatch(setTradeInModalStatus('flow-2-request-trade-in-offer-with-model'))
              }
            />
          </>
        )

      case 'flow-3-enter-license-plate':
        return (
          <>
            <h2>Inruilvoorstel</h2>
            <p className="trade-in-modal__paragraph">
              Vul de gegevens in van jouw auto. De Suzuki-dealer gaat op basis daarvan een
              persoonlijk inruilvoorstel maken.
            </p>

            <TradeInForm
              licensePlateApiUrl={licensePlateApiUrl}
              onFormValid={() => dispatch(setTradeInModalStatus('flow-3-add-car'))}
              prcodeApi={prcodeApi}
            />
          </>
        )

      case 'flow-3-add-car':
        return (
          <>
            <h2>Inruilaanbod opvragen</h2>

            <p className="trade-in-modal__paragraph">
              Vul de gegevens in van jouw auto. De Suzuki-dealer gaat op basis daarvan een
              persoonlijk inruilvoorstel maken.
            </p>

            <CarSpecificationsSummary
              editLicensePlate={true}
              editEdition={true}
              onEdit={() => dispatch(setTradeInModalStatus('flow-3-enter-license-plate'))}
            />

            <CharacteristicsForm />

            <hr />

            <button
              className="button button--primary"
              type="button"
              disabled={!characteristicsFilled}
              onClick={() => {
                dispatch(updateTradeInCar({ isCompleted: true }))
                ;(followUpStatus && dispatch(setTradeInModalStatus(followUpStatus))) ||
                  afterModalClosing()
              }}
              data-dismiss={followUpStatus ? null : 'modal'}
            >
              Auto toevoegen
              <ButtonArrow className="icon" />
            </button>
          </>
        )

      case 'flow-3-remove-car':
        return (
          <>
            <RemoveCar
              onRemove={() => {
                dispatch(removeTradeInCar())
                dispatch(setTradeInModalStatus(null))
              }}
            />
          </>
        )

      case 'flow-4-trade-option':
        return (
          <>
            <h2>Auto inruilen</h2>

            <p className="mb-3">
              Heb je een auto in te ruilen? Dat kan invloed hebben op je financiering
            </p>

            <div className="trade-in-modal__trade-options">
              <button
                className="button button--secondary"
                onClick={() => dispatch(setTradeInModalStatus('flow-4-calculate-monthly-payment'))}
              >
                Nee, toon financieren
              </button>

              <button
                className="button button--secondary"
                onClick={() => {
                  setFollowUpStatus('flow-4-calculate-monthly-payment')
                  dispatch(setTradeInModalStatus('flow-3-enter-license-plate'))
                }}
              >
                Ja, toon auto inruilen
              </button>
            </div>
          </>
        )

      case 'flow-4-calculate-monthly-payment':
        return <CalculateMonthlyPayment financeApiUrl={financeApiUrl} apiRootUrl={apiRootUrl} />

      case 'flow-4-remove-finance':
        return (
          <>
            <h4>Financiering verwijderen</h4>

            <p>Weet je zeker dat je de financiering wilt verwijderen</p>

            <div className="d-flex mt-4">
              <button className="button button--secondary mr-3" data-dismiss="modal">
                Annuleren
              </button>

              <button
                className="button button--primary"
                onClick={() => {
                  dispatch(setUsingFinancialPlan(false))
                  dispatch(setTradeInModalStatus(null))
                }}
              >
                Verwijderen
              </button>
            </div>
          </>
        )

      default:
        return null
    }
  }

  return (
    <Modal open={!!status} onClose={afterModalClosing}>
      <div className="trade-in-modal">{content(status)}</div>
    </Modal>
  )
}

export default TradeInModal
