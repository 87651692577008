import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { setEdition, setModel } from '~src/store/configuration/actions'
import { gotoPage, setFinancialPlan, setInitialNavigationState, setNavigation } from './actions'
import { FinancialPlan, INavigationState, StepName } from './types'

export const initialState: INavigationState = {
  financeModal: false,
  currentStep: 'model',
}

const navigationReducer = createReducer(initialState, {
  [gotoPage.toString()]: (state, action: PayloadAction<StepName>) => ({
    ...state,
    currentStep: action.payload,
  }),
  [setNavigation.toString()]: (_, action: PayloadAction<INavigationState>) => action.payload,
  [setFinancialPlan.toString()]: (state, action: PayloadAction<FinancialPlan>) => ({
    ...state,
    financialPlan: action.payload,
  }),
  [setModel.toString()]: (state) => ({
    ...state,
    currentStep: 'edition',
  }),
  [setEdition.toString()]: (state) => ({
    ...state,
    currentStep: 'configure',
  }),
  [setInitialNavigationState.toString()]: (state, action: PayloadAction<INavigationState>) => ({
    ...state,
    ...action.payload,
  }),
})

export default navigationReducer
