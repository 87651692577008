import Glide from '@glidejs/glide'
import { ArrowDisabler, FixBoundPeek } from '../utils/glide-functions.js'
import { breakpoints } from '../utils/breakpoints'

export default function EditionsCarousel() {
  // Handle click on colors
  const colors = document.querySelectorAll('.editions-carousel-item__color')
  ;[].forEach.call(colors, (color: HTMLButtonElement) =>
    color.addEventListener('click', handleColorClick)
  )

  // Create carousels
  const carousels = document.querySelectorAll('.editions-carousel-block .glide')
  ;[].forEach.call(carousels, createCarousel)
}

// When a user clicks an edition color, change the image and prices
function handleColorClick(event: Event) {
  event.preventDefault()
  const button = event.target as HTMLButtonElement
  const card = button.closest('.editions-carousel-item')
  const colors = card.querySelectorAll('.editions-carousel-item__color')
  const imageEl: HTMLImageElement = card.querySelector('.editions-carousel-item__image img')
  const pricePrimaryEl = card.querySelector('.editions-carousel-item__price-primary')
  const priceSecondaryEl = card.querySelector('.editions-carousel-item__price-secondary')
  const configuratorLink = card.querySelector('.editions-carousel-item__cta-link')

  // Get data and check
  const { img, pricePrimary, priceSecondary, configuratorUrl } = button.dataset
  if (!card || !img || !pricePrimary) {
    throw new Error('Can not update card, not all data is available')
  }

  // Remove old active states
  ;[].forEach.call(colors, (color: HTMLButtonElement) => color.classList.remove('active'))

  // Set active state
  button.classList.add('active')

  // Set image and price and url
  imageEl.src = img
  pricePrimaryEl.innerHTML = pricePrimary

  if (configuratorLink) {
    configuratorLink.setAttribute('href', configuratorUrl)
  }

  // If available, set secondary price
  if (priceSecondary && priceSecondaryEl) {
    priceSecondaryEl.innerHTML = priceSecondary
  }
}

// Create a carousel with Glide
function createCarousel(carousel: HTMLElement) {
  // Note: breakpoints go DOWN!
  const options = {
    type: 'slider',
    bound: true,
    focusAt: 0,
    autoplay: false,
    keyboard: true,
    swipe: true,
    rewind: false,
    animationTimingFunc: 'ease-in-out',
    // very high resolutions
    perView: 3,
    gap: 40,
    peek: { before: 20, after: 300, afterLast: 40 },
    breakpoints: {
      [breakpoints.md - 1]: {
        // mobile
        perView: 1,
        gap: 16,
        peek: { before: 20, after: 50, afterLast: 20 },
      },
      [breakpoints.lg - 1]: {
        // tablet
        perView: 1,
        gap: 20,
        peek: { before: 20, after: 275, afterLast: 20 },
      },
      [breakpoints.xl - 1]: {
        // desktop
        perView: 2,
        gap: 20,
        peek: { before: 20, after: 330, afterLast: 20 },
      },
      [breakpoints.xxl - 1]: {
        // desktop xl
        perView: 2,
        gap: 40,
        peek: { before: 20, after: 350, afterLast: 40 },
      },
      1920: {
        // desktop xxl
        perView: 2,
        gap: 40,
        peek: { before: 20, after: 300, afterLast: 40 },
      },
    },
  }

  // @ts-ignore
  new Glide(carousel, options).mutate([FixBoundPeek]).mount({ ArrowDisabler })
}
