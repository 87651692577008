import { FacetName, SortType } from '~src/api/types/occasion'
import { numberFormatter, currencyFormatter } from '~src/utils/number-formatter'

export const formattedOptionName = (id: FacetName, name: string) =>
  'Mileage' === id
    ? `${numberFormatter.format(Number(name))} km`
    : 'FinanceMethod' === id
    ? `${name === 'directbuy' ? 'Aanschafprijs' : 'Financiering'}`
    : 'PriceOnline' === id
    ? `${currencyFormatter.format(Number(name))}`
    : 'PriceLease' === id
    ? `${currencyFormatter.format(Number(name))} p/m`
    : name

export const sortLabels = (s: SortType) => {
  switch (s) {
    case 'mileage-asc':
      return 'Kilometerstand (laag > hoog)'
    case 'mileage-desc':
      return 'Kilometerstand (hoog > laag)'
    case 'price-asc':
      return 'Prijs (laag > hoog)'
    case 'price-desc':
      return 'Prijs (hoog > laag)'
    case 'year-asc':
      return 'Bouwjaar (laag > hoog)'
    case 'year-desc':
      return 'Bouwjaar (hoog > laag)'
    default:
      return 'Recent aanbod'
  }
}
