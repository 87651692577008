import $ from 'jquery'
import 'bootstrap/js/dist/tooltip'

// Turn our custom tooltips (created in Django) into Bootstrap tooltips
const tooltips: NodeListOf<HTMLSpanElement> = document.querySelectorAll('.js-tooltip')
;[].forEach.call(tooltips, (tooltipEl: HTMLSpanElement) => {
  tooltipEl.innerHTML = ''
  tooltipEl.dataset.toggle = 'tooltip'
  tooltipEl.dataset.title = tooltipEl.dataset.content
  tooltipEl.classList.add('tooltip-toggler', 'active')
})

// Enable Bootstrap tooltips
document.addEventListener('DOMContentLoaded', function () {
  $('[data-toggle="tooltip"]').tooltip()
})
