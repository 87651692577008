import React from 'react'

function SelectInput<T>({
  options,
  nullLabel,
  value,
  getLabel,
  getValue,
  onChange,
  disabled = false,
  size = 'large',
}: {
  options: T[]
  value?: string
  nullLabel?: string
  getValue: (i: T) => string
  getLabel: (i: T) => string
  onChange: (i: string) => void
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
}) {
  const classes = ['input-select']
  if (size === 'small') classes.push('input-select--small')
  if (size === 'medium') classes.push('input-select--medium')

  return (
    <div className={classes.join(' ')}>
      <select
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
        className="input-select__select"
        disabled={disabled}
      >
        {nullLabel && <option value="">{nullLabel}</option>}
        {options.map((o) => (
          <option key={getValue(o)} value={getValue(o)}>
            {getLabel(o)}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectInput
