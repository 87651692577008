import React, { FocusEvent, useState } from 'react'
import InputText from './InputText'
import { sanitizeZipCode, validHouseNumber, validZipcode } from '~src/utils/address'
import clsx from 'clsx'
import { apiGetAddress } from '~src/api/client'

type Props = {
  addressApiUrl: string
  addressApiKey: string
}

export const AddressFields = ({ addressApiKey, addressApiUrl }: Props) => {
  const [isValid, setIsValid] = useState(false)
  const [postalCode, setPostalCode] = useState('')
  const [houseNumber, setHouseNumber] = useState('')
  const [houseNumberAddition, setHouseNumberAddition] = useState('')
  const [streetName, setStreetName] = useState('')
  const [city, setCity] = useState('')

  const validateAddress = async (zipcode: string, houseNumber: string) => {
    setIsValid(false)

    if (validZipcode(zipcode) && validHouseNumber(houseNumber)) {
      const response = await apiGetAddress(zipcode, houseNumber, addressApiUrl, addressApiKey)
      if (response.status === 'ok') {
        const { street, city } = response.results[0]
        setCity(city)
        setStreetName(street)

        setIsValid(true)
      }
    }
  }

  return (
    <div className={clsx('form__address', isValid && 'is-valid')}>
      <div className="row row-md-gutter-16">
        <div className="col-12 col-md-4">
          <InputText
            label="Postcode*"
            id="finance_request_form_zipcode"
            name="zipcode"
            value={postalCode}
            onChange={async (e) => {
              setPostalCode(e.target.value)
              await validateAddress(e.target.value, houseNumber)
            }}
            onBlur={(e) => setPostalCode(sanitizeZipCode(e.target.value))}
          />
        </div>

        <div className="col-6 col-md-4">
          <InputText
            type="number"
            label="Huisnummer*"
            id="finance_request_form_house_number"
            name="house_number"
            className="hide-arrows"
            value={houseNumber}
            onChange={async (e) => {
              setHouseNumber(e.target.value)
              await validateAddress(postalCode, e.target.value)
            }}
          />
        </div>

        <div className="col-6 col-md-4">
          <InputText
            label="Toevoeging"
            id="finance_request_form_house_number_addition"
            name="house_number_addition"
            value={houseNumberAddition}
            onChange={async (e) => {
              setHouseNumberAddition(e.target.value)
              await validateAddress(postalCode, houseNumber)
            }}
          />
        </div>
      </div>

      <div className="row row-md-gutter-16">
        <div className="col-12 col-md-6">
          <InputText
            label="Straatnaam*"
            id="finance_request_form_street_name"
            name="street_name"
            value={streetName}
            onChange={(e) => setStreetName(e.target.value)}
          />
        </div>

        <div className="col-12 col-md-6">
          <InputText
            label="Plaats*"
            id="finance_request_form_city"
            name="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
