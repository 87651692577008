import React from 'react'
import { IDrawer } from './types/IDrawer'
import IconCross from '~svg/cross-fat.svg'

interface Props extends IDrawer {
  children?: React.ReactNode
}

const Drawer = ({ children, drawerOpen, handleClose }: Props) => {
  return (
    <div className={`drawer${drawerOpen ? ' open' : ''}`}>
      <div className="drawer__close" onClick={handleClose}>
        <IconCross className="drawer__close__icon" />
      </div>
      {children}
    </div>
  )
}

export default Drawer
