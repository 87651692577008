declare global {
  interface Window {
    currentBreakpoint: any
  }
}

window.currentBreakpoint = window.currentBreakpoint || {}

export const breakpoints = {
  xs: 0,
  md: 768,
  lg: 1200,
  xl: 1440,
  xxl: 1600,
}

export function getCurrentBreakpoint() {
  // Get current breakpoint.
  // So a window width of 1000px would return "768"
  return Object.values(breakpoints)
    .filter((size) => window.innerWidth > size)
    .pop()
}

export const isMobile = () => getCurrentBreakpoint() === breakpoints.xs
