import { createPortal } from 'react-dom'

type Props = {
  children: React.ReactNode
  selector?: string
}

const ReactPortal = ({ selector, children }: Props): JSX.Element => {
  const domNode = selector ? document.querySelector(selector) : document.body

  return createPortal(children, domNode)
}

export default ReactPortal
