/** Removes all undefined and null properties */
export const pruned = <T>(input: T): Partial<T> => {
  return !(input instanceof Object)
    ? input
    : Object.entries(input).reduce(
        (result, [k, v]) =>
          v === null || v === undefined
            ? result
            : ((result[k] =
                v instanceof Array ? v.map(pruned) : v instanceof Object ? pruned(v) : v),
              result),
        {} as any
      )
}

export const isObjectEmpty = (objectName: Object) => {
  return JSON.stringify(objectName) === '{}'
}
