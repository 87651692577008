// note these values need to be url-friendly
export const steps = ['model', 'edition', 'configure', 'finance', 'summary'] as const
export type StepName = (typeof steps)[number]

// note these values need to be url-friendly
export const financialPlans = [
  'payment-plan',
  'financial-lease',
  'operational-lease',
  'private-lease',
] as const

export type FinancialPlan = (typeof financialPlans)[number]

export const leasePlans: FinancialPlan[] = ['private-lease', 'operational-lease']
export const creditPlans: FinancialPlan[] = ['financial-lease', 'payment-plan']

export interface INavigationState {
  financeModal: boolean
  currentStep: StepName
  financialPlan?: FinancialPlan
}

export const financialPlanLabel = (plan: FinancialPlan) =>
  ({
    'operational-lease': 'Operational Lease',
    'financial-lease': 'Financial Lease',
    'payment-plan': 'Betaalplan',
    'private-lease': 'Private Lease',
  }[plan])
