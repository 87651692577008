import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useRootState } from '~src/hooks/store'
import { removeTradeInCar, updateTradeInVersion } from '~src/store/trade-in/actions'
import { formatLicense } from '~src/utils/format_license'
import IconRemove from '~svg/remove.svg'

interface IProps {
  editEdition: boolean
  editLicensePlate?: boolean
  showDisclaimer?: boolean
  onEdit: () => void
  onRemove?: () => void
}

const CarSpecificationsSummary: React.FunctionComponent<IProps> = ({
  editEdition,
  editLicensePlate,
  showDisclaimer,
  onEdit,
  onRemove,
}) => {
  const dispatch = useDispatch()
  const {
    tradeIn: { oldCar },
  } = useRootState()

  const handleVersionSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(updateTradeInVersion(e.target.value))
  }

  const handleRemove = () => {
    onRemove()
    dispatch(removeTradeInCar())
  }

  return (
    <div className="trade-in-modal__section">
      <div className="trade-in-modal__card">
        <div className="trade-in-modal__card-header">
          <h6 className="mb-0">
            {oldCar.brand} {oldCar.model}
          </h6>
          {editLicensePlate && (
            <button type="button" className="text-link" onClick={onEdit}>
              Wijzigen
            </button>
          )}
          {onRemove && (
            <button type="button" className="ml-auto" onClick={handleRemove}>
              <IconRemove className="icon" />
            </button>
          )}
        </div>
        <div className="trade-in-modal__card-list">
          <div className="trade-in-modal__card-item">
            <span className="text-small">Kenteken</span>
            <h5 className="mb-0">{formatLicense(oldCar.license_plate).value}</h5>
          </div>
          <div className="trade-in-modal__card-item">
            <span className="text-small">Kilometerstand</span>
            <h5 className="mb-0">{oldCar.mileage} km</h5>
          </div>
          <div className="trade-in-modal__card-item">
            <span className="text-small">Uitvoering</span>
            <h5 className="mb-0">{oldCar.version}</h5>
          </div>
        </div>
        {showDisclaimer && (
          <div className="trade-in-modal__disclaimer">
            <p className="mb-0 text-small">
              De inruilwaarde van je auto wordt op een later moment bepaald door de dealer.
            </p>
          </div>
        )}
        {editEdition && oldCar.alternative_versions.length > 1 && (
          <div className="trade-in-modal__card-edit">
            <h5>Controleer je uitvoering</h5>
            <div className="input-select">
              <select
                name="trade_in_version_edit"
                id="id_trade_in_version_edit"
                className="input-select__select"
                onChange={handleVersionSelect}
              >
                {oldCar.alternative_versions.map((version) => (
                  <option key={version.version} value={version.version}>
                    {version.version}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CarSpecificationsSummary
