import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '~src/store'
import {
  addToCompareSelection,
  clearCompareSelection,
  removeFromCompareSelection,
  swapComparison,
} from '~src/store/comparison/actions'
import { IComparisonState } from '~src/store/comparison/types'

export const initialComparisonState: IComparisonState = {
  selectedCarCodes: [],
}

const comparisonReducer = createReducer(initialComparisonState, {
  [addToCompareSelection.toString()]: (state, action: PayloadAction<string>) => ({
    ...state,
    selectedCarCodes: [...new Set([...state.selectedCarCodes, action.payload])],
  }),
  [removeFromCompareSelection.toString()]: (state, action: PayloadAction<string>) => ({
    ...state,
    selectedCarCodes: state.selectedCarCodes.filter((id) => id !== action.payload),
  }),
  [swapComparison.toString()]: (
    state,
    action: PayloadAction<{ oldCode: string; newCode: string }>
  ) => ({
    ...state,
    selectedCarCodes: state.selectedCarCodes.map((code) =>
      code === action.payload.oldCode ? action.payload.newCode : code
    ),
  }),
  [clearCompareSelection.toString()]: (state) => ({
    ...state,
    selectedCarCodes: [],
  }),
  ['initialize']: (_, action: PayloadAction<RootState>) => ({
    ...action.payload.comparison,
  }),
})

export default comparisonReducer
