import queryString from 'query-string'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useRootState } from '~src/hooks/store'
import { setTradeInModalStatus } from '~src/store/trade-in/actions'

const BuySellCtaBlock = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useDispatch()
  const {
    tradeIn: { oldCar },
  } = useRootState()

  // Remove `alternative_versions` from the object used for query parameters.
  // Reason: we don't need it and it requires extra effort to make it work because of the nested values.
  const { alternative_versions: _, ...paramsObject } = oldCar

  return (
    <div className="trade-in-modal__section">
      <h4 className="trade-in-modal__title">Waar ben je in geïnteresseerd?</h4>
      <p className="trade-in-modal__paragraph mb-4">
        Wil je je auto inruilen voor een nieuwe Suzuki? Of je auto verkopen aan de Suzuki-dealer?
      </p>

      <div className="trade-in-modal__cta">
        <h5>Een nieuwe Suzuki samenstellen</h5>
        <p className="mb-3">
          Kies welke Suzuki bij je past. Je ontvangt van de Suzuki-dealer een offerte met
          inruilvoorstel.
        </p>
        <a
          className={`button button--primary ${disabled ? 'disabled' : ''}`}
          href={
            disabled
              ? null
              : `/auto/samenstellen/?${queryString.stringify({
                  oldCar: queryString.stringify({ ...paramsObject, isCompleted: true }),
                })}`
          }
        >
          Bekijk onze modellen
        </a>
        <p className="text-small mt-3">Binnen 24 uur een persoonlijk aanbod</p>
      </div>

      <div className="trade-in-modal__cta">
        <h5>Auto verkopen</h5>
        <p className="mb-3">
          Jouw nieuwe auto kun je later nog uitzoeken. Eerst weten hoeveel je krijgt voor jouw
          huidige auto?
        </p>
        <button
          type="button"
          className="button button--secondary"
          disabled={disabled}
          onClick={() => dispatch(setTradeInModalStatus('flow-1-trade-in-lead-form'))}
        >
          Vul jouw gegevens in
        </button>
      </div>
    </div>
  )
}

export default BuySellCtaBlock
