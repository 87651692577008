document.addEventListener('DOMContentLoaded', function () {
  // Handle scroll for iOS
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  if (isSafari && window.innerWidth < 768) {
    const collapsers = document.querySelectorAll('[data-toggle="collapse"]')
    ;[].forEach.call(collapsers, (el: HTMLElement) =>
      el.addEventListener('click', handleCollapseClick)
    )
  }
})

function handleCollapseClick(event: Event) {
  const target = event.target as HTMLElement
  const category = target.closest('.faq-block__category')
  const categoryIsOpen = !!category && !!category.querySelector('[aria-expanded="true"]')

  if (categoryIsOpen) {
    setTimeout(() => {
      const questionOffset = target.getBoundingClientRect()
      const offset = questionOffset.top + window.pageYOffset - 80
      window.scrollTo({ top: offset, behavior: 'smooth' })
    }, 400)
  }
}
