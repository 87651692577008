/***
 * @name Dealer search
 *
 */

import React, { FormEvent, MouseEvent, useState } from 'react'
import { IDealerSearch, SearchType } from '~src/apps/DealerMap/types'
import IconCheckmark from '~svg/checkmark.svg'
import IconCrossCircle from '~svg/cross-circle-v2.svg'
import IconCross from '~svg/cross.svg'
import IconLocationArrow from '~svg/location-arrow.svg'
import IconSearch from '~svg/search.svg'
import IconSpinner from '~svg/spinner.svg'
import DealerFilterMotor from './DealerFilterMotor'
import IDealerFilterMotor from '../types/IDealerFilter'
import { DealerSearchLegend } from './DealerSearchLegend'
import InputRadio from '~src/common/InputRadio'

const LOCATION_INITIAL: number = 0
const LOCATION_REQUESTING: number = 1
const LOCATION_REQUEST_SUCCESS: number = 2
const LOCATION_REQUEST_FAIL: number = 3
const DealerSearch: React.FC<IDealerSearch & IDealerFilterMotor> = ({
  onGeolocationRequest,
  onSubmit,
  googleError,
  productGroup,
  formType,
  viewMode,
  isFilteringMotor,
  filter,
  setFilter,
  filterOptions,
}) => {
  const [value, setValue] = useState('')
  const [searchType, setSearchType] = useState<SearchType>('location')
  const [submitted, setSubmitted] = useState(false)
  const [location, setLocation] = useState(LOCATION_INITIAL)

  const changeHandler = (e: FormEvent<HTMLInputElement>): void => {
    setValue(e.currentTarget.value)
  }

  const changeSearchTypeHandler = (event: FormEvent<HTMLInputElement>): void => {
    setSearchType(event.currentTarget.value as 'location' | 'dealer')
  }

  const clearClickHandler = (e: FormEvent): void => {
    e.preventDefault()

    setSubmitted(false)
    setValue('')
    setLocation(LOCATION_INITIAL)

    if (onSubmit) {
      onSubmit(null, searchType)
    }
  }

  const submitHandler = (e: FormEvent) => {
    e.preventDefault()

    if (onSubmit) {
      setSubmitted(true)
      onSubmit(value, searchType)

      setLocation(LOCATION_INITIAL)
    }
  }

  const geolocationRequestHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if ('geolocation' in navigator) {
      setLocation(LOCATION_REQUESTING)

      window.navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position.coords) {
            setLocation(LOCATION_REQUEST_SUCCESS)
            onGeolocationRequest(position.coords)
          } else {
            setLocation(LOCATION_REQUEST_FAIL)
          }
        },
        () => {
          setLocation(LOCATION_REQUEST_FAIL)
        }
      )
    } else {
      setLocation(LOCATION_REQUEST_FAIL)
    }
  }

  return (
    <div className="dealer-list__search">
      <form className="dealer-search" onSubmit={submitHandler}>
        <div className="dealer-search__filter">
          <span>Zoek op:</span>
          <InputRadio
            label="Locatie"
            checked={searchType == 'location'}
            id={'location'}
            name={'location'}
            value={'location'}
            onChange={changeSearchTypeHandler}
          />
          <InputRadio
            label="Dealer"
            checked={searchType == 'dealer'}
            id={'dealer'}
            name={'dealer'}
            value={'dealer'}
            onChange={changeSearchTypeHandler}
          />
        </div>

        <div className="dealer-search__input">
          <input
            type="text"
            value={value}
            onChange={changeHandler}
            placeholder={searchType === 'location' ? 'Plaats of postcode' : 'Dealernaam'}
            className="form-control input-text"
          />
          <button className="dealer-search__btn-submit" type="submit" aria-label="search">
            <IconSearch />
          </button>
          <button
            className={
              (value.length > 0 && submitted) ||
              location === LOCATION_REQUEST_FAIL ||
              location === LOCATION_REQUEST_SUCCESS
                ? 'dealer-search__btn-clear active'
                : 'dealer-search__btn-clear'
            }
            onClick={clearClickHandler}
            aria-label="verwijder locatie"
          >
            <IconCrossCircle />
          </button>
        </div>

        <div className="dealer-search__footer">
          {location === LOCATION_INITIAL && (
            <button className="dealer-search__footer-indicator" onClick={geolocationRequestHandler}>
              <div className="dealer-search__footer-indicator-icon">
                <IconLocationArrow />
              </div>
              <span>Gebruik mijn locatie</span>
            </button>
          )}
          {location === LOCATION_REQUESTING && (
            <div className="dealer-search__footer-indicator">
              <IconSpinner className="icon-spinner" />
              <span>Verzoek om toegang tot jouw locatie</span>
            </div>
          )}
          {location === LOCATION_REQUEST_SUCCESS && (
            <div className="dealer-search__footer-indicator success">
              <div className="dealer-search__footer-indicator-icon">
                <IconCheckmark className="icon" />
              </div>
              <span>Niet juist? Gebruik het zoekvak om jouw locatie in te voeren.</span>
            </div>
          )}

          {location === LOCATION_REQUEST_FAIL && (
            <div className="dealer-search__footer-indicator fail">
              <div className="dealer-search__footer-indicator-icon">
                <IconCross className="icon" />
              </div>
              <span>
                Er is een fout opgetreden. Gebruik het zoekvak om jouw locatie in te voeren.
              </span>
            </div>
          )}
          {googleError && (
            <div className="dealer-search__footer-indicator fail mt-2">
              <div className="dealer-search__footer-indicator-icon">
                <IconCross className="icon" />
              </div>
              <span>Er zijn geen resultaten voor deze zoekterm</span>
            </div>
          )}
        </div>

        {viewMode !== 'form_cta' && productGroup === 'car' && <DealerSearchLegend />}

        {productGroup === 'motors' && !isFilteringMotor && (
          <DealerFilterMotor filter={filter} setFilter={setFilter} filterOptions={filterOptions} />
        )}
      </form>
    </div>
  )
}

export default DealerSearch
