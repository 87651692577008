import React from 'react'
import { IFinancialProduct } from '~src/api/types/configurator'
import { IItem } from '~src/api/types/occasion'
import { IDealerInfo } from '~src/apps/DealerMap/types'
import { useRootState } from '~src/hooks/store'
import { currencyFormatter, numberFormatter } from '~src/utils/number-formatter'
import {
  DEFAULT_FINAL_PAYMENT_PERCENTAGE,
  findFinancialConfig,
  getDefaultDownpaymentPercentage,
  getMonthlyCreditPayment,
} from '~src/utils/price'
import { capitalize } from '~src/utils/text'
import LocationSvg from '~svg/location.svg'

export const OccasionCard = ({
  car,
  dealer,
  rootUrl,
  product,
  showBrandName,
}: {
  car: IItem
  rootUrl: string
  product: IFinancialProduct
  showBrandName: boolean
  dealer?: IDealerInfo
}) => {
  const { configuration } = useRootState()
  const totalPrice = Number(car?.priceOnline)

  const downPayment = Math.floor(
    totalPrice * getDefaultDownpaymentPercentage(configuration.productGroup)
  )
  const credit = totalPrice - downPayment
  const finalPayment = credit * DEFAULT_FINAL_PAYMENT_PERCENTAGE
  const financialConfig = product && findFinancialConfig(credit, product, configuration)
  let description = car?.type

  const monthlyPayment =
    !!financialConfig &&
    getMonthlyCreditPayment(
      credit,
      Number(financialConfig.interest_rate) / 100,
      finalPayment,
      configuration.basic.durationMonths
    )

  try {
    description = decodeURIComponent(escape(description))
  } catch (error) {}

  return (
    <a
      className="card shadow-big-hover occasion-card"
      href={`${rootUrl}${car.id.carId}?back_url=${encodeURIComponent(window.location.href)}`}
    >
      <div style={{ zIndex: 1, position: 'absolute', top: '4px', left: '10px' }}>
        {(car.isReserved || car.isSold) && (
          <span className="label label--gray label--small">
            {car.isSold ? 'Verkocht' : 'Gereserveerd'}
          </span>
        )}
      </div>
      <picture className="card-img-top occasion-card-img-top">
        <img
          src={car.imageUrls[0] + '?width=320&format=webp'}
          alt={`${car.model} - ${description}`}
        />
      </picture>
      <div className="card-body d-flex flex-column">
        <h2 className="occasion-card__model h4">
          {showBrandName && `${car.brand} `}
          {car.model}
        </h2>
        <div className="flex-fill occasion-card__edition">{description}</div>
        <div className="occasion-card__specs text-small">
          {numberFormatter.format(car.mileage)} km &middot; {car.year} &middot; {car.fuelTypeName}
        </div>
        <div className="mt-4">
          <strong className="occasion-card__price">
            {currencyFormatter.format(car.priceOnline)}
          </strong>
          <span className="text-small mx-2">of</span>
          <strong className="occasion-card__price">
            {currencyFormatter.format(monthlyPayment, 'round')}{' '}
          </strong>{' '}
          <small className="text-small" style={{ fontWeight: 'bold' }}>
            p/m*
          </small>
        </div>
        {dealer?.city && (
          <div className="mt-2 mt-lg-1" style={{ fontSize: 12 }}>
            <LocationSvg width={10} height={12} style={{ position: 'relative', top: '-2px' }} />{' '}
            {capitalize(dealer.city.toLowerCase())}
          </div>
        )}
      </div>
    </a>
  )
}
