import $ from 'jquery'
import { apiPost } from '~src/api/client'

const orderForm = document.querySelector('.js-add-to-cart-form')
const shoppingCartBadge = document.querySelector('.js-shopping-cart-badge')
const changeVariantInputs: NodeListOf<HTMLSelectElement> = document.querySelectorAll(
  '.js-change-variant'
)

// Handle variant dropdowns
;[].forEach.call(changeVariantInputs, (input: HTMLSelectElement) =>
  input.addEventListener('change', handleVariantChange)
)

// Handle form submit
orderForm && orderForm.addEventListener('submit', handleFormSubmit)

async function handleFormSubmit(event: Event) {
  event.preventDefault()
  const form = event.target as HTMLFormElement
  const formError = form.querySelector('.js-form-error')
  const formValues = $(this).serializeArray()
  const apiUrl = form.dataset.apiUrl

  // Disable input fields and button, reset form error
  const inputElements = form.querySelectorAll<HTMLFormElement>('button, input, select')
  inputElements.forEach((input) => (input.disabled = true))
  formError.innerHTML = ''

  // Get form values
  const data = formValues.reduce(
    (result, item) => ({
      ...result,
      [item.name]: item.value,
    }),
    {}
  )

  // Submit data to API
  try {
    const response = await apiPost<{
      cart_count: number
    }>(apiUrl, data)

    // Set amount in badge in header
    if (shoppingCartBadge) {
      shoppingCartBadge.innerHTML = String(response.data.cart_count)
      shoppingCartBadge.classList.remove('d-none')
    }

    // Re-enable form elements
    inputElements.forEach((input) => (input.disabled = false))

    // Open modal
    $('#modal-shopping-cart').modal('show')
  } catch (error) {
    // Show generic error if API completely fails
    if (formError) {
      formError.innerHTML =
        'Er gaat helaas iets fout bij het verzenden van het formulier, probeer het alsjeblieft nogmaals.'
    }

    // Re-enable form elements
    inputElements.forEach((input) => (input.disabled = false))

    // Throw error
    console.error(error.response)
    throw new Error('Can not submit form')
  }
}

function handleVariantChange(event: Event) {
  const target = event.target as HTMLSelectElement
  window.location.href = target.value
}
