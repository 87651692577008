import { apiPostNewsletter } from '~src/api/client'

type Values = {
  commercial_use: string
  customer_survey: string
  email: string
}

export default function NewsletterForm() {
  const component = document.querySelector('.js-newsletter-form')
  const form: HTMLFormElement = component?.querySelector('form')

  if (!form) {
    return
  }

  const initialState: HTMLDivElement = component.querySelector('.newsletter-block__initial')
  const successState: HTMLDivElement = component.querySelector('.newsletter-block__success')
  const failedState: HTMLDivElement = component.querySelector('.newsletter-block__failed')
  const inputError: HTMLSpanElement = component.querySelector('.newsletter-block__error')
  const productGroup: string = form.dataset.productGroup
  const newsletterApiUrl: string = form.dataset.apiUrl

  form.addEventListener('submit', (event: Event) => {
    event.preventDefault()

    // Get form values
    const formValues = $(form).serializeArray()
    const data = formValues.reduce(
      (result, item) => ({ ...result, [item.name]: item.value }),
      {}
    ) as Values

    if (data.email !== '') {
      subscribe(data.email, data.commercial_use, data.customer_survey)
    }
  })

  const subscribe = async (email: string, commercial: string, survey: string) => {
    try {
      await apiPostNewsletter(newsletterApiUrl, productGroup, email, commercial, survey)

      initialState.classList.add('d-none')
      successState.classList.add('d-flex')
    } catch (error) {
      if (error.response.data.email_address) {
        inputError.innerText = error.response.data.email_address[0]
        inputError.classList.remove('d-none')
      } else {
        initialState.classList.add('d-none')
        failedState.classList.add('d-flex')
      }
    }
  }
}
