import Glide from '@glidejs/glide'
import { ArrowDisabler, FixBoundPeek } from '../utils/glide-functions.js'
import { breakpoints } from '../utils/breakpoints'

document.addEventListener('DOMContentLoaded', function () {
  const carousels = document.querySelectorAll('.product-teaser-block .glide')
  ;[].forEach.call(carousels, createCarousel)
})

// Create a carousel with Glide
function createCarousel(carousel: HTMLElement) {
  if (!carousel) {
    return
  }

  // Note: breakpoints go DOWN!
  const options = {
    type: 'slider',
    bound: true,
    focusAt: 0,
    autoplay: false,
    keyboard: true,
    swipe: true,
    rewind: false,
    animationTimingFunc: 'ease-in-out',
    // very high resolutions
    perView: 3,
    gap: 40,
    peek: { before: 20, after: 300, afterLast: 40 },
    breakpoints: {
      [breakpoints.md - 1]: {
        // mobile
        perView: 1,
        gap: 16,
        peek: { before: 20, after: 46, afterLast: 20 },
      },
      [breakpoints.lg - 1]: {
        // tablet
        perView: 1,
        gap: 40,
        peek: { before: 20, after: 285, afterLast: 20 },
      },
      [breakpoints.xl - 1]: {
        // desktop
        perView: 2,
        gap: 20,
        peek: { before: 20, after: 270, afterLast: 20 },
      },
      [breakpoints.xxl - 1]: {
        // desktop xl
        perView: 2,
        gap: 40,
        peek: { before: 20, after: 300, afterLast: 40 },
      },
      1920: {
        // desktop xxl
        perView: 2,
        gap: 40,
        peek: { before: 20, after: 300, afterLast: 40 },
      },
    },
  }

  // @ts-ignore
  new Glide(carousel, options).mutate([FixBoundPeek]).mount({ ArrowDisabler })
}
