import React, { ReactNode } from 'react'

type Props = {
  name: string
  id: string
  children: ReactNode
}

const InputCheckbox = ({ name, id, children }: Props): JSX.Element => (
  <div className="form-group">
    <div className="form-check form-check-inline input-checkbox">
      <input className="input-checkbox__input" type="checkbox" name={name} id={id} value="true" />
      <label className="input-checkbox__label" htmlFor={id}>
        {children}
      </label>
    </div>
    <div className="invalid-feedback text-left"></div>
  </div>
)

export default InputCheckbox
