import { useSelector } from 'react-redux'
import { RootState } from '~src/store'

export const useRootState = () => useSelector((s: RootState) => s)

export const useModel = () => {
  const {
    api: { models },
    configuration: { model },
  } = useRootState()
  return models.find((m) => m.code === model)
}
