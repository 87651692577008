import React from 'react'
import Carousel from '~src/common/Carousel/Carousel'
import { fallbackImageCommerce } from '~src/utils/image'

type Image = {
  url: string
  caption?: string
}

type Props = {
  images: Image[]
}

const ProductCarousel = ({ images }: Props): JSX.Element => {
  const slides = images.filter((images) => images.url).slice(0, 5)

  return slides.length ? (
    <Carousel
      className="product-carousel"
      loop={false}
      thumbnails={slides.map((slide, index) => (
        <div key={`thumb-${index}`} className="product-carousel__thumb">
          <img src={slide.url} alt={slide.caption} width={103} height={77} />
        </div>
      ))}
    >
      {slides.map((slide, index) => (
        <figure key={`slide-${index}`} className="product-carousel__slide">
          <img
            className="product-carousel__image"
            src={slide.url}
            alt={slide.caption}
            onError={(e) => (e.currentTarget.src = fallbackImageCommerce)}
            width={880}
            height={660}
            itemProp="image"
          />

          {slide.caption && (
            <figcaption className="product-carousel__caption">{slide.caption}</figcaption>
          )}
        </figure>
      ))}
    </Carousel>
  ) : null
}

export default ProductCarousel
