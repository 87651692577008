/**
 * Joins a list of phrases to a Dutch summary separated by commas (but the last)
 *
 * @example
 * ```js
 * joinPhrases(['Kwik', 'Kwek', 'Kwak']) // 'Kwik, Kwek en Kwak'
 * ```
 */
export const joinPhrases = (phrases: string[]) =>
  `${phrases.slice(0, -1).join(', ')} en ${phrases.slice(-1)}`

/**
 * Capitalizes the first letter of the input.
 *
 * @example
 * ```js
 * capitalize('test') // 'Test'
 * ```
 */
export function capitalize(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1)
}

/**
 * Replace Markdown links with <a></a>
 */
export function applyMarkdownLink(value: string): string {
  if (value)
    return value?.replaceAll(
      /\[([^\]]+)\]\(([^\)]+)\)/g,
      `<a href="$2" target="blank" rel="noopener noreferrer">$1</a>`
    )
}
