/***
 * @name Gallery - component
 *
 */

import render from './renderComponent'

// Local imports
const $root = [...Array.from(document.querySelectorAll('.js-gallery'))]

if ($root.length > 0) {
  $root.forEach(($item: HTMLDivElement) => render($item, $item.dataset.title, $item.dataset.target))
}
