import clsx from 'clsx'
import React from 'react'

type Props = {
  selectedIndex: number
  onClick: (index: number) => void
  children: React.ReactElement[]
}

const CarouselThumbs = ({ selectedIndex, onClick, children }: Props): JSX.Element => {
  return (
    <div className="embla-thumbs" data-testid="carousel-thumbs">
      {children.map((child, i) => (
        <button
          key={i}
          type="button"
          className={clsx('embla-thumbs__item', selectedIndex === i && 'selected')}
          onClick={() => onClick(i)}
        >
          {child}
        </button>
      ))}
    </div>
  )
}

export default CarouselThumbs
