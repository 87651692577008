import $ from 'jquery'
import queryString from 'query-string'

// If querystring contains modal reference, open that modal
// For instance ?modal=modal-contact
const search = queryString.parse(location.search)
const hash = queryString.parse(location.hash)

if (search.modal) {
  $(`#${search.modal}`).modal('show')
}

if (hash.modal) {
  $(`#${hash.modal}`).modal('show')
}

// if hash contains modal reference, open that modal
// For instance #modal=modal-contact-nimag
window.addEventListener(
  'hashchange',
  function (event) {
    const hashString = queryString.parse(location.hash)
    const oldHashString = queryString.parse(new URL(event.oldURL).hash)

    // When a modal closes, make sure to remove its hash from the URL.
    $('.modal').on('hide.bs.modal', () => history.replaceState(null, null, ' '))

    // Open a specific modal when its hash is removed from the URL.
    if (hashString.modal) {
      $(`#${hashString.modal}`).modal('show')
    }

    // Close a specific modal when its hash is removed from the URL.
    if (oldHashString.modal) {
      $(`#${oldHashString.modal}`).modal('hide')
    }
  },
  false
)
