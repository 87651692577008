/***
 * @name Overlay cookie settings - Render overlay (show & hide)
 *
 */

import React from 'react'
import { createRoot } from 'react-dom/client'
import Overlay from './index'

let isOpen: boolean = false

const render = (showOverlay: boolean = true, component?: React.ReactElement) => {
  const $root: HTMLElement = document.querySelector('.js-overlay')

  if ($root) {
    const reactDomRoot = createRoot($root)
    reactDomRoot.render(
      <Overlay show={showOverlay} onClose={hide}>
        {component}
      </Overlay>
    )
  }
}

const show = (component?: React.ReactElement) => {
  if (!isOpen) {
    isOpen = true
    render(true, component)
  }
}

const hide = () => {
  if (isOpen) {
    isOpen = false
    render(false)
    window.location.hash = (window as any).lastHash || ''
  }
}

export { show, hide }
