import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'
import ScrollLock from 'react-scrolllock'
import { CSSTransition, config } from 'react-transition-group'
import { useClickAway, useKey } from 'react-use'
import ReactPortal from '~src/common/ReactPortal'
import CrossFatIcon from '~svg/cross-fat.svg'

const Modal = ({
  open,
  onClose,
  onOpened,
  children,
  className,
  headerTitle,
  animated = true,
}: {
  open: boolean
  onClose: () => void
  onOpened?: () => void
  children: React.ReactNode
  className?: string
  headerTitle?: string
  animated?: boolean
}): JSX.Element => {
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    config.disabled = !animated
    if (onOpened) onOpened()
  }, [])

  useKey('Escape', onClose)

  useClickAway<MouseEvent>(contentRef, (event) => {
    // Only close modal when clicking main (usually the left) mouse button.
    // If there is more than 1 modal open, ignore the click.
    const container = document.querySelector('.js-react-modal')
    if (event.button === 0 && container.children.length === 1) onClose()
  })

  return (
    <>
      <ReactPortal selector=".js-react-modal">
        <CSSTransition mountOnEnter unmountOnExit in={open} timeout={300} classNames="react-modal">
          <div>
            <ScrollLock isActive={open}>
              <div className={clsx('modal react-modal', className && className)}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content" ref={contentRef}>
                    <div className="modal-header">
                      {headerTitle && <h3 className="modal__title">{headerTitle}</h3>}
                      <button
                        type="button"
                        className="modal__close"
                        data-dismiss="modal"
                        aria-label="Sluit"
                        onClick={onClose}
                      >
                        <CrossFatIcon height={18} fill="white" />
                      </button>
                    </div>
                    <div className="modal-body">{children}</div>
                  </div>
                </div>
              </div>
            </ScrollLock>
            <div className="modal-backdrop react-modal-backdrop"></div>
          </div>
        </CSSTransition>
      </ReactPortal>
    </>
  )
}
export default Modal
