/**
 * Sums the values of a list of objects calculated by a callback.
 *
 * @example
 * ```js
 * sum([{price: 1}, {price: 2}], x => x.price) // 3
 * ```
 */
export const sum = <T>(items: T[], getValue: (i: T) => number): number =>
  items.reduce((p, c) => p + getValue(c), 0)

export const findMin = <T>(items: T[], getValue: (i: T) => number): T =>
  items.reduce((a, b) => (getValue(a) < getValue(b) ? a : b), 0 as unknown as T)

export const floorDecimals = (value: number, decimals: number): number =>
  Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals)

export const roundDecimals = (value: number, decimals: number): number =>
  Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)

export const restrictBetween = (value: number, min: number, max: number): number => {
  if (value <= min) return min
  if (value >= max) return max
  return value
}
