import React from 'react'

const InputRadio = ({
  label,
  id,
  name,
  value,
  onChange,
  checked,
}: {
  label: string
  id: string
  name: string
  value: string
  onChange?: (event: React.FormEvent) => void
  checked?: boolean
}) => (
  <div className="form-check form-check-inline input-radio">
    <input
      className="input-radio__input"
      type="radio"
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      checked={checked}
    />
    <label className="input-radio__label" htmlFor={id}>
      {label}
    </label>
  </div>
)

export default InputRadio
