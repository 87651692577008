import { ILicensePlate } from '~src/store/trade-in/types'

const validNumbers = [
  /^([a-zA-Z]{2})([\d]{2})([\d]{2})$/, // 1 XX-99-99
  /^([\d]{2})([\d]{2})([a-zA-Z]{2})$/, // 2 99-99-XX
  /^([\d]{2})([a-zA-Z]{2})([\d]{2})$/, // 3 99-XX-99
  /^([a-zA-Z]{2})([\d]{2})([a-zA-Z]{2})$/, // 4 XX-99-XX
  /^([a-zA-Z]{2})([a-zA-Z]{2})([\d]{2})$/, // 5 XX-XX-99
  /^([\d]{2})([a-zA-Z]{2})([a-zA-Z]{2})$/, // 6 99-XX-XX
  /^([\d]{2})([a-zA-Z]{3})([\d]{1})$/, // 7 99-XXX-9
  /^([\d]{1})([a-zA-Z]{3})([\d]{2})$/, // 8 9-XXX-99
  /^([a-zA-Z]{2})([\d]{3})([a-zA-Z]{1})$/, // 9 XX-999-X
  /^([a-zA-Z]{1})([\d]{3})([a-zA-Z]{2})$/, // 10 X-999-XX
  /^([a-zA-Z]{3})([\d]{2})([a-zA-Z]{1})$/, // 11 XXX-99-X
  /^([a-zA-Z]{1})([\d]{2})([a-zA-Z]{3})$/, // 12 X-99-XXX
  /^([\d]{1})([a-zA-Z]{2})([\d]{3})$/, // 13 9-XX-999
  /^([\d]{3})([a-zA-Z]{2})([\d]{1})$/, // 14 999-XX-9
]

export const formatLicense = (licensePlate: string): ILicensePlate => {
  const code = licensePlate.trim().toUpperCase()

  for (const validNumber of validNumbers) {
    const match = code.replace(/-/g, '').match(validNumber)

    if (match) {
      return {
        original: code,
        value: match.slice(1).join('-'),
        valid: true,
      }
    }
  }

  return {
    original: code,
    value: code.replace(/-/g, ''),
    valid: false,
  }
}
