import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { apiGet, apiPostTradeIn } from '~src/api/client'
import LicensePlateInput from '~src/apps/TradeIn/LicensePlateInput'
import MileageInput from '~src/apps/TradeIn/MileageInput'
import { useRootState } from '~src/hooks/store'
import { setNewCustomer, setTradeInCar } from '~src/store/trade-in/actions'
import {
  ILicensePlate,
  ITradeInCar,
  ITradeInCustomer,
  TradeInType,
} from '~src/store/trade-in/types'
import ButtonArrow from '~svg/button-arrow.svg'

const TradeInForm = ({
  onFormValid,
  nextStepLabel,
  licensePlateApiUrl,
  prcodeApi,
}: {
  onFormValid: (oldCar?: ITradeInCar) => void
  nextStepLabel?: string
  licensePlateApiUrl: string
  prcodeApi?: string
  tradeInType?: TradeInType
}) => {
  const dispatch = useDispatch()
  const [licensePlate, setLicensePlate] = useState<ILicensePlate>(null)
  const [loading, setIsLoading] = useState(false)
  const [mileage, setMileage] = useState<number | ''>('')
  const [error, setError] = useState('')
  const {
    tradeIn: { oldCar },
  } = useRootState()

  const query = queryString.parse(location.search)

  const fetchPrCode = async () => {
    try {
      if (!prcodeApi) return
      const response = await apiGet<ITradeInCustomer>(prcodeApi)

      if (response.data) {
        setLicensePlate({
          value: response.data.license_plate,
          original: response.data.license_plate,
          valid: true,
        })
        dispatch(setNewCustomer(response.data))
      }
    } catch (error) {
      console.error(error)
      setError('Er is iets misgegaan.')
    }
  }

  useEffect(() => {
    // If there is a query string with PR Code
    if (query.prcode) {
      // Do the API call to /api/prcode/auto/{PRCODE}
      fetchPrCode()
    }

    // If there is a TradeIn Car in the store, prefill the inputs
    if (oldCar) {
      setLicensePlate({ value: oldCar.license_plate, original: oldCar.license_plate, valid: true })
      setMileage(oldCar.mileage)
    }
  }, [oldCar])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError('')

    try {
      setIsLoading(true)
      const response = await apiPostTradeIn(licensePlateApiUrl, licensePlate.value, Number(mileage))
      if (response.data) {
        dispatch(setTradeInCar(response.data))
        onFormValid(response.data)
      }
    } catch (error) {
      if (error.response.data.non_field_errors) {
        setError(error.response.data.non_field_errors[0])
      } else {
        setError('Dit kenteken wordt niet herkend.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeLicense = (format: ILicensePlate) => {
    setLicensePlate(format)
    setError('')
  }

  const handleSetMileage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMileage(parseInt(event.target.value))
    setError('')
  }

  return (
    <>
      <div className="trade-in-form">
        <form className="trade-in-form__form" onSubmit={handleSubmit}>
          <LicensePlateInput
            value={licensePlate?.original ?? ''}
            disabled={loading}
            onChange={handleChangeLicense}
          />
          <MileageInput value={mileage} disabled={loading} onChange={handleSetMileage} />
          <div className="trade-in-form__item trade-in-form__item--action">
            <button
              type="submit"
              className="button button--primary"
              disabled={!licensePlate?.original || !mileage || loading}
            >
              {nextStepLabel ?? 'Volgende stap'}
              <ButtonArrow className="icon" />
            </button>
          </div>
          <div className="w-100 text-small font-italic">
            Je inruilwaarde wordt berekend via Autotelex
          </div>
        </form>
        {error && (
          <div className="trade-in-form__errors error">
            <p>{error}</p>
          </div>
        )}
      </div>
    </>
  )
}

export default TradeInForm
