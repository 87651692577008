/***
 * @name Gallery - Main
 *
 */

import React, { useState } from 'react'
import Gallery from './Gallery'
import SubNav from './SubNav'
import { show } from '~src/apps/overlay/render'
import OverlayGallery from '../overlay-gallery/Gallery'

export interface IMain {
  title?: string
  items: any[]
}

const Main: React.FC<IMain> = ({ title, items }) => {
  //
  // Component state
  const [selected, setSelected] = useState(items[0].id)
  // const [autoUpdating, setAutoUpdate] = useState(true)

  // Component variables
  const len = items.length
  const i = items.map((item) => item.id).indexOf(selected)
  const x = (i / len) * 100

  //
  // Component event handlers
  const subNavSelectedHandler = (id: string) => {
    setSelected(id)

    // setAutoUpdate(false)
  }

  //
  const galleryImageSelectedHandler = (id: string) => {
    // setAutoUpdate(false)

    show(<OverlayGallery data={items.map((item) => item.items).flat()} items={items} id={id} />)
  }

  //
  // Hooks
  // useInterval(
  //   () => {
  //     const next = items.map((item) => item.id).indexOf(selected) + 1
  //     const id = items[next >= len ? 0 : next].id

  //     setSelected(id)
  //   },
  //   autoUpdating ? 5000 : null
  // )

  //
  // Render
  return (
    <>
      {title && (
        <div className="container">
          <h2 className="block-title text-center">{title}</h2>
        </div>
      )}
      <SubNav items={items} selected={selected} onSelected={subNavSelectedHandler} />
      <div className="gallery-group">
        <div
          className="gallery-group__container"
          style={{ transform: `translate3d(-${x}%, 0, 0)`, width: `${len * 100}%` }}
        >
          {items.map((item, index) => {
            return (
              <Gallery key={`item-${index}`} {...item} onSelected={galleryImageSelectedHandler} />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Main
