import React from 'react'
import { useDispatch } from 'react-redux'
import InputRadio from '~src/common/InputRadio'
import { useRootState } from '~src/hooks/store'
import { updateTradeInCar } from '~src/store/trade-in/actions'

const CharacteristicsForm = () => {
  const dispatch = useDispatch()
  const {
    tradeIn: { oldCar },
  } = useRootState()

  const handleRadioChange = (event: React.FormEvent) => {
    const target = event.target as HTMLSelectElement
    const name: string = target.name
    const value = target.value == 'true'
    dispatch(updateTradeInCar({ [name]: value }))
  }

  return (
    <div className="trade-in-modal__section">
      <h4 className="trade-in-modal__title">Auto kenmerken</h4>
      <div>
        <div className="form-group">
          <label className="input-label feature-label">Is jouw auto een rokersauto?</label>
          <div>
            <InputRadio
              onChange={handleRadioChange}
              label="Ja"
              id="trade_in_isSmokerCarYes"
              name="isSmokeCar"
              value="true"
              checked={oldCar.isSmokeCar === true}
            />
            <InputRadio
              onChange={handleRadioChange}
              label="Nee"
              id="trade_in_isSmokerCarNo"
              name="isSmokeCar"
              value="false"
              checked={oldCar.isSmokeCar === false}
            />
          </div>
          <div className="invalid-feedback text-left"></div>
        </div>

        <div className="form-group">
          <label className="input-label feature-label">
            Wordt jouw auto door een dealer onderhouden?
          </label>
          <div>
            <InputRadio
              onChange={handleRadioChange}
              label="Ja"
              id="trade_in_isDealerMaintainedYes"
              name="isDealerMaintained"
              value="true"
              checked={oldCar.isDealerMaintained === true}
            />
            <InputRadio
              onChange={handleRadioChange}
              label="Nee"
              id="trade_in_isDealerMaintainedNo"
              name="isDealerMaintained"
              value="false"
              checked={oldCar.isDealerMaintained === false}
            />
          </div>
          <div className="invalid-feedback text-left"></div>
        </div>

        <div className="form-group">
          <label className="input-label feature-label">Heeft jouw auto schade?</label>
          <div>
            <InputRadio
              onChange={handleRadioChange}
              label="Ja"
              id="trade_in_isDamagedYes"
              name="isDamaged"
              value="true"
              checked={oldCar.isDamaged === true}
            />
            <InputRadio
              onChange={handleRadioChange}
              label="Nee"
              id="trade_in_isDamagedNo"
              name="isDamaged"
              value="false"
              checked={oldCar.isDamaged === false}
            />
          </div>
          <div className="invalid-feedback text-left"></div>
        </div>
        <p className="text-small">
          Let op! Deze kenmerken kunnen invloed hebben op de uiteindelijke inruilprijs.
        </p>
      </div>
    </div>
  )
}

export default CharacteristicsForm
