const specificationsBlocks: NodeListOf<HTMLDivElement> =
  document.querySelectorAll('.specifications-block')

;[].forEach.call(specificationsBlocks, initSpecificationBlock)

function initSpecificationBlock(specificationsBlock: HTMLDivElement) {
  const specificationsTabs: NodeListOf<HTMLDivElement> =
    specificationsBlock.querySelectorAll('.tab-pane')

  // Look for tables within each "tab" and check if they need to be collapsed/hidden.
  ;[].forEach.call(specificationsTabs, (tab: HTMLDivElement) => {
    const tabContent: HTMLDivElement = tab.querySelector('.tab-pane__content')
    const tables: NodeListOf<HTMLTableElement> = tab.querySelectorAll('table')
    const firstTable: HTMLTableElement = tab.querySelector('table')
    const toggleButton: HTMLButtonElement = tab.querySelector('.toggle-button')

    if (tables) {
      return
    }

    // Count the amount of line that are initially open
    const totalRows = firstTable.rows.length + tables.length

    // More than 8 rows are cropped and get a slide open button
    if (totalRows > 8) {
      tabContent.classList.add('faded')
      toggleButton.classList.remove('d-none')
    }

    // Each tab has its own toggle button
    toggleButton.addEventListener('click', () => toggleSpecifications(tab))
  })
}

function toggleSpecifications(tab: HTMLDivElement) {
  const tabContent: HTMLDivElement = tab.querySelector('.tab-pane__content')
  const alreadyActive = !tabContent.classList.contains('faded')

  if (!alreadyActive) {
    showContent(tab)
  } else {
    hideContent(tab)
  }
}

function showContent(tab: HTMLDivElement) {
  tab.classList.remove('faded')
  const tabContent: HTMLDivElement = tab.querySelector('.tab-pane__content')
  const tabContentOriginalHeight = tabContent.clientHeight + 'px'
  const toggleButton: HTMLButtonElement = tab.querySelector('.toggle-button')

  toggleButton.innerText = 'Verberg specificaties'
  toggleButton.classList.add('toggle-button--faded')

  // Slide content down
  // Set height to auto, so we can see how high the element should be
  tabContent.style.height = 'auto'
  tabContent.style.maxHeight = 'none'

  // Get height
  const height = tabContent.clientHeight + 1 + 'px'

  // Set height to original, so we can animate
  tabContent.style.height = tabContentOriginalHeight

  // Set height
  setTimeout(() => {
    tabContent.style.height = height
  }, 50)

  addEventListenerOnce(tabContent, 'transitionend', () => {
    tabContent.style.height = 'auto'
    tabContent.classList.remove('faded')

    // Scroll to top of tab
    const tabContentRect = tabContent.getBoundingClientRect()
    const offset = tabContentRect.top + window.pageYOffset - 80
    window.scrollTo({ top: offset, behavior: 'smooth' })
  })
}

function hideContent(tab: HTMLDivElement) {
  tab.classList.add('faded')
  const tabContent: HTMLDivElement = tab.querySelector('.tab-pane__content')
  const toggleButton: HTMLButtonElement = tab.querySelector('.toggle-button')

  toggleButton.innerText = 'Toon alle specificaties'
  toggleButton.classList.remove('toggle-button--faded')

  // Set current height
  tabContent.style.height = tabContent.clientHeight + 'px'

  setTimeout(() => {
    tabContent.classList.add('faded')
    tabContent.style.removeProperty('height')
    tabContent.style.removeProperty('max-height')

    setTimeout(() => {
      // Scroll to top of tab
      const tabContentRect = tabContent.getBoundingClientRect()
      const offset = tabContentRect.top + window.pageYOffset - 80
      window.scrollTo({ top: offset, behavior: 'smooth' })
    }, 300)
  }, 50)
}

function addEventListenerOnce(element: HTMLElement, event: string, fn: any) {
  var func = function () {
    element.removeEventListener(event, func)
    fn()
  }
  element.addEventListener(event, func)
}
