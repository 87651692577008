import React from 'react'

interface IProps {
  value: number | ''
  disabled?: boolean
  onChange: any
  onBlur?: () => void
}

const MileageInput: React.FunctionComponent<IProps> = ({ value, disabled, onChange, onBlur }) => {
  return (
    <div className="trade-in-form__item">
      <label className="input-label" htmlFor="id_mileage">
        Kilometerstand
      </label>
      <div className="trade-in-form__group trade-in-form__group--mileage">
        <input
          type="number"
          min={0}
          name="mileage"
          className="input-text"
          id="id_mileage"
          value={value ?? ''}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
        />
        <span className="trade-in-form__addon">KM</span>
      </div>
    </div>
  )
}

export default MileageInput
