import React, { useEffect, useRef, useState } from 'react'
import { DealerSearch } from '~src/common/DealerSearch'
import InputRadio from '~src/common/InputRadio'
import InputText from '~src/common/InputText'
import Modal from '~src/common/Modal'
import { handleFormSubmit } from '~src/dom_components/forms'
import { prefillForm } from '~src/dom_components/prefill-forms'

type Props = {
  apiUrl: string
  apiRootUrl: string
  prCodeApi: string
  privacyUrl: string
  dealersApiUrl: string
  brand: string
  dealerNumber?: string
  formDescription?: string
  title?: string
  text?: string
  vehicleModels?: string[][]
  vehicleEditions?: string[][]
  vehicleModelCode?: string
  vehicleEditionCode?: string
}

const privacyStatementText =
  'Als je jouw aanvraag verstuurt ga je akkoord met onze privacyverklaring. De door jou verstrekte gegevens worden uitsluitend gebruikt voor het door jou gevraagde contact of de door jou gevraagde informatie. Een dealer kan telefonisch contact opnemen.'

export const ShortFunnel = ({
  title,
  text,
  apiUrl,
  apiRootUrl,
  prCodeApi,
  privacyUrl,
  dealersApiUrl,
  dealerNumber,
  brand,
  formDescription,
  vehicleModels,
  vehicleEditions,
  vehicleModelCode,
  vehicleEditionCode,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (evt?: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    setIsOpen(true)
  }

  useEffect(() => {
    if (isOpen) prefillForm(formRef.current)
  }, [isOpen])

  return (
    <>
      <div className="text-center">
        {title && <h2 className="block-title text-center mb-4">{title}</h2>}
        {text && <p className="mb-3">{text}</p>}
        <button type="button" className="button button--primary" onClick={handleClick}>
          Neem contact met me op
        </button>
      </div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <form
          data-api-url={apiUrl}
          data-prcode-api={prCodeApi}
          onSubmit={(e) => handleFormSubmit(e.nativeEvent)}
          ref={formRef}
        >
          <div className="form__input-block js-form-fields">
            <h2 className="modal-title mb-5">Contactverzoek</h2>

            {vehicleModels.length > 1 && (
              <>
                <h3>Kies een model</h3>
                <div className="form-group">
                  <label className="input-label" htmlFor="short_funnel_form_model_code">
                    Selecteer model*
                  </label>
                  <div className="input-select">
                    <select
                      className="input-select__select"
                      id="short_funnel_form_model_code"
                      name="model_codes"
                    >
                      {vehicleModels.map(([name, code]) => (
                        <option key={code} value={code}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}

            {vehicleEditions.length > 1 && (
              <>
                <h3>Kies een uitvoering</h3>
                <div className="form-group">
                  <label className="input-label" htmlFor="short_funnel_form_model_edition_code">
                    Selecteer uitvoering*
                  </label>
                  <div className="input-select">
                    <select
                      className="input-select__select"
                      id="short_funnel_form_model_edition_code"
                      name="model_edition_code"
                    >
                      {vehicleEditions.map(([name, code]) => (
                        <option key={code} value={code}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}

            {vehicleModelCode && (
              <input type="hidden" name="model_codes" value={vehicleModelCode} />
            )}

            {vehicleEditionCode && (
              <input type="hidden" name="model_edition_code" value={vehicleEditionCode} />
            )}

            <div className="block text-block text-small mb-3">
              <div className="rich-text">
                Vul hieronder je gegevens in en de dealer neemt contact met je op.
              </div>
            </div>

            <div className="form-group">
              <label className="input-label">Aanhef*</label>
              <div>
                <InputRadio label="Dhr." id="short_funnel_genderMale" name="gender" value="m" />
                <InputRadio label="Mevr." id="short_funnel_genderFemale" name="gender" value="v" />
              </div>
              <div className="invalid-feedback text-left"></div>
            </div>

            <div className="row row-md-gutter-16">
              <div className="col-12 col-md-4">
                <InputText label="Voornaam*" id="short_funnel_first_name" name="first_name" />
              </div>
              <div className="col-12 col-md-3">
                <InputText
                  label="Tussenvg."
                  id="short_funnel_last_name_prefix"
                  name="last_name_prefix"
                />
              </div>
              <div className="col-12 col-md-5">
                <InputText label="Achternaam*" id="short_funnel_last_name" name="last_name" />
              </div>
            </div>

            <div className="row row-md-gutter-16 mb-4">
              <div className="col-12 col-md-4">
                <InputText label="Postcode*" id="short_funnel_zipcode" name="zipcode" />
              </div>
            </div>

            <InputText
              label="E-mailadres*"
              id="short_funnel_email_address"
              name="email_address"
              type="email"
            />

            <InputText
              label="Telefoonnummer*"
              id="short_funnel_phone_number"
              name="phone_number"
              type="tel"
            />

            <hr className="my-4" />

            <DealerSearch
              apiRootUrl={apiRootUrl}
              dealersApiUrl={dealersApiUrl}
              louwmanHubBrand={brand}
            />

            <hr className="my-4" />

            <div className="form-group">
              <div className="form-check form-check-inline input-checkbox">
                <input type="hidden" name="privacy_statement" value="false" />
                <input
                  className="input-checkbox__input"
                  type="checkbox"
                  name="privacy_statement"
                  id="short_funnel_privacy_statement"
                  value="true"
                />
                <label className="input-checkbox__label" htmlFor="short_funnel_privacy_statement">
                  Ik ga akkoord met het{' '}
                  <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
                    privacy statement
                  </a>
                </label>
              </div>
              <div className="invalid-feedback text-left"></div>
            </div>

            <input type="hidden" name="privacy_statement_text" value={privacyStatementText} />

            <div className="form-group">
              <div className="form-check form-check-inline input-checkbox">
                <input type="hidden" name="commercial_use" value="false" />
                <input
                  className="input-checkbox__input"
                  type="checkbox"
                  name="commercial_use"
                  id="short_funnel_commercial_use"
                  value="true"
                />
                <label className="input-checkbox__label" htmlFor="short_funnel_commercial_use">
                  Ja, Suzuki/Nimag mag mij op de hoogte houden van acties, aanbiedingen en
                  service-berichten
                </label>
              </div>
              <div className="invalid-feedback text-left"></div>
            </div>

            <div className="form-group mb-5">
              <div className="form-check form-check-inline input-checkbox">
                <input type="hidden" name="customer_survey" value="false" />
                <input
                  className="input-checkbox__input"
                  type="checkbox"
                  name="customer_survey"
                  id="short_funnel_customer_survey"
                  value="true"
                />
                <label className="input-checkbox__label" htmlFor="short_funnel_customer_survey">
                  Ja, Suzuki/Nimag mag mij benaderen voor klantonderzoek
                </label>
              </div>
              <div className="invalid-feedback text-left"></div>
            </div>

            {dealerNumber && <input type="hidden" name="dealer_number" value={dealerNumber} />}

            {brand && <input type="hidden" name="brand" value={brand} />}

            {formDescription && (
              <input type="hidden" name="form_description" value={formDescription} />
            )}

            <div className="block text-block text-small mb-5">
              <div className="rich-text">
                <p>{privacyStatementText}</p>
                <p>
                  <strong>*</strong> Deze velden zijn verplicht
                </p>
              </div>
            </div>

            <div className="form-group">
              <button type="submit" className="button button--primary">
                Verstuur
              </button>
            </div>
            <div className="form-error error js-form-error"></div>
          </div>
          <div className="form__success-block d-none js-form-success">
            <div className="block text-block js-form-success-message"></div>

            <div className="mt-3">
              <button
                type="button"
                className="button button--primary"
                onClick={() => setIsOpen(false)}
              >
                Sluit
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}
