import React, { useState, useRef } from 'react'
import ScrollLock from 'react-scrolllock'
import ButtonArrow from '~svg/button-arrow.svg'

const BottomOverlay = ({
  getToggleLabel,
  barChildren,
  children,
}: {
  getToggleLabel: (open: boolean) => React.ReactNode
  barChildren: (open: boolean) => React.ReactNode
  children: React.ReactNode
}) => {
  const [isOpen, setOpen] = useState(false)
  const modalBodyRef = useRef<HTMLDivElement>(null)

  return (
    <div className={`bottom-overlay ${isOpen ? 'bottom-overlay--expanded' : ''}`}>
      <div className="bottom-overlay-bar">
        <div className="bottom-overlay-bar__actions">
          <button
            className="button button--small button--secondary bottom-overlay-bar__button"
            onClick={() => setOpen(!isOpen)}
          >
            {getToggleLabel(isOpen)}
            <ButtonArrow className="icon" />
          </button>
        </div>
        <div className="container">{barChildren(isOpen)}</div>
      </div>
      <>
        <ScrollLock isActive={isOpen}>
          <div className="bottom-overlay-content" ref={modalBodyRef}>
            {isOpen && children}
          </div>
        </ScrollLock>
      </>
    </div>
  )
}

export default BottomOverlay
