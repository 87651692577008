export default function hideOnClickOutside(selector: string, callback: Function) {
  const element = document.querySelector(selector)

  if (!element) {
    return
  }

  const outsideClickListener = (event: Event) => {
    const target = event.target as HTMLElement
    if (!element.contains(target) && target.closest(selector) === null) {
      callback()
      removeClickListener()
    }
  }

  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener)
  }

  document.addEventListener('click', outsideClickListener)
}
