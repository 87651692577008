/**
 * @name API client
 *
 * @credit Taken from Agradi by François
 */

import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { LouwmanAddressResponse } from '~src/api/types/address'
import { IEdition, IFinancialData, ISimpleEdition } from '~src/api/types/configurator'
import { IDealerLocation, ISearchResult } from '~src/api/types/dealer'
import { ITradeInCar } from '~src/store/trade-in/types'

const baseURL = `${window.location.protocol}//${window.location.host}`

const client = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

export const apiGet = <T>(url: string) => {
  if (!url) console.error('No URL passed to apiGET')
  return client.get<T>(url)
}

export const apiPost = <T>(url: string, data: any, config: AxiosRequestConfig = {}) =>
  client.post<T>(url, data, config)

export const apiGetUsedCars = (clientId: string, limit: number, model: string = '') => {
  const filter = [
    { name: 'Brand', values: ['toyota'] },
    { name: 'ClientId', values: clientId.split(',') },
  ]
  if (model !== '') {
    filter.push({ name: 'Model', values: [model] })
  }

  return client.post<ISearchResult>(
    `https://occasions.toyota.nl/api/search?source=toyota`,
    {
      filter,
      limits: { start: 0, limit },
      sort: 'RecentDesc',
    },
    {
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
    }
  )
}

export const apiGetDealers = (url: string) => {
  if (!url) console.error('No URL passed to apiGetDealers')
  return client.get<{ locations: IDealerLocation[] }>(url).then((x) => x.data)
}

export const apiPostNewsletter = (
  url: string,
  group: string,
  email: string,
  commercialCheckbox: string,
  surveyCheckbox: string
): AxiosPromise<any[]> =>
  client.post(url, {
    product_group: group,
    email_address: email,
    commercial_use: commercialCheckbox,
    customer_survey: surveyCheckbox,
  })

export const apiGetEditionDetails = (simpleEdition: ISimpleEdition) =>
  Promise.all([
    apiGet<IEdition>(simpleEdition.detail_url).then((d) => d.data),
    apiGet<IFinancialData>(simpleEdition.financial_data_url).then((d) => d.data),
  ])

export const apiPostTradeIn = (url: string, licensePlate: string, mileage: number) => {
  if (!url) console.error('No URL passed to apiPostTradeIn')

  return client.post(url, {
    license_plate: licensePlate,
    mileage,
    url: window.location.href,
  })
}

export const apiPostQuote = async ({
  apiBaseUrl,
  oldCar,
  newCarCode,
  newCarEditionCode,
}: {
  apiBaseUrl: string
  oldCar: ITradeInCar
  newCarCode: string
  newCarEditionCode: string
}) => {
  if (!apiBaseUrl) console.error('No URL passed to apiPostTradeIn')

  return client.post(`${apiBaseUrl}trade-in/values/`, {
    license_plate: oldCar.license_plate,
    mileage: oldCar.mileage,
    model_code: newCarCode,
    model_edition_code: newCarEditionCode,
  })
}

export const apiGetAddress = async (
  zipcode: string,
  houseNumber: string,
  apiBaseUrl: string,
  apiKey: string
): Promise<LouwmanAddressResponse> => {
  if (!zipcode || !houseNumber) return

  return client
    .get<LouwmanAddressResponse>(
      `${apiBaseUrl}/1/postalcode/autocomplete?apikey=${apiKey}&postalcode=${zipcode}&streetnumber=${houseNumber}`,
      {
        withCredentials: false,
      }
    )
    .then((x) => x.data)
}
