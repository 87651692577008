export interface IOption {
  name: string
  value: string
  selected: boolean
  count: number
}

export interface IFacet {
  name: string
  options: IOption[]
}

export interface IId {
  carId: number
  ownerId: string
}

export interface IMeasurements {}

export interface ISpecifications {
  powerHp: number
  consumptionMixed: number
  energyLabel: string
  numberOfCilinders: number
  driveTrain: number
  driveTrainName: string
}

export interface ITyres {}

export interface IState {
  damages: any[]
  pros: any[]
  cons: any[]
  tyres: ITyres
}

export interface IItemsWithFeatured {
  name: string
  isfeatured: boolean
}

export interface IAccessory {
  name: string
  items: string[]
  itemsWithFeatured: IItemsWithFeatured[]
}

export interface IExtraFields {
  vehiclePurpose: string
}

export interface ILeasePrice {
  monthlyPrice: number
  monthlyPriceExact: number
  duration: number
  kmPerYear: number
  investmentPrice?: any
  accesoiresPrice?: any
  paymentPrice?: any
  leaseCompany: string
  leaseCheckout: string
}

export interface Extension {
  id: IId
  leasePrices: ILeasePrice[]
  labels: string[]
  has360: boolean
}

export interface ILowestPriceLeaseOption {
  monthlyPrice: number
  monthlyPriceExact: number
  duration: number
  kmPerYear: number
  investmentPrice?: any
  accesoiresPrice?: any
  paymentPrice?: any
  leaseCompany: string
  leaseCheckout: string
}

export interface IItem {
  id: IId
  jatoCode: string
  clientId: string
  vin: string
  licensePlate: string
  brand: string
  model: string
  type: string
  vehicleType: number
  vehicleTypeName: string
  doors: number
  bodyName: string
  fuelType: number
  fuelTypeName: string
  transmission: number
  transmissionName: string
  mileage: number
  year: number
  colorNumber: number
  colorDefaultName: string
  colorName: string
  colorInterior: string
  description: string
  imageUrls: string[]
  videoUrls: any[]
  price: number
  priceAllIn: number
  priceOnline: number
  isNew: boolean
  firstAdmission: Date
  measurements: IMeasurements
  specifications: ISpecifications
  state: IState
  accessories: IAccessory[]
  extraFields: IExtraFields
  url: string
  urlcheckout: string
  vehicleTitle: string
  isSold: boolean
  isReserved: boolean
  isDeleted?: any
  updatedDate: Date
  formattedPriceOnline: string
  formattedPriceLease: string
  metaTitle: string
  metaDescription: string
  isHidden: boolean
  extraInformation: any[]
  extensions: any[]
}

export type FacetName =
  | 'ClientId'
  | 'Brand'
  | 'Model'
  | 'Body'
  | 'Doors'
  | 'PriceOnline'
  | 'PriceLease'
  | 'Year'
  | 'Mileage'
  | 'FuelType'
  | 'Transmission'
  | 'Accessories.Items'
  | 'ColorNumber'
  | 'TaxDeductible'
  | 'ExtraFields.VehiclePurpose'
  | 'IsSpecialOffer'
  | 'Measurements.TowingBrakedWeight'
  | 'Specifications.PowerHp'
  | 'Seats'
  | 'Extension.Labels.Type'
  | 'FinanceMethod'

export const facetNames = [
  'ClientId',
  'Brand',
  'Model',
  'Body',
  'Doors',
  'PriceOnline',
  'PriceLease',
  'Year',
  'Mileage',
  'FuelType',
  'Transmission',
  'Accessories.Items',
  'ColorNumber',
  'TaxDeductible',
  'ExtraFields.VehiclePurpose',
  'IsSpecialOffer',
  'Measurements.TowingBrakedWeight',
  'Specifications.PowerHp',
  'Seats',
  'Extension.Labels.Type',
  'FinanceMethod',
]

export interface IOccasionResponse {
  facets: { [key in FacetName]: IFacet }
  items: IItem[]
  count: number
  url: string
  urlcanonical: string
  pagetitle: string
}

export const sortTypes = [
  'price-asc',
  'price-desc',
  'mileage-asc',
  'mileage-desc',
  'year-asc',
  'year-desc',
] as const

export type SortType = (typeof sortTypes)[number]

export type IFacetChoices = {
  [name in FacetName]?: IFacetState
}

export interface IFacetState {
  values?: string[]
  range?: string[]
}

export type OccasionBannerProps = {
  label?: string
  title: string
  text: string
  image_url: string
  promotion_page: string
}
