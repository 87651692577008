import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade'
import { breakpoints } from '~src/utils/breakpoints'
import hideOnClickOutside from '~src/utils/hideOnClickOutside'

import { ui } from '~src/utils/ui'

declare global {
  interface Window {
    prevScrollPos: number
  }
}

type Elements = {
  component: HTMLElement
  primaryNav: HTMLDivElement
  navContent: HTMLDivElement
  searchBox: HTMLDivElement
  togglePrimaryNavButton: HTMLButtonElement
  toggleSearchButton: HTMLButtonElement
  navButtons: HTMLButtonElement[]
  contentBackButtons: HTMLButtonElement[]
}

ui(
  {
    component: '.js-page-header',
    children: {
      primaryNav: '.js-primary-nav',
      navContent: '.js-nav-content',
      searchBox: '.js-search-box',
      togglePrimaryNavButton: '.js-nav-toggle',
      toggleSearchButton: '.js-search-toggle',
      navButtons: ['.js-nav-button'],
      contentBackButtons: ['.js-content-back-button'],
    },
  },
  ({
    component,
    primaryNav,
    navContent,
    searchBox,
    togglePrimaryNavButton,
    toggleSearchButton,
    navButtons,
    contentBackButtons,
  }: Elements) => {
    if (!component) return

    window.prevScrollPos = window.prevScrollPos

    // Open/close primary nav (on mobile)
    if (primaryNav) togglePrimaryNavButton.addEventListener('click', togglePrimaryNav)

    // Open/close search box
    if (searchBox) toggleSearchButton.addEventListener('click', handleSearchButton)

    // Handle click on primary nav buttons
    navButtons.forEach((button) => button.addEventListener('click', handleNavButton))

    // Handle click on content back buttons
    contentBackButtons.forEach((button) =>
      button.addEventListener('click', handleContentBackButton)
    )

    // Handle scroll actions
    window.addEventListener('scroll', handleScroll)

    // Keep track of window resizing
    window.prevScrollPos = window.scrollY
    window.addEventListener('resize', resizeCheckNavigation)

    // Handle escape button
    document.addEventListener('keyup', (e) => {
      if (e.keyCode == 27) {
        if (document.body.classList.contains('primary-nav-content-active')) {
          closeNavContent()
        }
      }
    })

    function togglePrimaryNav() {
      if (document.body.classList.contains('primary-nav-active')) {
        closeNavigation()
      } else {
        closeSearchBox()
        openNavigation()
        document.body.classList.add('primary-nav-active--mobile')
      }
    }
    // Close navigation
    function closeNavigation() {
      closeActiveItems()
      document.body.classList.remove(
        'primary-nav-active',
        'primary-nav-content-active',
        'primary-nav-active--mobile'
      )

      // Reset body position
      enableBodyScroll(navContent)
      navContent.dataset.animating = 'false'
    }

    // Open navigation (on mobile)
    function openNavigation() {
      // Close menu when clicked outside
      hideOnClickOutside('.page-header', closeNavContent)

      // If already open, no action required
      if (document.body.classList.contains('primary-nav-active')) return
      document.body.classList.add('primary-nav-active')

      // Prevent body from scrolling in the background
      disableBodyScroll(navContent, {
        allowTouchMove: (el: HTMLElement) => {
          while (el && el !== document.body) {
            if (el.getAttribute('body-scroll-lock-ignore') !== null) {
              return true
            }
            el = el.parentElement
          }
        },
      })
    }

    // Handle nav button
    function handleNavButton(e: Event) {
      const button = e.target as HTMLButtonElement
      const navItem = button.closest<HTMLElement>('.primary-nav__list-item')
      const alreadyActive = navItem.classList.contains('active')
      const contentAlreadyOpen = document.body.classList.contains('primary-nav-content-active')
      const originalHeight = navContent.clientHeight + 'px'

      // Prevent fast clicking animation errors
      if (navContent.dataset.animating == 'true') return

      navContent.dataset.animating = 'true'

      // Open content item
      document.body.classList.add('primary-nav-content-active')

      // If item is not active yet, open
      if (!alreadyActive) {
        // Close the search-box
        closeSearchBox()

        openNavigation()

        // Close all active menu items and content items
        closeActiveItems()

        // Set current item active
        navItem.classList.add('active')

        // Open content of current item
        const contentId = button.dataset.for
        const contentTarget = navContent.querySelector(`[data-id="${contentId}"]`)
        contentTarget.classList.add('active')

        // Slide content down
        // Set height to auto, so we can see how high the element should be
        navContent.style.height = 'auto'

        // Get height
        const height = navContent.clientHeight + 'px'

        // Reset height to 0, so we can animate
        navContent.style.height = contentAlreadyOpen ? originalHeight : '0px'

        // Set height
        setTimeout(() => {
          navContent.style.height = height
        }, 50)

        // When transition is done, reset height to auto, so the element can still scale
        navContent.addEventListener(
          'transitionend',
          () => {
            navContent.dataset.animating = 'false'
            navContent.style.height = 'auto'
            disableBodyScroll(navItem)
          },
          {
            once: true,
          }
        )
      } else {
        closeNavContent()
      }
    }

    function closeNavContent() {
      // Item is open already, first close the navigation content
      navContent.style.height = navContent.clientHeight + 'px'

      setTimeout(() => {
        navContent.style.height = '0px'

        // Wait for animation, then close navigation
        setTimeout(() => {
          closeNavigation()
        }, 400)
      }, 50)
    }

    // Close all active menu items and content items
    function closeActiveItems() {
      const activeContent = document.querySelectorAll(
        '.primary-nav__content-item.active, .primary-nav__list-item.active'
      )
      ;[].forEach.call(activeContent, (item: HTMLElement) => {
        item.classList.remove('active')
        enableBodyScroll(item)
      })
    }

    // Handle click on content back button (mobile)
    function handleContentBackButton() {
      closeActiveItems()
      document.body.classList.remove('primary-nav-content-active')
      navContent.style.height = 'auto'
      navContent.dataset.animating = 'false'
    }

    // When resizing the window, going from 'mobile' to 'desktop' breakpoint, make sure to close the menu
    // Otherwise (with the menu open) the body could still be fixed
    function resizeCheckNavigation() {
      if (
        window.innerWidth >= breakpoints.lg &&
        document.body.classList.contains('primary-nav-active--mobile')
      ) {
        closeNavigation()
      }
    }

    function handleScroll() {
      const headerHeight = component.offsetHeight
      const currentScrollPos = window.scrollY
      const searchboxAnimating = searchBox?.dataset.animating == 'true'

      if (window.prevScrollPos > currentScrollPos) {
        document.body.classList.remove('scrolled-down')
      } else if (currentScrollPos > headerHeight) {
        document.body.classList.add('scrolled-down')
        // Do not close the searchbox on mobile devices
        if (window.innerWidth >= breakpoints.lg && !searchboxAnimating) {
          closeSearchBox()
        }
      }

      window.prevScrollPos = currentScrollPos
    }

    function handleSearchButton() {
      const alreadyActive = searchBox.classList.contains('active')

      // Prevent fast clicking animation errors
      if (searchBox.dataset.animating == 'true') return

      searchBox.dataset.animating = 'true'

      if (!alreadyActive) {
        openSearchBox()
      } else {
        closeSearchBox()
      }
    }

    function openSearchBox() {
      if (!searchBox) return

      // Close navigation content before opening search-box
      closeNavigation()

      // Close search-box on click outside
      hideOnClickOutside('.page-header', closeSearchBox)

      // Get original height
      const originalHeight = searchBox.clientHeight + 'px'

      // Set search box as active
      searchBox.classList.add('active')

      // Set height to auto, so we can see how high the element should be
      searchBox.style.height = 'auto'

      // Get actual height
      const height = searchBox.clientHeight + 'px'

      // Reset height to 0, so we can animate
      searchBox.style.height = originalHeight

      // Set height
      setTimeout(() => {
        searchBox.style.height = height
      }, 50)

      // When transition is done, reset height to auto, so the element can still scale

      setTimeout(() => {
        searchBox.dataset.animating = 'false'
        searchBox.style.height = 'auto'
      }, 600)
    }

    function closeSearchBox() {
      if (!searchBox) return

      searchBox.dataset.animating = 'true'
      searchBox.style.height = searchBox.clientHeight + 'px'

      searchBox.classList.remove('active')

      setTimeout(() => {
        searchBox.style.height = '0px'
      }, 50)

      setTimeout(() => {
        searchBox.dataset.animating = 'false'
      }, 600)
    }
  }
)
