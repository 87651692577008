import $ from 'jquery'
import { ui } from '~src/utils/ui'

type Elements = {
  component: HTMLDivElement
  linkButton: HTMLAnchorElement
}

ui(
  {
    component: '.js-overlay-page',
    children: {
      linkButton: 'a',
    },
  },
  ({ component, linkButton }: Elements) => {
    const slug = component.dataset.slug
    const modalId = `modal-${slug}`
    const modal = document.querySelector(`#${modalId}`)
    const modalBody = modal?.querySelector('.modal-body')
    const pageUrl = linkButton.getAttribute('href')!

    linkButton.setAttribute('href', `#modal=${modalId}`)

    $(modal).on('show.bs.modal', async function () {
      if (modalBody.innerHTML.trim()) return

      const html = await fetch(pageUrl, {
        headers: { 'x-requested-with': 'XMLHttpRequest' },
      }).then((r) => r.text())
      modalBody.innerHTML = html
    })

    // This code is also in `modals.ts`, but seems to be necessary here as well.
    $(modal).on('hide.bs.modal', () => history.replaceState(null, null, ' '))
  }
)
