import React from 'react'
import { ILicensePlate } from '~src/store/trade-in/types'
import { formatLicense } from '~src/utils/format_license'

interface IProps {
  value: string
  disabled?: boolean
  onChange: (format: ILicensePlate) => void
  onBlur?: () => void
}

const LicensePlateInput: React.FunctionComponent<IProps> = ({
  value,
  disabled,
  onChange,
  onBlur,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target
    const format = formatLicense(input.value)
    onChange(format)
  }

  const format = formatLicense(value)

  return (
    <div className="trade-in-form__item">
      <label className="input-label" htmlFor="id_license_plate">
        Kenteken
      </label>
      <div className="trade-in-form__group trade-in-form__group--license-plate">
        <span className="trade-in-form__addon">NL</span>
        <input
          type="text"
          name="licensePlate"
          className="input-text"
          id="id_license_plate"
          maxLength={8}
          value={format.value}
          disabled={disabled}
          onChange={handleChange}
          onBlur={onBlur}
        />
      </div>
    </div>
  )
}

export default LicensePlateInput
