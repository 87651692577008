import React, { lazy, Suspense, useEffect, useState } from 'react'
import Modal from '~src/common/Modal'
import { useRootState } from '~src/hooks/store'
import type { Props as ConfiguratorProps } from './Configurator'
const Configurator = lazy(() => import('~src/apps/Configurator/Configurator'))

type Props = ConfiguratorProps & {
  text: string
  className: string
  plan: string
}

function ConfiguratorModalButton({ text, className, plan, ...props }: Props): JSX.Element {
  const { configuration, initialized } = useRootState()

  const [isOpen, setIsOpen] = useState(Boolean(configuration.model))

  const handleClick = (evt?: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    const url = `${window.location.pathname}?financeModal=true&plan=${plan}`
    window.history.replaceState(null, '', url)
    setIsOpen(true)
  }

  const afterClose = () => {
    window.history.replaceState(null, '', window.location.pathname)
    setIsOpen(false)
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search || '')

    if (configuration.car.edition && params.get('financeModal')) {
      setIsOpen(true)
    }
  }, [initialized])

  return (
    <>
      <button type="button" className={`button ${className}`} onClick={handleClick}>
        {text}
      </button>

      <Modal open={isOpen} onClose={afterClose} className="modal-lg-wide modal--extra-padding">
        <Suspense fallback={<span></span>}>
          <Configurator displayHeader={false} {...props} />
        </Suspense>
      </Modal>
    </>
  )
}

export default ConfiguratorModalButton
