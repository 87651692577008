import flatpickr from 'flatpickr'
import { handleFormSubmit } from '~src/dom_components/forms'
import { ui } from '~src/utils/ui'
import { nextArrow, prevArrow } from './date-pickers'

type Elements = {
  component: HTMLDivElement
  form: HTMLFormElement
  receiptValues: HTMLDivElement[]
  modelSelector: HTMLDivElement
  modelImage: HTMLImageElement
  changeDealerLink: HTMLAnchorElement
  datePickerInput: HTMLInputElement
}

interface IModelVersion {
  name: string
  code: string
  selected: boolean
}

export interface IModel {
  name: string
  code: string
  selected: boolean
  image_url: string
  editions: IModelVersion[]
  test_drive_from: string | null
  test_drive_to: string | null
}

ui(
  {
    component: '.js-testdrive-form',
    children: {
      form: '.js-testdrive-form',
      receiptValues: ['.js-testdrive-receipt-value'],
      modelSelector: '.js-model-selector',
      modelImage: '.js-model-image',
      changeDealerLink: '.js-change-dealer-link',
      datePickerInput: '.js-date-picker-dealer-test-drive',
    },
  },
  ({
    form,
    receiptValues,
    modelSelector,
    modelImage,
    changeDealerLink,
    datePickerInput,
  }: Elements) => {
    if (!form || !modelSelector) return

    const receiptFields = receiptValues.map((el) => el.dataset.value)
    const vehicleDataEl = document.querySelector('#js-vehicle-data')
    const vehicleData: IModel[] = vehicleDataEl && JSON.parse(vehicleDataEl.innerHTML)
    const initialModel = vehicleData.find((el) => el.selected)

    // Set initial value for model_codes field.
    const initialSelectedModelCode = initialModel?.code
    updateReceiptLineItem('model_codes', initialSelectedModelCode)

    // Set initial value for all other fields.
    const formValues = $(form).serializeArray()
    const updateFields = formValues.filter((el) => receiptFields.includes(el.name))
    updateFields.forEach(({ name, value }) => {
      if (value !== '') updateReceiptLineItem(name, value)
    })

    // Init datepicker
    const datePicker = flatpickr(datePickerInput, {
      inline: true,
      minDate: getMinDate(initialModel),
      maxDate: getMaxDate(initialModel),
      disable: [(date) => date.getDay() === 0],
      nextArrow,
      prevArrow,
    })

    // Fill model selector with vehicle data.
    fillModelSelector()

    // After model change, update model selector and datepicker.
    modelSelector.addEventListener('change', (event: Event) => {
      const target = event.target as HTMLSelectElement
      updateModelSelectorImage(target.value)
      updateDatePickerRange(target.value)
    })

    // After form changes, update receipt.
    form.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement
      updateReceiptLineItem(target.name, target.value)
    })

    form.addEventListener('submit', (event) =>
      handleFormSubmit(event, () => {
        const formOffset = form.getBoundingClientRect()
        const offset = formOffset.top + window.scrollY - 120
        window.scrollTo({ top: offset, behavior: 'smooth' })
        changeDealerLink.remove()
      })
    )

    function updateDatePickerRange(value: string) {
      const model = vehicleData.find((el) => el.code === value)
      const minDate = getMinDate(model)
      const maxDate = getMaxDate(model)
      const selectedDate = datePicker.selectedDates[0]

      // If the selected date falls out of range, clear it and update the receipt.
      if (selectedDate < minDate || selectedDate > maxDate) {
        datePicker.clear()
        updateReceiptLineItem('preferred_date', '')
      }

      datePicker.set('minDate', minDate)
      datePicker.set('maxDate', maxDate)
    }

    function updateReceiptLineItem(name: string, value: string) {
      const node = receiptValues.find((el) => el.dataset.value === name)
      if (node) {
        switch (name) {
          case 'model_codes':
            updateModel(node, value)
            break
          case 'preferred_date':
            updatePreferredDate(node, value)
            break
          case 'preferred_time':
            updatePreferredTime(node, value)
            break
        }
      }
    }

    function updateModel(node: HTMLDivElement, value: string) {
      const modelName = vehicleData.find((el) => el.code === value)?.name
      node.classList.toggle('text-muted', !Boolean(modelName))
      node.innerHTML = modelName ?? 'Niet gekozen'
    }

    function updatePreferredDate(node: HTMLDivElement, value: string) {
      node.classList.toggle('text-muted', !Boolean(value))

      if (value) {
        const date = new Date(value)
        node.innerHTML = date.toLocaleDateString('nl-NL', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
      } else {
        node.innerHTML = 'Niet gekozen'
      }
    }

    function updatePreferredTime(node: HTMLDivElement, value: string) {
      node.classList.remove('text-muted')
      node.innerHTML = value
    }

    function fillModelSelector() {
      const selectEl = modelSelector.querySelector('select')
      const selectedOption = vehicleData.find((el) => el.selected)?.code
      const defaultOption = `<option>- Maak je keuze -</option>`
      const options = vehicleData.map(
        (el) => `<option value="${el.code}" ${el.selected && 'selected'}>${el.name}</option>`
      )

      updateModelSelectorImage(selectedOption)
      selectEl.innerHTML = defaultOption + options.join('')
    }

    function updateModelSelectorImage(value: string) {
      const fallbackImage = modelSelector.dataset.fallbackImage
      // Get image from vehicle data and set it as the model image.
      const image = vehicleData.find((el) => el.code === value)?.image_url
      modelImage.src = image ?? fallbackImage
    }

    function getMinDate(model?: IModel): Date {
      const defaultMinDate = new Date(new Date().setDate(new Date().getDate() + 2))
      const modelMinDate = model?.test_drive_from && new Date(model.test_drive_from)

      if (!modelMinDate) {
        return defaultMinDate
      } else {
        // Ensure that the minDate is at least 2 days in the future.
        return modelMinDate < defaultMinDate ? defaultMinDate : modelMinDate
      }
    }

    function getMaxDate(model?: IModel) {
      const defaultMaxDate = new Date(new Date().setDate(new Date().getDate() + 167))
      const modelMaxDate = model?.test_drive_to && new Date(model.test_drive_to)

      return modelMaxDate ?? defaultMaxDate
    }
  }
)
