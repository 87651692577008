import React, { useEffect, useState } from 'react'
import { apiGet } from '~src/api/client'
import { IFinancialProduct } from '~src/api/types/configurator'

type Disclaimers = {
  disclaimers: {
    car: string
    motor: string
    marine: string
    occasion: string
  }
}

type Props = {
  product: IFinancialProduct
  apiRootUrl?: string
}

export const OccasionDisclaimer = ({ product, apiRootUrl }: Props) => {
  const [disclaimer, setDisclaimer] = useState<string | undefined>()

  useEffect(() => {
    apiGet<Disclaimers>(`${apiRootUrl ? apiRootUrl : '/'}site_settings/?format=json`).then(
      (result) => {
        setDisclaimer(result.data.disclaimers.occasion)
      },
      (error) => {
        console.error('Something went wrong in retrieving the disclaimer text: ', error)
      }
    )
  }, [apiRootUrl])

  return (
    <>
      {product?.disclaimer && <p className="my-4">{product.disclaimer}</p>}

      {disclaimer && (
        <p className="mb-4">
          <span className="font-weight-bold">Disclaimer:</span> {disclaimer}
        </p>
      )}
    </>
  )
}
