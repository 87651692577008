import React from 'react'
import { currencyFormatter } from '~src/utils/number-formatter'
import RemoveIcon from '~svg/remove.svg'

export const LineTitle = ({
  action,
  children,
}: {
  action?: React.ReactNode
  children: React.ReactNode
}): JSX.Element => {
  return (
    <div className="line-title">
      <div className="flex-fill d-flex align-items-center">{children}</div>
      {action && <div>{action}</div>}
    </div>
  )
}

export const Price = ({
  price,
  originalPrice,
  perMonth,
}: {
  price: number
  originalPrice?: number
  perMonth?: boolean
}): JSX.Element =>
  originalPrice ? (
    <div>
      <span className="text-muted" style={{ textDecoration: 'line-through' }}>
        {currencyFormatter.format(originalPrice)}
      </span>{' '}
      <span className="text-primary">{currencyFormatter.format(price)}</span>
      <span className="text-lowercase">{perMonth && ' p/m*'}</span>
    </div>
  ) : (
    <span>
      {currencyFormatter.format(price)}{' '}
      <span className="text-lowercase">{perMonth && ' p/m*'}</span>
    </span>
  )

export const LinePrice = ({
  price,
  originalPrice,
  total,
  perMonth,
  onRemove,
  children,
}: {
  price: number
  originalPrice?: number
  perMonth?: boolean
  total?: boolean
  children?: React.ReactNode
  onRemove?: () => void
}): JSX.Element => {
  return (
    <div className={`line-price ${total ? 'line-price--total' : ''}`}>
      <div className="d-flex  align-items-center">
        {onRemove && (
          <RemoveIcon
            height={12}
            width={12}
            onClick={onRemove}
            className="line-price__remove-button"
          />
        )}
        {children}
      </div>
      <div className="line-price__price">
        {price || price === 0 ? (
          <Price price={price} originalPrice={originalPrice} perMonth={perMonth} />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export const LineData = ({
  data,
  children,
}: {
  data: string
  children: React.ReactNode
}): JSX.Element => {
  return (
    <div className={`line-data`}>
      <div className="flex-fill">{children}</div>
      <div>{data}</div>
    </div>
  )
}
