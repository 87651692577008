import * as Sentry from '@sentry/browser'
import $ from 'jquery'
import { apiPost } from '~src/api/client'

export default function Forms(): void {
  $('.js-form').on('submit', handleFormSubmit)
}

export async function handleFormSubmit(event: Event, callback?: Function): Promise<void> {
  event.preventDefault()
  const form = event.target as HTMLFormElement
  const formError = form.querySelector('.js-form-error')
  const formValues = $(form).serializeArray()
  const apiUrl = form.dataset.apiUrl
  const redirectUrl = form.dataset.successRedirectUrl
  const dateField = form.querySelector('.js-date-field')

  if (!apiUrl) {
    throw new Error('Form does not have an API url set')
  }

  // Disable input fields and button, reset form error
  const inputElements = form
    .querySelector('.js-form-fields')
    .querySelectorAll<HTMLFormElement>('button, input, select')
  inputElements.forEach((input) => (input.disabled = true))
  formError.innerHTML = ''

  const dateOfBirth = dateField && combineDateFieldsToString(formValues)

  // Define name/value pairs that should be included in the submitted form data
  const additionalFields = {
    ...(dateOfBirth && {
      date_of_birth: dateOfBirth,
    }),
  }

  // Define name/value pairs that should be excluded from the submitted form data
  const excludedFields = ['dob_day', 'dob_month', 'dob_year']

  // Get form values
  const data: Record<string, string> = formValues.reduce((result, item) => {
    if (!excludedFields.includes(item.name)) {
      return { ...result, [item.name]: item.value }
    }

    return result
  }, additionalFields)

  // Submit data to API
  try {
    const response = await apiPost<{ success_message: string }>(apiUrl, data)
    if (response.data) {
      const formFields = form.querySelector('.js-form-fields')
      const successField = form.querySelector('.js-form-success')
      const successMessage = form.querySelector('.js-form-success-message')

      if (formFields && successField && successMessage) {
        // Show success message
        formFields.classList.add('d-none')
        successField.classList.remove('d-none')
        successMessage.innerHTML = response.data.success_message
      }

      if (callback) {
        // Fire callback
        callback(response.data.success_message)
      } else if (redirectUrl) {
        // Redirect to url
        window.location.href = redirectUrl
      } else if (!form.closest('.modal')) {
        // Scroll to top of modal
        const formOffset = form.getBoundingClientRect()
        const offset = formOffset.top + window.pageYOffset - 120
        window.scrollTo({ top: offset, behavior: 'smooth' })
      }
    }
  } catch (error) {
    // Re-enable form elements
    inputElements.forEach((input) => (input.disabled = false))

    // Check if field error messages received
    if (typeof error?.response?.data === 'object') {
      handleErrors(form, error.response.data)
    } else {
      // Show generic error if API completely fails
      if (formError) {
        formError.innerHTML =
          'Er gaat helaas iets fout bij het verzenden van het formulier, probeer het alsjeblieft nogmaals.'
      }

      // Throw error
      console.error({ error, response: error.response })
      throw new Error('Can not submit form')
    }
  }
}

function handleErrors(form: HTMLFormElement, errors: {}) {
  const formError = form.querySelector('.js-form-error')

  // Remove old errors
  const oldErrors = form.querySelectorAll<HTMLDivElement>('.invalid-feedback')
  oldErrors.forEach((el) => (el.innerHTML = ''))

  // Remove old invalid states
  const oldClasses = form.querySelectorAll('.is-invalid')
  oldClasses.forEach((el) => el.classList.remove('is-invalid'))

  // Apply new errors
  for (const [key, value] of Object.entries(errors)) {
    const customInputs = ['date_of_birth']
    const input = customInputs.includes(key)
      ? form.querySelector(`[data-input-name="${key}"]`)
      : form.querySelector(`[name="${key}"]`)
    const formGroup = input?.closest('.form-group')

    // Check if field exists
    if (!input) {
      console.error(`Can not apply error message to: ${key}`)
      Sentry.captureException(`Can not apply error message to: ${key}`)

      // Show error on screen
      if (formError) {
        formError.innerHTML = formError.innerHTML + `<p>Er gaat iets mis met '${key}': ${value}</p>`
      }

      continue
    }

    // Add form group invalid class
    if (formGroup) {
      formGroup.classList.add('is-invalid')
    } else {
      console.error(`Can not apply invalid class to formgroup of: ${key}`)
    }

    // Insert message into error field
    const errorField = formGroup?.querySelector('.invalid-feedback')
    if (errorField) errorField.innerHTML = `${value}`
  }

  // Scroll the modal/window to the first error
  const firstFormgroup = form.querySelector('.form-group.is-invalid')
  const elOffset = firstFormgroup?.getBoundingClientRect()

  if (!elOffset) {
    // Do nothing
  } else if (form.closest('.modal')) {
    // Calculate offset based on modal
    const offset = elOffset.top + form.closest('.modal')?.scrollTop - 20
    form.closest('.modal').scrollTo({ top: offset, behavior: 'smooth' })
  } else {
    // Calculate offset based on window
    const offset = elOffset.top + window.pageYOffset - 90
    window.scrollTo({ top: offset, behavior: 'smooth' })
  }
}

function combineDateFieldsToString(formValues: JQuery.NameValuePair[]) {
  // If date fields exist in form values, combine them into a string
  const day = formValues.find((item) => item.name === 'dob_day')?.value
  const month = formValues.find((item) => item.name === 'dob_month')?.value
  const year = formValues.find((item) => item.name === 'dob_year')?.value

  return day && month && year ? `${day}-${month}-${year}` : undefined
}
