import flatpickr from 'flatpickr'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'

export default function DatePickers() {
  // Set localisation
  flatpickr.localize(Dutch)

  // Input fields
  flatpickr('.js-date-picker', {
    altInput: true,
    altFormat: 'd-m-Y',
    disableMobile: true,
  })

  /*
   Specific parameters for testdrive
   inline: true, always open
   minDate: day after tomorrow
   maxDate: 167 days (five and a half months) from now
   disable: sundays not allowed
  */

  flatpickr('.js-date-picker-test-drive', {
    inline: true,
    minDate: new Date().setDate(new Date().getDate() + 2),
    maxDate: new Date().setDate(new Date().getDate() + 167),
    disable: [(date) => date.getDay() === 0],
    nextArrow,
    prevArrow,
  })

  /*
   Specific parameters for showroom appointment
   inline: true, always open
   minDate: tomorrow
   maxDate: 167 days (five and a half months) from now
  */

  flatpickr('.js-date-picker-showroom', {
    inline: true,
    minDate: new Date().setDate(new Date().getDate() + 1),
    maxDate: new Date().setDate(new Date().getDate() + 167),
    nextArrow,
    prevArrow,
    // When a date gets selected, we need to update the "voorkeurstijden" component
    onChange: function (selectedDates) {
      updateTimePicker(selectedDates[0].getDay())
    },
  })
}

function updateTimePicker(selectedDate: number) {
  const weekday = weekdays[selectedDate]

  const timePicker: HTMLDivElement = document.querySelector('.js-time-picker')
  const options: HTMLDivElement = timePicker.querySelector('.js-time-picker-options')
  const message: HTMLDivElement = timePicker.querySelector('.js-time-picker-message')

  const times = timePicker.getAttribute('data-showroom-hours')
  const availableTimes = JSON.parse(times)[weekday]

  // 1. If the message is shown, we should hide it.
  if (!message.classList.contains('d-none')) {
    message.classList.add('d-none')
  }

  // 2. Remove all previous time picker elements.
  options.innerHTML = ''

  if (availableTimes.length !== 0) {
    // 3. Add all new time picker elements
    for (const time of availableTimes) {
      const element = createTimePickerElement(time)
      options.appendChild(element)
    }
  } else {
    // 4. Show generic message if no dates available for this day
    message.innerHTML = 'Helaas, er zijn geen voorkeurstijden beschikbaar. Kies een andere dag uit.'
    message.classList.remove('d-none')
  }
}

function createTimePickerElement(time: string) {
  const sanitizedID = `showroom_appointment_time_${time.replace(':', '')}`

  const element = document.createElement('div')
  element.classList.add('form-check', 'form-check-inline', 'input-radio')

  const input = document.createElement('input')
  input.id = sanitizedID
  input.classList.add('input-radio__input')
  input.setAttribute('type', 'radio')
  input.setAttribute('name', 'preferred_time')
  input.setAttribute('value', time)

  const label = document.createElement('label')
  label.classList.add('input-radio__label')
  label.setAttribute('for', sanitizedID)
  label.innerHTML = time

  element.appendChild(input)
  element.appendChild(label)

  return element
}

const weekdays: WeekDay[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

type WeekDay = 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat'

export const prevArrow =
  '<svg width="12px" height="18px" viewBox="0 0 12 18" xmlns="http://www.w3.org/2000/svg" fill="#323232"><polygon points="0 9 9 18 12 15 6 9 12 3 9 5.68434189e-14"></polygon></svg>'

export const nextArrow =
  '<svg width="12px" height="18px" viewBox="0 0 12 18" xmlns="http://www.w3.org/2000/svg" fill="#323232"><polygon points="12 9 3 18 0 15 6 9 0 3 3 5.68434189e-14"></polygon></svg>'
