const colors = document.querySelectorAll('.motorcycle-colors-block__color')

;[].forEach.call(colors, (color: HTMLButtonElement) =>
  color.addEventListener('click', handleColorClick)
)

// When a user clicks an edition color, change the image and prices
function handleColorClick(event: Event) {
  event.preventDefault()
  const button = event.target as HTMLButtonElement
  const block = button.closest('.motorcycle-colors-block')
  const blockColors = block.querySelectorAll('.motorcycle-colors-block__color')
  const imageEl: HTMLImageElement = block.querySelector('.motorcycle-colors-block__image')
  const nameEl: HTMLDivElement = block.querySelector('.motorcycle-colors-block__current-color')

  // Get data and check
  const { img, name } = button.dataset
  if (!block || !img || !name) {
    throw new Error(`Can not update card, not all data is available`)
  }

  // Remove old active states
  ;[].forEach.call(blockColors, (color: HTMLButtonElement) => color.classList.remove('active'))

  // Set active state
  button.classList.add('active')

  // Set image and price and url
  imageEl.src = img
  nameEl.innerHTML = name
}
