import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRootState } from '~src/hooks/store'
import {
  setNewCustomer,
  setTradeInCar,
  setTradeInModalStatus,
  updateTradeInVersion,
} from '~src/store/trade-in/actions'
import { ILicensePlate, ITradeInCustomer } from '~src/store/trade-in/types'
import MileageInput from '../TradeIn/MileageInput'
import LicensePlateInput from '../TradeIn/LicensePlateInput'
import queryString from 'query-string'
import { apiGet, apiPostTradeIn } from '~src/api/client'
import ButtonArrow from '~svg/button-arrow.svg'

type Props = {
  licensePlateApiUrl: string
  prcodeApi?: string
}

export const CarTradeInModalForm = ({ licensePlateApiUrl, prcodeApi }: Props) => {
  const [licensePlate, setLicensePlate] = useState<ILicensePlate>(null)
  const [mileage, setMileage] = useState<number | ''>('')
  const [loading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const dispatch = useDispatch()
  const {
    tradeIn: { oldCar },
  } = useRootState()

  const query = queryString.parse(location.search)

  const fetchPrCode = async () => {
    try {
      if (!prcodeApi) return
      const response = await apiGet<ITradeInCustomer>(prcodeApi)

      if (response.data) {
        setLicensePlate({
          value: response.data.license_plate,
          original: response.data.license_plate,
          valid: true,
        })
        dispatch(setNewCustomer(response.data))
      }
    } catch (error) {
      console.error(error)
      setError('Er is iets misgegaan.')
    }
  }

  useEffect(() => {
    // If there is a query string with PR Code
    if (query.prcode) {
      // Do the API call to /api/prcode/auto/{PRCODE}
      fetchPrCode()
    }

    // If there is a TradeIn Car in the store, prefill the inputs
    if (oldCar) {
      setLicensePlate({ value: oldCar.license_plate, original: oldCar.license_plate, valid: true })
      setMileage(oldCar.mileage)
    }
  }, [oldCar])

  const handleBlur = async () => {
    if (!mileage || !licensePlate.valid) return

    setError('')

    try {
      setIsLoading(true)
      const response = await apiPostTradeIn(licensePlateApiUrl, licensePlate.value, Number(mileage))
      if (response.data) {
        dispatch(setTradeInCar(response.data))
      }
    } catch (error) {
      if (error.response.data.non_field_errors) {
        setError(error.response.data.non_field_errors[0])
      } else {
        setError('Dit kenteken wordt niet herkend.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeLicense = (format: ILicensePlate) => {
    setLicensePlate(format)
    setError('')
  }

  const handleSetMileage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMileage(parseInt(event.target.value))
    setError('')
  }

  const handleVersionSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(updateTradeInVersion(e.target.value))
  }

  return (
    <>
      <div className="trade-in-form trade-in-form--modal">
        <form className="trade-in-form__form">
          <LicensePlateInput
            value={licensePlate?.original ?? ''}
            disabled={loading}
            onChange={handleChangeLicense}
            onBlur={handleBlur}
          />

          <MileageInput
            value={mileage}
            disabled={loading}
            onChange={handleSetMileage}
            onBlur={handleBlur}
          />

          {oldCar && oldCar.alternative_versions.length > 1 && (
            <div className="trade-in-form__version">
              <label className="input-label" htmlFor="id_trade_in_version_edit">
                Controleer je uitvoering
              </label>
              <div className="input-select">
                <select
                  name="trade_in_version_edit"
                  id="id_trade_in_version_edit"
                  className="input-select__select"
                  onChange={handleVersionSelect}
                >
                  {oldCar.alternative_versions.map((version) => (
                    <option key={version.version} value={version.version}>
                      {version.version}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </form>
        {error && (
          <div className="trade-in-form__errors error">
            <p>{error}</p>
          </div>
        )}

        <button
          className="button  button--primary  button--small  trade-in-form__next-button"
          disabled={!oldCar || !licensePlate?.original || !mileage || loading}
          onClick={() => {
            if (oldCar.fuel_type === 'Diesel' || oldCar.mileage >= 200000) {
              dispatch(setTradeInModalStatus('no-trade-in-offer'))
            } else {
              dispatch(setTradeInModalStatus('choose-new-car'))
            }
          }}
        >
          Kies jouw Suzuki
          <ButtonArrow className="icon" />
        </button>
      </div>
    </>
  )
}
