import { apiGetAddress } from '~src/api/client'
import { sanitizeZipCode, validHouseNumber, validZipcode } from '~src/utils/address'
import { ui } from '~src/utils/ui'

type Elements = {
  component: HTMLDivElement
  zipcodeField: HTMLInputElement
  houseNumberField: HTMLInputElement
  houseNumberAdditionField: HTMLInputElement
  streetField: HTMLInputElement
  cityField: HTMLInputElement
}

ui(
  {
    component: '.js-address',
    children: {
      zipcodeField: 'input[name="zipcode"]',
      houseNumberField: 'input[name="house_number"]',
      houseNumberAdditionField: 'input[name="house_number_addition"]',
      streetField: 'input[name="street_name"]',
      cityField: 'input[name="city"]',
    },
  },
  ({
    component,
    zipcodeField,
    houseNumberField,
    houseNumberAdditionField,
    streetField,
    cityField,
  }: Elements) => {
    const { apiBaseUrl, apiKey } = component.dataset
    const listenerFields = [zipcodeField, houseNumberField, houseNumberAdditionField]

    if (!apiBaseUrl && !apiKey) return

    zipcodeField.addEventListener(
      'blur',
      () => (zipcodeField.value = sanitizeZipCode(zipcodeField.value))
    )

    listenerFields.forEach((field) =>
      field.addEventListener('input', () =>
        submitAddress(zipcodeField.value, houseNumberField.value)
      )
    )

    const submitAddress = async (zipcode: string, houseNumber: string) => {
      component.classList.remove('is-valid')

      if (validZipcode(zipcode) && validHouseNumber(houseNumber)) {
        const response = await apiGetAddress(zipcode, houseNumber, apiBaseUrl, apiKey)
        if (response.status === 'ok') {
          const { street, city } = response.results[0]
          streetField.value = street
          cityField.value = city

          component.classList.add('is-valid')
        }
      }
    }
  }
)
