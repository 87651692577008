import React from 'react'
import { useDispatch } from 'react-redux'
import { LineTitle } from '~src/common/Lines'
import { useRootState } from '~src/hooks/store'
import { removeNewCarWithTradeInQuote, setTradeInModalStatus } from '~src/store/trade-in/actions'
import { formatLicense } from '~src/utils/format_license'

export const CarTradeInReceipt = () => {
  const dispatch = useDispatch()
  const {
    tradeIn: { oldCar, newCar, status },
  } = useRootState()

  return (
    <div className="receipt">
      <header className="receipt__header">
        <h4 className="mb-0">Samenvatting</h4>
      </header>

      <div>
        <hr />
        <LineTitle
          action={
            status !== 'request-trade-in-offer' && (
              <button
                className="button--text"
                onClick={() => dispatch(setTradeInModalStatus('request-trade-in-offer'))}
              >
                Wijzigen
              </button>
            )
          }
        >
          Jouw huidige auto
        </LineTitle>
        <div className="line-price">
          <div className="d-flex  align-items-center">
            {oldCar.brand} {oldCar.model}
          </div>
        </div>

        <LineTitle>Uitvoering</LineTitle>
        <div className="line-price">
          <div className="d-flex  align-items-center">{oldCar.version}</div>
        </div>

        <LineTitle>Kenteken</LineTitle>
        <div className="line-price">
          <div className="d-flex  align-items-center">
            {formatLicense(oldCar.license_plate).value}
          </div>
        </div>

        <LineTitle>Kilometerstand</LineTitle>
        <div className="line-price">
          <div className="d-flex  align-items-center">{oldCar.mileage} KM</div>
        </div>
      </div>

      {newCar && (
        <div>
          <hr />
          <LineTitle
            action={
              status === 'get-trade-in-value' && (
                <button
                  className="button--text"
                  onClick={() => {
                    dispatch(removeNewCarWithTradeInQuote())
                    dispatch(setTradeInModalStatus('choose-new-car'))
                  }}
                >
                  Wijzigen
                </button>
              )
            }
          >
            Jouw nieuwe Suzuki
          </LineTitle>
          <div className="line-price">
            <div className="d-flex  align-items-center">{newCar.model}</div>
          </div>

          <LineTitle>Uitvoering</LineTitle>
          <div className="line-price">
            <div className="d-flex  align-items-center">{newCar.edition.name}</div>
          </div>
        </div>
      )}
    </div>
  )
}
