export default function DisclaimerForm() {
  const component: HTMLDivElement = document.querySelector('.js-disclaimer-form')
  const checkbox: HTMLInputElement = component?.querySelector('#accept_disclaimer')
  const button: HTMLButtonElement = component?.querySelector('.js-submit-button')

  if (!checkbox || !button) return

  button.disabled = true

  checkbox.addEventListener('change', (event: Event) => {
    const target = event.target as HTMLInputElement
    button.disabled = !Boolean(target.checked)
  })
}
