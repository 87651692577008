// Extra functions for glide

// Disable the arrows when the user can't slider further
// Hide the controls if they're not useful
export function ArrowDisabler(Glide, Components, Events) {
  return {
    mount() {
      // Only in effect when rewinding is disabled
      if (Glide.settings.rewind) {
        return
      }

      Glide.on(['mount.after', 'run', 'resize'], () => {
        // If there are less/equal slides as perview, hide the controls
        const controls = Glide.selector.querySelector('.glide-controls')
        if (Components.Sizes.length <= Glide.settings.perView) {
          if (controls) controls.classList.add('hidden')
        } else {
          if (controls) controls.classList.remove('hidden')
        }

        // Filter out arrows_control
        const controlsItems = Object.keys(Components.Controls.items).map(
          (i) => Components.Controls.items[i]
        )
        controlsItems.forEach(function (controlItem) {
          if (!controlItem.classList.contains('glide__arrows')) {
            return
          }

          // Set left arrow state
          const left = controlItem.querySelector('.glide__arrow--left')
          if (left) {
            if (Glide.index === 0) {
              left.setAttribute('disabled', '') // Disable on first slide
            } else {
              left.removeAttribute('disabled') // Enable on other slides
            }
          }

          // Set right arrow state
          const right = controlItem.querySelector('.glide__arrow--right')
          if (right) {
            if (Glide.index === Components.Sizes.length - Glide.settings.perView) {
              right.setAttribute('disabled', '') // Disable on last slide
            } else {
              right.removeAttribute('disabled') // Disable on other slides
            }
          }
        })
      })
    },
  }
}

export function FixBoundPeek(Glide, Components, Events) {
  return {
    /**
     * Fix peek 'after' with 'bound' option.
     *
     * @param  {Number} translate
     * @return {Number}
     */
    modify(translate) {
      var isBound = Components.Run.isBound
      // future method from 'master'
      if (typeof isBound !== 'function') {
        isBound = function () {
          return (
            Glide.isType('slider') && Glide.settings.focusAt !== 'center' && Glide.settings.bound
          )
        }
      }

      if (isBound() && Components.Run.isEnd()) {
        const peek = Components.Peek.value

        // If there all slides are shown at once, don't use peek
        if (peek && Components.Sizes.length <= Glide.settings.perView) {
          return translate
        }

        // Use afterLast to add a margin after last item
        if (peek && typeof peek === 'object' && peek.after && peek.afterLast) {
          return translate - (peek.after - peek.afterLast)
        } else if (peek && typeof peek === 'object' && peek.after) {
          return translate - peek.after
        }

        return translate - peek
      }

      return translate
    },
  }
}
