import React from 'react'
import InputCheckbox from '~src/common/InputCheckbox'
import InputText from '~src/common/InputText'
import Modal from '~src/common/Modal'
import { handleFormSubmit } from '~src/dom_components/forms'
import ButtonArrow from '~svg/button-arrow.svg'

type Props = {
  open: boolean
  onClose: () => void
  onOpened: () => void
  saveFilterStateApiUrl: string
}

const SaveFilterStateModal = ({
  open,
  onClose,
  onOpened,
  saveFilterStateApiUrl,
}: Props): JSX.Element => {
  return (
    <Modal open={open} onClose={onClose} onOpened={onOpened}>
      <h2 className="mb-3">Zoekopdracht mailen</h2>
      <form data-api-url={saveFilterStateApiUrl} onSubmit={(e) => handleFormSubmit(e.nativeEvent)}>
        <div className="js-form-fields">
          <p className="mb-4">
            Laat je e-mailadres achter en ontvang jouw zoekopdracht in je mailbox. Zo kun je altijd
            weer makkelijk terug naar de website om het nieuwste aanbod te bekijken.
          </p>
          <InputText
            type="email"
            label="E-mailadres"
            id="save_occasion_query_email"
            name="email_address"
          />
          <InputCheckbox id="save_occasion_query_commercial_use" name="commercial_use">
            Ja, Suzuki/Nimag mag mij op de hoogte houden van acties, aanbiedingen en
            service-berichten
          </InputCheckbox>
          <InputCheckbox id="save_occasion_customer_survey" name="customer_survey">
            Ja, Suzuki/Nimag mag mij benaderen voor klantonderzoek
          </InputCheckbox>
          <input type="hidden" name="search_task_url" value={window.location.href} />
          <button type="submit" className="button button--primary mt-3">
            Mail zoekopdracht
            <ButtonArrow className="icon" />
          </button>
        </div>
        <div className="form-error error js-form-error"></div>
        <div className="form__success-block d-none js-form-success">
          <div className="block text-block js-form-success-message"></div>
        </div>
      </form>
    </Modal>
  )
}

export default SaveFilterStateModal
