import React, { useId } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import TooltipIcon from '~svg/tooltip.svg'

const Tooltip = ({ text }: { text: string }) => {
  const id = useId()

  return (
    <>
      <a id={`tooltip-${id}`} className="tooltip-toggler">
        <TooltipIcon width={16} height={16} />
      </a>
      <div className="custom-react-tooltip">
        <ReactTooltip anchorSelect={`[id='tooltip-${id}']`} clickable className="react-tooltip">
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </ReactTooltip>
      </div>
    </>
  )
}

export default Tooltip
