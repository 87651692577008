import React, { ChangeEvent, useState } from 'react'
import { apiGet } from '~src/api/client'
import { IDealerInfo } from '~src/apps/DealerMap/types'
import InputText from '~src/common/InputText'
import { useDealers } from '~src/hooks/useDealers'
import IconSearch from '~svg/search.svg'

type Props = {
  dealersApiUrl: string
  apiRootUrl: string
  louwmanHubBrand: string
  className?: string
}

export const DealerSearch = ({
  apiRootUrl,
  dealersApiUrl,
  louwmanHubBrand,
  className = 'h5',
}: Props) => {
  const dealers = useDealers(dealersApiUrl)
  const [postcode, setPostcode] = useState('')
  const [dealerError, setDealerError] = useState(null)
  const [selectedDealer, setSelectedDealer] = useState('')

  const handlePostcodeOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handlePostcodeDealerSearch()
    }
  }

  const handlePostcodeDealerSearch = async () => {
    setDealerError(false)

    try {
      const response = await apiGet<IDealerInfo>(
        apiRootUrl + `dealers/by-postalcode/${louwmanHubBrand}/${postcode}/`
      )
      const foundDealer = dealers.find(
        (d: IDealerInfo) => d.dealer_number === response?.data?.dealer_number
      )

      if (foundDealer) {
        setSelectedDealer(foundDealer.dealer_number)
      } else {
        throw new Error('No match found')
      }
    } catch (error) {
      // No match found
      setDealerError(true)
    }
  }

  return (
    <>
      <h4 className={className}>Je Suzuki dealer</h4>

      <InputText
        label="Zoek op postcode"
        id="postalcode"
        name="postalcode"
        type="postalcode"
        maxLength={6}
        value={postcode}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setPostcode(e.target.value.replace(/\s/g, '').toUpperCase())
        }
        onKeyPress={handlePostcodeOnEnter}
        endAdornment={
          <button type="button" onClick={handlePostcodeDealerSearch}>
            <IconSearch />
          </button>
        }
      />

      <div className="form-group">
        <label className="input-label" htmlFor="dealer_number">
          Dealer*
        </label>
        <div className="input-select">
          <select
            className="input-select__select"
            name="dealer_number"
            id="dealer_number"
            value={selectedDealer}
            onChange={(event) => setSelectedDealer(event.target.value)}
          >
            <option disabled={true} value="">
              Selecteer een dealer
            </option>

            {Object.values(dealers).map(({ dealer_number, city, name }) => (
              <option key={dealer_number} value={dealer_number}>
                {city} - {name}
              </option>
            ))}
          </select>
        </div>

        {dealerError && (
          <div className="error my-3">
            Er kan geen dealer worden gevonden met die postcode, selecteer alsjeblieft een dealer
            uit de lijst.
          </div>
        )}
      </div>
    </>
  )
}
