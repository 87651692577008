import React from 'react'
import SelectInput from '~src/common/SelectInput'

function MinMaxSelect<T>({
  value: { min, max },
  options,
  getValue,
  getLabel,
  onChange,
  disabled = false,
}: {
  value: { min?: string; max?: string }
  options: { min: T[]; max: T[] }
  getValue: (i: T, isMin: boolean) => string
  getLabel: (i: T, isMin: boolean) => string
  onChange: ({ min, max }: { min: string; max: string }) => void
  disabled?: boolean
}) {
  return (
    <div>
      <div className="mb-3">
        <SelectInput
          value={min?.toString()}
          options={options.min}
          getLabel={(o) => getLabel(o, true)}
          getValue={(o) => getValue(o, true)}
          onChange={(m) => onChange({ min: m, max })}
          disabled={disabled}
          size="medium"
        />
      </div>
      <div className="mb-3">
        <SelectInput
          value={max?.toString()}
          options={options.max}
          getLabel={(o) => getLabel(o, false)}
          getValue={(o) => getValue(o, false)}
          onChange={(m) => onChange({ min, max: m })}
          disabled={disabled}
          size="medium"
        />
      </div>
    </div>
  )
}

export default MinMaxSelect
