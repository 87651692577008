import React, { CSSProperties } from 'react'
import { useDispatch } from 'react-redux'
import { useRootState } from '~src/hooks/store'
import { gotoPage } from '~src/store/navigation/actions'
import { StepName } from '~src/store/navigation/types'
import CheckIconWhite from '~svg/checkmark-white.svg'
import FinishFlagIcon from '~svg/finish-flag.svg'

type StepperItems = 'model-and-edition' | 'configure' | 'finance' | 'summary'
const steps: StepperItems[] = ['model-and-edition', 'configure', 'finance', 'summary']

const stepLabel = (step: StepperItems) => {
  switch (step) {
    case 'model-and-edition':
      return 'Model en uitvoering'
    case 'configure':
      return 'Samenstellen'
    case 'finance':
      return 'Geldzaken'
    case 'summary':
      return 'Samenvatting'
  }
}

const getActiveStep = (currentStep: StepName): number =>
  currentStep === 'model' || currentStep === 'edition' ? 0 : steps.indexOf(currentStep)

const Stepper = () => {
  const {
    navigation: { currentStep },
  } = useRootState()
  const dispatch = useDispatch()

  return (
    <div
      className="stepper"
      style={
        {
          '--step-count': steps.length,
        } as CSSProperties
      }
    >
      {steps.map((name, i) => (
        <Step
          key={name}
          label={stepLabel(name)}
          step={i}
          isLastStep={i === steps.length - 1}
          current={getActiveStep(currentStep)}
          onClickCompleted={() => {
            if (name === 'model-and-edition') {
              dispatch(gotoPage('edition'))
            } else {
              dispatch(gotoPage(name))
            }
          }}
        />
      ))}
    </div>
  )
}

const Step = ({
  step,
  current,
  label,
  isLastStep,
  onClickCompleted,
}: {
  step: number
  current: number
  label: string
  isLastStep: boolean
  onClickCompleted: () => void
}) => {
  const variant = current > step ? 'completed' : step > current ? 'todo' : 'current'

  return (
    <div
      className={`stepper__step stepper__step--${variant}`}
      onClick={variant === 'completed' ? onClickCompleted : undefined}
      style={
        { '--step-line-pos': `calc(${step} * (100% / (var(--step-count) - 1)))` } as CSSProperties
      }
      data-testid="stepper-step"
    >
      <div className="stepper__circle">
        {isLastStep ? (
          <FinishFlagIcon height={16} width={16} />
        ) : variant === 'completed' ? (
          <CheckIconWhite height={12} width={12} />
        ) : (
          step + 1
        )}
      </div>
      <div className="stepper__label">{label}</div>
    </div>
  )
}

export default Stepper
