import clsx from 'clsx'
import useEmblaCarousel from 'embla-carousel-react'
import React, { useCallback, useEffect, useState } from 'react'
import CarouselThumbs from '~src/common/Carousel/CarouselThumbs'
import ArrowSharp from '~svg/arrow-sharp.svg'

type Props = {
  loop?: boolean
  children: React.ReactElement[]
  className?: string
  thumbnails?: React.ReactElement[]
  showProgress?: boolean
}

const Carousel = ({ loop, thumbnails, className, children, showProgress }: Props): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [slidesInView, setSlidesInView] = useState(0)
  const [activeSlide, setActiveSlide] = useState(0)
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [emblaRef, emblaApi] = useEmblaCarousel({
    active: children.length > slidesInView,
    loop,
  })

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi])
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi])

  const onThumbClick = (index: number) => {
    if (!emblaApi) return
    emblaApi.scrollTo(index)
  }

  const onSelect = () => {
    if (!emblaApi) return
    setSelectedIndex(emblaApi.selectedScrollSnap())
    emblaApi.scrollTo(emblaApi.selectedScrollSnap())
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
  }

  useEffect(() => {
    if (!emblaApi) return
    setSlidesInView(emblaApi.slidesInView().length)
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return
    emblaApi.on('select', onSelect)
    onSelect()

    setActiveSlide(emblaApi.selectedScrollSnap())
  }, [emblaApi, onSelect])

  useEffect(() => {
    if (!emblaApi) return
    emblaApi.reInit({})
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [emblaApi, children])

  return (
    <div className={clsx('embla', className)}>
      <div className="position-relative">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container" data-testid="carousel-container">
            {children}
          </div>
        </div>

        {children.length > slidesInView && (
          <div className="embla__nav">
            <button
              className="embla__prev"
              onClick={scrollPrev}
              disabled={!prevBtnEnabled}
              data-testid="carousel-previous"
            >
              <ArrowSharp className="icon" />
            </button>
            <button
              className="embla__next"
              onClick={scrollNext}
              disabled={!nextBtnEnabled}
              data-testid="carousel-next"
            >
              <ArrowSharp className="icon" />
            </button>
          </div>
        )}
      </div>

      {thumbnails && thumbnails.length > slidesInView && (
        <CarouselThumbs selectedIndex={selectedIndex} onClick={(i) => onThumbClick(i)}>
          {thumbnails}
        </CarouselThumbs>
      )}

      {showProgress && (
        <div className="embla__progress" data-testid="carousel-progress">
          {activeSlide + 1}/{children.length}
        </div>
      )}
    </div>
  )
}

export default Carousel
