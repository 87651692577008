export function bodyScrollIntoView(element: HTMLElement) {
  return new Promise<void>((resolve) => {
    const OFFSET: number = window.innerWidth < 1200 ? 0 : 48

    const currentContainerScrollYPos: number = window.pageYOffset
    const elementScrollYPos: number =
      element.getBoundingClientRect().top + currentContainerScrollYPos
    const targetY: number = elementScrollYPos - OFFSET

    const scrollListener = (e: Event) => {
      if (window.pageYOffset === targetY) {
        e.currentTarget.removeEventListener('scroll', scrollListener)
        resolve()
      }
    }

    window.addEventListener('scroll', scrollListener)

    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: elementScrollYPos - OFFSET,
    })
  })
}
