import clsx from 'clsx'
import React from 'react'
import CreditWarningSvg from '~svg/credit-warning.svg'

const CreditWarning = ({ className }: { className?: string }) => (
  <div className={clsx('credit-warning', className)} title="Let op! Geld lenen kost geld">
    <CreditWarningSvg height={30} width={270} />
  </div>
)

export default CreditWarning
