import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '~src/store'
import { setInitialState } from '~src/store/initialization'
import {
  removeTradeInCar,
  setNewCar,
  setNewCarWithTradeInQuote,
  removeNewCarWithTradeInQuote,
  setNewCustomer,
  setSuccessMessage,
  setTradeInCar,
  setTradeInModalStatus,
  updateTradeInCar,
  updateTradeInVersion,
} from './actions'
import {
  ITradeInCar,
  ITradeInCustomer,
  ITradeInQuote,
  ITradeInState,
  ITradeInSuggestion,
  StatusName,
} from './types'
import { IModelProducts } from '~src/api/types/configurator'

export const initialState: ITradeInState = {
  status: null,
  oldCar: null,
  newCar: null,
  tradeInQuote: null,
  successMessage: null,
  customer: null,
}

const tradeInReducer = createReducer(initialState, {
  [setTradeInModalStatus.toString()]: (state, action: PayloadAction<StatusName>) => ({
    ...state,
    status: action.payload,
  }),

  [setTradeInCar.toString()]: (state, action: PayloadAction<ITradeInCar>) => {
    sessionStorage.setItem('trade_in', JSON.stringify(action.payload))

    return {
      ...state,
      oldCar: action.payload,
    }
  },

  [updateTradeInCar.toString()]: (state, action: PayloadAction<Partial<ITradeInCar>>) => {
    sessionStorage.setItem(
      'trade_in',
      JSON.stringify({
        ...state.oldCar,
        ...action.payload,
      })
    )

    return {
      ...state,
      oldCar: {
        ...state.oldCar,
        ...action.payload,
      },
    }
  },

  [removeTradeInCar.toString()]: (state) => {
    sessionStorage.removeItem('trade_in')

    return {
      ...state,
      oldCar: null,
    }
  },

  [updateTradeInVersion.toString()]: (state, action: PayloadAction<string>) => ({
    ...state,
    oldCar: {
      ...state.oldCar,
      version: action.payload,
    },
  }),

  [setNewCar.toString()]: (state, action: PayloadAction<ITradeInSuggestion>) => ({
    ...state,
    newCar: action.payload,
  }),

  [setNewCarWithTradeInQuote.toString()]: (
    state,
    action: PayloadAction<{ model: IModelProducts; tradeInQuote: ITradeInQuote }>
  ) => ({
    ...state,
    newCar: {
      image_url: action.payload.model.image_url,
      model: action.payload.model.name,
      code: action.payload.model.code,
      edition: {
        name: action.payload.model.baseline_variant.name,
        price: action.payload.model.baseline_variant.from_price,
        code: action.payload.model.baseline_variant.code,
      },
    },
    tradeInQuote: action.payload.tradeInQuote,
  }),

  [removeNewCarWithTradeInQuote.toString()]: (state) => {
    return {
      ...state,
      newCar: null,
      tradeInQuote: null,
    }
  },

  [setSuccessMessage.toString()]: (state, action: PayloadAction<string>) => ({
    ...state,
    successMessage: action.payload,
  }),

  [setNewCustomer.toString()]: (state, action: PayloadAction<ITradeInCustomer>) => ({
    ...state,
    customer: action.payload,
  }),
  [setInitialState.toString()]: (state, action: PayloadAction<RootState>) => ({
    ...state,
    oldCar: action.payload.tradeIn.oldCar,
  }),
})

export default tradeInReducer
