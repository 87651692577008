import React from 'react'

const RemoveCar = ({ onRemove }: { onRemove: () => void }) => {
  return (
    <>
      <h4>Auto verwijderen</h4>

      <p>Weet je zeker dat je de in te ruilen auto wilt verwijderen?</p>

      <div className="d-flex mt-4">
        <button className="button button--secondary mr-3" data-dismiss="modal">
          Annuleren
        </button>

        <button className="button button--primary" onClick={onRemove}>
          Verwijderen
        </button>
      </div>
    </>
  )
}

export default RemoveCar
