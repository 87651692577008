import React from 'react'
import { useDispatch } from 'react-redux'
import TradeInForm from '~src/apps/TradeIn/TradeInForm'
import TradeInModal from '~src/apps/TradeIn/TradeInModal'
import { setTradeInModalStatus } from '~src/store/trade-in/actions'
import { TradeInType } from '~src/store/trade-in/types'

type Props = {
  licensePlateApiUrl: string
  dealersApiUrl: string
  contactFormApiUrl: string
  quoteApiUrl: string
  quoteUsedApiUrl: string
  prcodeApi: string
  tradeInType: TradeInType
  apiRootUrl: string
  louwmanHubBrand?: string
}

const TradeInTool = ({
  licensePlateApiUrl,
  dealersApiUrl,
  contactFormApiUrl,
  quoteApiUrl,
  quoteUsedApiUrl,
  prcodeApi,
  tradeInType,
  apiRootUrl,
  louwmanHubBrand,
}: Props): JSX.Element => {
  const dispatch = useDispatch()

  function flowSwitch() {
    switch (tradeInType) {
      case 'regular':
        dispatch(setTradeInModalStatus('flow-1-request-trade-in-offer'))
        break
      case 'warranty':
        dispatch(setTradeInModalStatus('flow-2-request-trade-in-offer-with-model'))
        break
      default:
        dispatch(setTradeInModalStatus('flow-1-request-trade-in-offer'))
        break
    }
  }

  return (
    <>
      <TradeInForm
        licensePlateApiUrl={licensePlateApiUrl}
        onFormValid={flowSwitch}
        tradeInType={tradeInType}
        prcodeApi={prcodeApi}
      />
      <TradeInModal
        licensePlateApiUrl={licensePlateApiUrl}
        dealersApiUrl={dealersApiUrl}
        contactFormApiUrl={contactFormApiUrl}
        prcodeApi={prcodeApi}
        quoteApiUrl={tradeInType === 'regular' ? quoteUsedApiUrl : quoteApiUrl}
        apiRootUrl={apiRootUrl}
        louwmanHubBrand={louwmanHubBrand}
      />
    </>
  )
}

export default TradeInTool
