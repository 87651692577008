import clsx from 'clsx'
import React, { ReactNode } from 'react'

type Props = {
  shade?: 'light' | 'normal' | 'dark' | 'red'
  height?: number
  width?: number
  className?: string
  children?: ReactNode
}

const Skeleton = ({ shade = 'light', height, width, className, children }: Props): JSX.Element => (
  <div className={clsx('skeleton', `skeleton--${shade}`, className)} style={{ height, width }}>
    {children}
  </div>
)

export default Skeleton
