document.addEventListener('DOMContentLoaded', () => {
  const form: HTMLFormElement = document.querySelector('.js-variant-form')

  if (!form) return

  const changeVariantInputs: NodeListOf<HTMLSelectElement> = form.querySelectorAll('select')

  changeVariantInputs.forEach((select: HTMLSelectElement, index: number) => {
    select.addEventListener('change', () => {
      if (select.value !== '' && index < changeVariantInputs.length - 1) {
        const nextSelect: HTMLSelectElement = changeVariantInputs[index + 1]

        nextSelect.disabled = false
      } else {
        for (let i = index + 1; i < changeVariantInputs.length; i++) {
          const nextSelect: HTMLSelectElement = changeVariantInputs[i]

          nextSelect.disabled = true
          nextSelect.value = ''
        }
      }

      form.submit()
    })
  })

  // form.addEventListener('change', () => {
  //   if (form.reportValidity()) {
  //     form.submit()
  //   }
  // })

  // form.addEventListener(
  //   'invalid',
  //   (event) => {
  //     event.preventDefault()
  //   },
  //   true
  // )
})
