import { apiPost } from '~src/api/client'

const form: HTMLDivElement = document.querySelector('.js-shopping-cart-form')

if (form) {
  const amountInputEls: NodeListOf<HTMLSelectElement> = form.querySelectorAll(
    '.shopping-cart-block__list select'
  )
  const removeButtons: NodeListOf<HTMLButtonElement> = form.querySelectorAll('.js-remove-cart-item')

  ;[].forEach.call(amountInputEls, (selectEl: HTMLSelectElement) =>
    selectEl.addEventListener('change', handleSelectChange)
  )
  ;[].forEach.call(removeButtons, (button: HTMLButtonElement) =>
    button.addEventListener('click', handleRemoveButtonClick)
  )
}

// Handle changes on select elements
async function handleSelectChange(event: Event) {
  event.preventDefault()
  const apiUrlSet = form.dataset.apiUrlSet
  const target = event.target as HTMLSelectElement

  const data = {
    product: target.dataset.productSlug,
    amount: target.value,
  }

  postToApi(apiUrlSet, data)
}

// Handle click on remove button
async function handleRemoveButtonClick(event: Event) {
  event.preventDefault()
  const target = event.target as HTMLSelectElement
  const apiUrlRemove = form.dataset.apiUrlRemove

  // Get form values
  const data = {
    product: target.dataset.productSlug,
  }

  postToApi(apiUrlRemove, data)
}

async function postToApi(apiUrl: string, data: {}) {
  // Disable input fields and button, reset form error
  const formError = form.querySelector('.js-form-error')
  const inputElements = form.querySelectorAll<HTMLFormElement>('button, input, select')
  inputElements.forEach((input) => (input.disabled = true))
  formError.innerHTML = ''

  // Get form values

  // Submit data to API
  try {
    const response = await apiPost<{
      cart_count: number
    }>(apiUrl, data)

    if (typeof response.data.cart_count === 'number') {
      location.reload()
    } else {
      throw new Error('Something went wrong with updating the amount')
    }
  } catch (error) {
    // Show generic error if API fails
    if (formError) {
      formError.innerHTML =
        'Er gaat helaas iets fout bij het verzenden van het formulier, probeer het alsjeblieft nogmaals.'
    }

    // Re-enable form elements
    inputElements.forEach((input) => (input.disabled = false))

    // Throw error
    console.error(error.response)
    throw new Error('Something went wrong with updating the amount')
  }
}
