import { breakpoints } from '~src/utils/breakpoints'
import { fallbackImageCommerce } from '~src/utils/image'
import { ui } from '~src/utils/ui'

/**
 * Product overview page
 * Handles the form, collapsing of blocks etc.
 * There is a form for mobile (in a modal), and one for desktop.
 */

type Elements = {
  component: HTMLFormElement
  blockToggleEls: HTMLButtonElement[]
  checkboxFacets: HTMLInputElement[]
  filterButton: HTMLButtonElement
  imageEls: HTMLImageElement[]
  selectFacets: HTMLSelectElement[]
  showMoreButtons: HTMLButtonElement[]
  sortEls: HTMLSelectElement[]
  openModalEl: HTMLInputElement
  facetModal: HTMLDivElement
}

ui(
  {
    component: '.js-product-overview',
    children: {
      blockToggleEls: ['.js-block-toggle'],
      checkboxFacets: ['.js-checkbox input'],
      filterButton: '.js-modal-toggle',
      imageEls: ['.js-image'],
      selectFacets: ['.js-filter select'],
      showMoreButtons: ['.js-show-more'],
      sortEls: ['.js-sort select'],
      facetModal: '#product-facets-modal',
    },
  },
  ({
    blockToggleEls,
    checkboxFacets,
    filterButton,
    imageEls,
    selectFacets,
    showMoreButtons,
    sortEls,
    facetModal,
  }: Elements) => {
    const isMobile = window.innerWidth < breakpoints.lg

    // Open modal for mobile if filter was applied
    if (isMobile && window.location.search.indexOf('openmodal=true') > -1) {
      filterButton && filterButton.click()
    }

    selectFacets.forEach((select) => select.addEventListener('change', handleSelectChange))
    checkboxFacets.forEach((checkbox) => checkbox.addEventListener('change', handleCheckboxChange))
    blockToggleEls.forEach((toggle) => toggle.addEventListener('click', handleBlockCollapse))
    showMoreButtons.forEach((button) => button.addEventListener('click', handleShowMore))
    imageEls.forEach((image) => image.addEventListener('error', handleImageError))
    sortEls.forEach((sort) => sort.addEventListener('change', handleSorting))
    $(facetModal).on('hidden.bs.modal', handleCloseModal)

    // When a user closes the facet modal, remove the `openmodal` parameter from the url
    // So that the modal won't open on reload or when the user navigates back
    function handleCloseModal() {
      const searchParams = new URLSearchParams(window.location.search)
      const links = document.querySelectorAll<HTMLAnchorElement>('.js-category')
      if (searchParams.has('openmodal')) {
        searchParams.delete('openmodal')
        links.forEach((link) => (link.href = link.href.replace('openmodal=true', '')))
        history.replaceState(null, '', window.location.pathname + '?' + searchParams.toString())
      }
    }

    function handleSelectChange(event: Event) {
      const target = event.target as HTMLSelectElement
      const form = target.closest<HTMLFormElement>('.js-product-overview')
      const currentFilter = target.closest<HTMLDivElement>('.js-filter')
      const children = currentFilter ? Array.from(currentFilter.parentElement.children) : undefined

      if (currentFilter) {
        const index = children.indexOf(currentFilter)
        const nextFiltersFirstOption =
          currentFilter.parentElement.querySelectorAll<HTMLOptionElement>(
            `.js-filter:nth-child(${index + 1}) ~ .js-filter select option:first-child`
          )

        // Set all next filters to first option ('all')
        if (nextFiltersFirstOption) {
          nextFiltersFirstOption.forEach((option) => {
            option.selected = true
          })
        }
      }

      form.submit()
    }

    function handleCheckboxChange(event: Event) {
      const target = event.target as HTMLInputElement
      const form = target.closest<HTMLFormElement>('.js-product-overview')

      form.submit()
    }

    function handleBlockCollapse(event: Event) {
      const target = event.currentTarget as HTMLButtonElement
      const collapse = target.nextElementSibling

      $(collapse).on('show.bs.collapse', function (event) {
        if (this === event.target) target.classList.remove('collapsed')
      })
      $(collapse).on('hide.bs.collapse', function (event) {
        if (this === event.target) target.classList.add('collapsed')
      })

      $(collapse).collapse('toggle')
    }

    function handleShowMore(event: Event) {
      const target = event.currentTarget as HTMLButtonElement
      const collapse = target.previousElementSibling

      $(collapse).on('show.bs.collapse', function (event) {
        if (this === event.target) target.innerText = 'Toon minder'
      })
      $(collapse).on('hide.bs.collapse', function (event) {
        if (this === event.target) target.innerText = 'Toon meer'
      })

      $(collapse).collapse('toggle')
    }

    function handleImageError(event: Event) {
      const target = event.target as HTMLImageElement
      target.src = fallbackImageCommerce
    }

    function handleSorting(event: Event) {
      const target = event.target as HTMLSelectElement
      const form = target.form

      form.submit()
    }
  }
)
