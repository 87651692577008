import React from 'react'
import IconSuzuki from '~svg/suzuki-s.svg'
import IconCheckmark from '~svg/checkmark.svg'

export const DealerSearchLegend = () => (
  <div className="dealer-search__legend">
    <div className="dealer-legend-card">
      <div className="dealer-legend-card__logo dealer-legend-card__logo--red">
        <IconSuzuki />
      </div>
      <span className="dealer-legend-card__title">Sales en Service</span>
      <ul className="dealer-legend-card__usps">
        <li>
          <IconCheckmark className="icon" />
          <span>Nieuwe Suzuki's</span>
        </li>
        <li>
          <IconCheckmark className="icon" />
          <span>Occasions</span>
        </li>
        <li>
          <IconCheckmark className="icon" />
          <span>Onderhoud</span>
        </li>
      </ul>
    </div>
    <div className="dealer-legend-card">
      <div className="dealer-legend-card__logo dealer-legend-card__logo--blue">
        <IconSuzuki />
      </div>
      <span className="dealer-legend-card__title">Service</span>
      <ul className="dealer-legend-card__usps">
        <li>
          <IconCheckmark className="icon" />
          <span>Occasions</span>
        </li>
        <li>
          <IconCheckmark className="icon" />
          <span>Onderhoud</span>
        </li>
      </ul>
    </div>
  </div>
)
