/***
 * @name Google Map
 *
 * @description
 *
 */

import React, { useEffect, useRef, useState } from 'react'
import GoogleMapWrapper from '~src/apps/DealerMap/services/GoogleMapService'
import { IDealerInfo, IGoogleMap } from '~src/apps/DealerMap/types'
import ScriptLoader from '~src/utils/script-loader'

//
// == Global declaration for google API
declare global {
  interface Window {
    google: any
  }
}

//
const GoogleMap: React.FC<IGoogleMap> = ({
  activeDealer,
  addressKeyword,
  center,
  showInfo,
  enableSearch = false,
  geoCoords,
  googleApiKey,
  markers,
  productGroup,
  viewMode,
  setGoogleError,
  onItemSelect,
  onViewChanged,
}) => {
  //
  // == State & references
  const mapRef = useRef()
  const [map, setMap] = useState(null)

  //
  // Method: Creates new instance of Google Maps
  const setupMap = () => {
    const instance = new GoogleMapWrapper({
      $ref: mapRef.current,
      center,
      zoom: 15,
    })

    instance.onViewChanged = (items: IDealerInfo[]) => {
      onViewChanged(items)
    }

    instance.onItemSelect = (item: IDealerInfo) => {
      onItemSelect(item)
    }

    instance.setFilterOnUpdate(enableSearch)
    instance.addMarkers(markers, () => {
      setMap(instance)
    })
  }

  //
  //
  // Life-cycle hook: When mounted -> load Google Maps API
  useEffect(() => {
    if (!document.getElementById('google-maps')) {
      ScriptLoader.loadScript(
        `https://maps.google.com/maps/api/js?key=${googleApiKey}&libraries=places,geometry`,
        'google-maps'
      ).then(() => {
        setupMap()
      })
    } else {
      setupMap()
    }
  }, [])

  // Life-cycle hook: Initialization of state (map, markers and services are read)
  useEffect(() => {
    if (map) {
      map.setOnlyOrigin(center.lat, center.lng)

      if (activeDealer) {
        map.jumpTo(Number(activeDealer.location_lat), Number(activeDealer.location_long), 15)
      } else {
        setTimeout(() => map.showAllMarkers(), 500)
      }
    }
  }, [map])

  // Life-cycle hook: When external geolocation is used -> Zoom to position
  useEffect(() => {
    if (map) {
      if (geoCoords) {
        setGoogleError(false)
        map.setOrigin(geoCoords.latitude, geoCoords.longitude)
      } else {
        map.showAllMarkers()
      }
    }
  }, [geoCoords])

  // Life-cycle hook: When address keyword is submitted -> Do lookup and zoom to position
  useEffect(() => {
    if (map) {
      if (addressKeyword === '') {
        setGoogleError(false)
        map.setOnlyOrigin(center.lat, center.lng)

        setTimeout(() => {
          map.showAllMarkers()
        }, 100)
      } else {
        setGoogleError(false)
        map.search(addressKeyword, () => setGoogleError(true))
      }
    }
  }, [addressKeyword])

  // Life-cycle hook: When user clicks on an marker
  useEffect(() => {
    if (map) {
      if (activeDealer && showInfo) {
        if (productGroup === 'car') {
          map.jumpTo(Number(activeDealer.location_lat), Number(activeDealer.location_long), 15)
        }
        map.setMarkerState(activeDealer, showInfo)
      }
    }
  }, [activeDealer])

  // Life-cycle hook: showinfo toggles map zoom in/out
  useEffect(() => {
    if (map) {
      if (markers.length > 1 && activeDealer) {
        if (showInfo) {
          if (productGroup === 'car') {
            map.jumpTo(Number(activeDealer.location_lat), Number(activeDealer.location_long), 15)
          }
        } else {
          map.goBack()
        }

        map.setMarkerState(activeDealer, showInfo)
      }
    }
  }, [showInfo])

  // Markers have updated (filtered), update Maps markers
  useEffect(() => {
    if (map) {
      map.updateMarkers(markers)
    }
  }, [markers])

  //
  // Render
  return (
    <div
      className={`google-map ${
        viewMode === 'contact_detail' || (productGroup === 'car' && showInfo) ? '' : 'is-minimized'
      } ${showInfo && productGroup === 'car' ? 'show-page' : ''}`}
    >
      <div className="google-map__component" ref={mapRef} />
    </div>
  )
}

export default GoogleMap
