import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DealerSearch } from '~src/common/DealerSearch'
import InputRadio from '~src/common/InputRadio'
import InputText from '~src/common/InputText'
import { handleFormSubmit } from '~src/dom_components/forms'
import { prefillForm } from '~src/dom_components/prefill-forms'
import { useRootState } from '~src/hooks/store'
import { setSuccessMessage } from '~src/store/trade-in/actions'
import { numberFormatter } from '~src/utils/number-formatter'
import ButtonArrow from '~svg/button-arrow.svg'

type Props = {
  dealersApiUrl: string
  quoteApiUrl: string
  apiRootUrl: string
  louwmanHubBrand?: string
  prcodeApi?: string
}

const privacyStatementText =
  'Als je jouw voucher aanvraagt ga je akkoord met onze privacyverklaring. De door jouw verstrekte gegevens worden uitsluitend gebruikt voor de door je gevraagde informatie.'

export const CarTradeInContactForm = ({
  dealersApiUrl,
  quoteApiUrl,
  apiRootUrl,
  louwmanHubBrand,
  prcodeApi,
}: Props) => {
  const [success, setSuccess] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const dispatch = useDispatch()
  const {
    tradeIn: { oldCar, newCar, tradeInQuote },
  } = useRootState()

  const handleFormSuccess = (message: string) => {
    dispatch(setSuccessMessage(message))
    setSuccess(true)
  }

  useEffect(() => {
    prefillForm(formRef.current)
  }, [])

  return (
    <form
      className="car-trade-in-contact box"
      data-api-url={quoteApiUrl}
      data-prcode-api={prcodeApi}
      onSubmit={(e) => handleFormSubmit(e.nativeEvent, handleFormSuccess)}
      ref={formRef}
    >
      <div className="car-trade-in-contact__fields  js-form-fields">
        <div className="flow">
          <h3 className="h4 mb-0">Neem contact met mij op</h3>
          {success ? (
            <div className="success-message">
              <p>Bedankt voor je aanvraag, wij nemen binnen één werkdag contact met je op.</p>
            </div>
          ) : (
            <p>
              Vul hieronder je contactgegevens in en wij nemen binnen één werkdag contact met je op.
            </p>
          )}
        </div>

        {!success && (
          <>
            <div className="form-group">
              <label className="input-label">Aanhef*</label>

              <div>
                <InputRadio label="Dhr." id="trade_in_genderMale" name="gender" value="m" />
                <InputRadio label="Mevr." id="trade_in_genderFemale" name="gender" value="v" />
              </div>

              <div className="invalid-feedback text-left"></div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <InputText label="Voornaam*" id="trade_in_first_name" name="first_name" />
              </div>

              <div className="col-12 col-md-6">
                <InputText
                  label="Tussenvoegsels"
                  id="trade_in_last_name_prefix"
                  name="last_name_prefix"
                />
              </div>
            </div>

            <InputText label="Achternaam*" id="trade_in_last_name" name="last_name" />

            <InputText label="E-mailadres*" id="trade_in_email" name="email_address" type="email" />

            <InputText
              label="Telefoonnummer*"
              id="trade_in_phone_number"
              name="phone_number"
              type="tel"
            />

            <hr className="m-0" />

            <DealerSearch
              apiRootUrl={apiRootUrl}
              dealersApiUrl={dealersApiUrl}
              louwmanHubBrand={louwmanHubBrand}
              className="h4 mb-0"
            />

            <hr className="m-0" />

            <div className="form-group">
              <div className="form-check form-check-inline input-checkbox">
                <input type="hidden" name="privacy_statement" value="false" />
                <input
                  className="input-checkbox__input"
                  type="checkbox"
                  name="privacy_statement"
                  id="trade_in_privacy_statement"
                  value="true"
                />
                <label className="input-checkbox__label" htmlFor="trade_in_privacy_statement">
                  Ik ga akkoord met het{' '}
                  <a href="/privacy-verklaring/" target="_blank" rel="noopener noreferrer">
                    privacy statement
                  </a>{' '}
                  *
                </label>
              </div>
              <div className="invalid-feedback text-left"></div>
            </div>

            <input type="hidden" name="privacy_statement_text" value={privacyStatementText} />

            <div className="form-group">
              <div className="form-check form-check-inline input-checkbox">
                <input type="hidden" name="commercial_use" value="false" />
                <input
                  className="input-checkbox__input"
                  type="checkbox"
                  name="commercial_use"
                  id="trade_in_commercial_use"
                  value="true"
                />
                <label className="input-checkbox__label" htmlFor="trade_in_commercial_use">
                  Ja, Suzuki/Nimag mag mij op de hoogte houden van acties, aanbiedingen en
                  service-berichten
                </label>
              </div>
              <div className="invalid-feedback text-left"></div>
            </div>

            <div className="form-group">
              <div className="form-check form-check-inline input-checkbox">
                <input type="hidden" name="customer_survey" value="false" />
                <input
                  className="input-checkbox__input"
                  type="checkbox"
                  name="customer_survey"
                  id="trade_in_customer_survey"
                  value="true"
                />
                <label className="input-checkbox__label" htmlFor="trade_in_customer_survey">
                  Ja, Suzuki/Nimag mag mij benaderen voor klantonderzoek
                </label>
              </div>
              <div className="invalid-feedback text-left"></div>
            </div>

            {/* START: Extend form with data from the store */}
            {oldCar && (
              <>
                <input type="hidden" value={oldCar.license_plate} name="trade_in_license_plate" />
                <input type="hidden" value={oldCar.mileage} name="trade_in_mileage" />
                <input type="hidden" value={oldCar.brand} name="trade_in_brand" />
                <input type="hidden" value={oldCar.model} name="trade_in_model" />
                <input type="hidden" value={oldCar.version} name="trade_in_version" />
              </>
            )}

            {newCar.code && <input type="hidden" value={newCar.code} name="model_codes" />}

            {newCar.edition.code && (
              <input type="hidden" value={newCar.edition.code} name="model_edition_code" />
            )}

            {/* Calculated values */}
            <input
              type="hidden"
              value={numberFormatter.format(Number(tradeInQuote.baseline_variant_price))}
              name="baseline_variant_price"
            />
            <input
              type="hidden"
              value={numberFormatter.format(Number(tradeInQuote.trade_in_value))}
              name="trade_in_value"
            />
            <input
              type="hidden"
              value={numberFormatter.format(Number(tradeInQuote.total_trade_in_value))}
              name="total_trade_in_value"
            />
            <input
              type="hidden"
              value={numberFormatter.format(Number(tradeInQuote.new_car_calculated_price))}
              name="new_car_calculated_price"
            />

            <input
              type="hidden"
              value={numberFormatter.format(Number(tradeInQuote.extra_trade_in_value))}
              name="extra_trade_in_value"
            />
            <input
              type="hidden"
              value={numberFormatter.format(Number(tradeInQuote.extra_trade_in_value_fixed))}
              name="extra_trade_in_value_fixed"
            />
            <input
              type="hidden"
              value={tradeInQuote.extra_trade_in_value_fixed_campaign_name}
              name="extra_trade_in_value_fixed_campaign_name"
            />

            <div className="form-error error js-form-error"></div>

            <button className="button  button--small  button--primary" type="submit">
              Versturen
              <ButtonArrow className="icon" />
            </button>
          </>
        )}
      </div>
    </form>
  )
}
