import clsx from 'clsx'
import React from 'react'

function RadioPillInput<T = string | number | boolean>({
  name,
  value,
  options,
  onChange,
  children,
}: {
  name: string
  value: T
  options: { label: string; value: T }[]
  onChange: (v: T) => void
  children?: React.ReactNode
}): JSX.Element {
  const id = `${name}_${options[0]?.value ?? ''}`
  return (
    <div className="input-range">
      {children && (
        <div className="input-range-label-container">
          <label className="input-range-label" htmlFor={id}>
            {children}
          </label>
        </div>
      )}
      <div className="input-range-pills">
        {options.map((option) => (
          <label
            key={option.value?.toString() ?? ''}
            htmlFor={`${name}_${option.value}`}
            className={clsx(
              'button button--pill',
              value === option.value ? 'is-checked' : 'is-gray'
            )}
          >
            <input
              id={`${name}_${option.value}`}
              type="radio"
              onChange={() => onChange(option.value)}
              value={option.value?.toString() ?? ''}
              checked={value === option.value}
              name={name}
              style={{ width: '1px', position: 'absolute', opacity: 0 }}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  )
}

export default RadioPillInput
