import LicensePlate from 'license-plate'
import React, { useEffect, useState } from 'react'
import Drawer from '~src/apps/MotorInsurance/Drawer'
import { IMotorInsurance } from '~src/apps/MotorInsurance/types/IMotorInsurance'
import ButtonArrow from '~svg/button-arrow.svg'

const validateLicensePlate = (licensePlate: LicensePlate) => {
  if (licensePlate.pretty().trim() === '') {
    return 'Dit veld is verplicht'
  } else if (!licensePlate.valid()) {
    return 'Ongeldig kenteken'
  } else {
    return ''
  }
}

const MotorInsurance = ({ url }: IMotorInsurance) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [licensePlateInput, setLicensePlateInput] = useState<string>('')
  const [error, setError] = useState<string>()

  const licensePlate = new LicensePlate(licensePlateInput)

  useEffect(() => {
    document.body.style.overflowY = drawerOpen ? 'hidden' : 'scroll'
  }, [drawerOpen])

  const formatAndValidate = () => {
    setError(validateLicensePlate(licensePlate))
    setLicensePlateInput(licensePlate.pretty())
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLicensePlateInput(event.target.value)
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()
    formatAndValidate()
    if (!error && licensePlate.pretty() !== '') setDrawerOpen(true)
  }

  return (
    <>
      <div className="motor-insurance border mx-auto pb-5 pt-4 px-3 py-md-5 px-md-3">
        <h1 className="h2 text-center font-weight-light">Bereken je premie</h1>
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center flex-column flex-md-row">
            <div className="license-plate mr-md-4">
              <label
                className="d-block font-weight-bold mb-2 license-plate__input-group__label"
                htmlFor="licenseplate"
              >
                VUL JE KENTEKEN IN
              </label>
              <div
                className={`license-plate__input-group d-flex justify-content-start${
                  error && ' validation-error'
                }`}
              >
                <div className="license-plate__input-group__prepend d-flex justify-content-center align-items-center">
                  NL
                </div>
                <input
                  type="text"
                  maxLength={8}
                  className="license-plate__input-group__input px-3"
                  name="licenseplate"
                  value={licensePlateInput}
                  onChange={handleChange}
                  onBlur={formatAndValidate}
                ></input>
              </div>
              {error && <p className="text-small error text-right mt-1">{error}</p>}
            </div>
            <div className="submit-button">
              <label className="d-block font-weight-bold mb-2 license-plate__input-group__label">
                &nbsp;
              </label>
              <button className="button button--primary" type="submit">
                BEREKEN PREMIE
                <ButtonArrow className="icon" />
              </button>
            </div>
          </div>
        </form>
      </div>
      <Drawer drawerOpen={drawerOpen} handleClose={() => setDrawerOpen(false)}>
        {licensePlateInput && (
          <iframe
            className="insurance-iframe"
            src={`${url}?kenteken=${licensePlateInput}`}
            title="Motor insurance check"
          /> //TODO: Url must be configured for suzuki
        )}
      </Drawer>
    </>
  )
}

export default MotorInsurance
