import { IBasicConfiguration } from './types'

export const defaultBasicConfiguration: IBasicConfiguration = {
  durationMonths: 60,
  downPayment: 0,
  finalPayment: 0,
  yearlyKilometers: 10000,
  claimFreeYears: 12,
  taxScale: 36.97,
}
