import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import ArrowCaretIcon from '~svg/arrow-caret-fat.svg'
import MinusIcon from '~svg/dash.svg'
import PlusIcon from '~svg/plus.svg'

/**
 * Accordion can be either controlled (external state) or uncontrolled (internal state).
 * Use the `isOpen` and `onChange` props to control the state.
 */

const Accordion = ({
  title,
  children,
  defaultOpen = false,
  alwaysOpen = false,
  isOpen: isExternalOpen,
  onChange,
  variant = 'primary',
}: {
  title: string
  defaultOpen?: boolean
  alwaysOpen?: boolean
  isOpen?: boolean
  onChange?: (key: string) => void
  children: React.ReactNode
  variant?: 'primary' | 'secondary'
}): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const [isInternalOpen, setInternalOpen] = useState(defaultOpen || alwaysOpen)
  const isOpen = isExternalOpen === undefined ? isInternalOpen : isExternalOpen

  useEffect(() => {
    if (ref?.current?.style) {
      const originalHeight = ref.current.clientHeight

      if (isOpen) {
        // Set height to auto, so we can see how high the element should be
        ref.current.style.height = 'auto'

        // Get height
        const expandedHeight = ref.current.clientHeight

        // Set height to original, so we can animate
        ref.current.style.height = originalHeight + 'px'

        // Set expand height
        setTimeout(() => {
          if (ref?.current) ref.current.style.height = expandedHeight + 'px'

          setTimeout(() => {
            if (ref?.current?.style) {
              ref.current.style.height = 'auto'
            }
          }, 300)
        }, 50)
      } else {
        // Set current height
        ref.current.style.height = originalHeight + 'px'

        setTimeout(() => {
          if (ref.current) ref.current.style.height = '0px'
        }, 50)
      }
    }
  }, [ref?.current, isOpen])

  const handleChange = () => {
    if (isExternalOpen === undefined) setInternalOpen(!isInternalOpen)
    if (onChange) onChange(title)
  }

  return (
    <div className={clsx('accordion', `accordion--${variant}`)}>
      {!alwaysOpen ? (
        <button className="accordion__header" onClick={handleChange}>
          <div className="flex-fill">{title}</div>
          <div>
            {variant === 'primary' ? (
              <ArrowCaretIcon
                height={15}
                width={15}
                style={{
                  transition: 'transform 0.3s ease',
                  transform: `rotate(${isOpen ? 270 : 90}deg)`,
                }}
              />
            ) : isOpen ? (
              <MinusIcon height={15} width={15} />
            ) : (
              <PlusIcon height={15} width={15} />
            )}
          </div>
        </button>
      ) : (
        <div className="accordion__header">
          <div className="flex-fill">{title}</div>
        </div>
      )}
      <div
        className="accordion__body"
        ref={ref}
        style={{
          maxHeight: 'none',
          transition: `height 0.2s ease`,
          visibility: isOpen ? 'visible' : 'hidden',
        }}
      >
        <div>{children}</div>
      </div>
    </div>
  )
}

export default Accordion
