import { createAction } from '@reduxjs/toolkit'
import { IAccessory, IColor, IEdition, IModelProducts, IPackage } from '~src/api/types/configurator'
import { IBasicConfiguration, ITradeInConfiguration } from './types'

export const updateBasicConfiguration = createAction<Partial<IBasicConfiguration>>(
  'configuration/updateBasic'
)
export const updateTradeIn = createAction<Partial<ITradeInConfiguration>>('configuration/tradeIn')
export const setModel = createAction<IModelProducts>('configuration/model')
export const setEdition = createAction<IEdition>('configuration/edition')
export const setColor = createAction<IColor>('configuration/color')
export const addAccessory = createAction<IAccessory>('configuration/add-accessory')
export const removeAccessory = createAction<IAccessory>('configuration/remove-accessory')
export const addPackage = createAction<IPackage>('configuration/add-package')
export const removePackage = createAction<IPackage>('configuration/remove-package')
export const addUpsell = createAction<string>('configuration/add-upsell')
export const removeUpsell = createAction<string>('configuration/remove-upsell')
