/**
 * @example
 * ```ts
 * numberFormatter.format(10000) // 10.000
 * ```
 *
 */
export const numberFormatter = new Intl.NumberFormat('nl-NL', { style: 'decimal' })

type Rounding = 'no-digits' | 'round'
class NumberFormat extends Intl.NumberFormat {
  format(value: number, rounding?: Rounding) {
    const result = super.format(rounding ? Math.round(value) : value)
    const zeroPattern = /,00$/
    return zeroPattern.test(result)
      ? result.replace(zeroPattern, rounding === 'no-digits' ? '' : ',-')
      : result
  }
}

/**
 * A Formatter for €-values following Dutch notation.
 * This formatter can also round numbers
 *
 * @example
 * ```ts
 * currencyFormatter.format(10) // € 10,-
 * currencyFormatter.format(10.5) // € 10,50
 * currencyFormatter.format(11, 'round') // € 10,-
 * currencyFormatter.format(10.5, 'no-digits') // € 10
 * ```
 */
export const currencyFormatter = new NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
})
