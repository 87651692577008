import { Middleware, PayloadAction } from '@reduxjs/toolkit'
import { gotoPage } from './navigation/actions'
import { setEdition, setModel } from './configuration/actions'
import { RootState } from './index'

// Add redux actions to this list if you want the next/previous browser buttons to be affected.
const newHistoryActions = [gotoPage.toString(), setModel.toString(), setEdition.toString()]

/**
 * Updates the url upon redux actions and saves (a part of) the store in the query parameters.
 */
export const gtmMiddleware: Middleware<{}, RootState> = (store) => (next) => (
  action: PayloadAction<any>
) => {
  const result = next(action)

  if (newHistoryActions.includes(action.type)) {
    ;(window as any).dataLayer?.push({
      event: 'gtm-pageview',
      pageurl: `/configurator/${store.getState().navigation.currentStep}/`,
    })
  }

  return result
}
