// Prefill form with data from API
import { apiGet } from '~src/api/client'

// Apply prefill to forms with prcode api
const forms: NodeListOf<HTMLElement> = document.querySelectorAll('[data-prcode-api]')
;[].forEach.call(forms, prefillForm)

export async function prefillForm(form: HTMLElement) {
  const apiUrl = form.dataset.prcodeApi

  if (!apiUrl) {
    return
  }

  try {
    const response = await apiGet<{ [key: string]: string }>(apiUrl)

    Object.keys(response.data).forEach((i: string) => {
      const elements: Element[] = [...Array.from(document.querySelectorAll(`[name="${i}"`))]
      const value = response.data[i]

      if (!value) {
        return
      }

      // Check multiple input types
      elements.forEach((el: HTMLInputElement) => {
        if (el.type === 'radio') {
          if (el.value.toLocaleLowerCase() === value.toLocaleLowerCase()) {
            el.checked = true
          }
        } else if (el.type === 'checkbox') {
          el.checked = true
        } else if (el.nodeName === 'SELECT') {
          const option: HTMLOptionElement = el.querySelector(`[value="${value}"]`)
          if (option) {
            option.selected = true
          }
        } else {
          // Text input (text, tel, e-mail etc) and textarea
          el.value = value
        }
      })
    })
  } catch (error) {
    console.error('An error occured trying to apply fill a form with prcode data')
  }
}
