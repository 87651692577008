import { useEffect, useState } from 'react'
import { apiGet } from '~src/api/client'
import { IDealerInfo } from '~src/apps/DealerMap/types'

export const useDealers = (url: string) => {
  const [data, setData] = useState([])

  useEffect(() => {
    apiGet<IDealerInfo[]>(url)
      .then((x) => {
        const filtered = x.data
          // Only use active dealers
          .filter((dealer) => dealer.is_active)
          // Only use sales dealers
          .filter((dealer) => dealer.is_sales_dealer)
          .sort((a, b) => a.city.localeCompare(b.city))
        setData(filtered)
      })
      .catch((e) => console.log(e))
  }, [])

  return data
}
