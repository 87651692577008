/***
 * @name Dealer list item
 *
 */

import clsx from 'clsx'
import React from 'react'
import { IDealerInfo, IDealerSelectable } from '~src/apps/DealerMap/types'
import IconCaret from '~svg/arrow-caret-fat.svg'
import ButtonArrow from '~svg/button-arrow.svg'
import IconCheckmark from '~svg/checkmark.svg'
import IconMarkerBlue from '~svg/marker-s-blue.svg'
import IconMarkerRed from '~svg/marker-s-red.svg'
import IconPhone from '~svg/phone.svg'
import IconWhatsApp from '~svg/whatsapp.svg'

//
// == Global declaration for window location
declare global {
  interface Window {
    location: Location
  }
}

const linkClickHandler = (e: any) => {
  e.stopPropagation()
}

const DealerItem: React.FC<IDealerInfo & IDealerSelectable> = ({
  configuratorQueryString,
  detail_page_url,
  id,
  dealer_number,
  distance,
  phone_number,
  display_phone_number,
  name,
  showDistances,
  whatsapp_url,
  whatsapp_readable,
  street_name,
  house_number,
  house_number_suffix,
  zipcode,
  city,
  productGroup,
  is_after_sales_dealer,
  is_sales_dealer,
  is_exclusive_dealer,
  motor_type_atv,
  motor_type_cross,
  motor_type_road,
  viewMode,
  formType,
  brochure_request_page_url,
  showroom_appointment_page_url,
  quote_request_page_url,
  service_appointment_page_url,
  testdrive_page_url,
  order_page_url,
  onDealerSelect,
}) => {
  // Redirect URLs
  const redirectURLs: any = {
    werkplaatsafspraak: service_appointment_page_url,
    brochureaanvraag: brochure_request_page_url,
    offerteaanvraag: quote_request_page_url,
    proefritaanvraag: testdrive_page_url,
    bestelling: order_page_url,
    showroomafspraak: showroom_appointment_page_url,
  }

  const queryStringValues = redirectURLs[formType]?.includes('?')
    ? '&' + location.search.substring(1)
    : location.search

  const queryString = configuratorQueryString || queryStringValues

  const redirectURL =
    viewMode === 'form_cta' && redirectURLs[formType] ? redirectURLs[formType] + queryString : null

  const hasDetailLink = productGroup === 'car' && !redirectURL

  const selectDealerHandler = () => {
    if (productGroup != 'car' && detail_page_url) {
      window.location.href = detail_page_url
    } else {
      onDealerSelect(dealer_number)
    }
  }

  const showDetail = () => {
    if (hasDetailLink) {
      onDealerSelect(dealer_number)
    }
  }

  return (
    <div className="dealer-item" data-dealer-id={id}>
      <div className="dealer-item__body">
        <h5
          className={clsx('dealer-item__name text-medium', hasDetailLink && 'link')}
          onClick={showDetail}
        >
          <div className="dealer-item__marker">
            {!is_sales_dealer ? <IconMarkerBlue /> : <IconMarkerRed />}
          </div>
          {name}

          {hasDetailLink && <IconCaret className="icon" />}
        </h5>

        {productGroup === 'motors' && (
          <ul className="dealer-item__facilities">
            {is_exclusive_dealer && (
              <li className="dealer-item__facility">
                <strong>Exclusief Suzuki dealer:</strong>
              </li>
            )}
            {motor_type_road && (
              <li className="dealer-item__facility">
                <IconCheckmark className="icon" />
                Wegmotoren
              </li>
            )}
            {motor_type_atv && (
              <li className="dealer-item__facility">
                <IconCheckmark className="icon" />
                ATV
              </li>
            )}
            {motor_type_cross && (
              <li className="dealer-item__facility">
                <IconCheckmark className="icon" />
                Motorcross
              </li>
            )}
          </ul>
        )}

        <div className="dealer-item__address text-small">
          <div>
            {street_name} {house_number} {house_number_suffix}
          </div>

          <div>
            {zipcode} {city}
          </div>
        </div>

        <div>
          {display_phone_number && (
            <a
              href={`tel:${phone_number}`}
              className="dealer-item__phone-link"
              onClick={linkClickHandler}
            >
              <IconPhone className="icon" />
              <span>{display_phone_number}</span>
            </a>
          )}
          {whatsapp_url && (
            <a
              href={whatsapp_url}
              className="dealer-item__whatsapp-link"
              onClick={linkClickHandler}
            >
              <IconWhatsApp className="icon" />
              <span>{whatsapp_readable || whatsapp_url}</span>
            </a>
          )}
        </div>

        {showDistances && distance !== undefined && !isNaN(distance) && (
          <div className="dealer-item__distance">{distance} km</div>
        )}

        {redirectURL && (
          <div className="dealer-item__actions">
            <a className="button button--primary" href={redirectURL}>
              Selecteer dealer
              <ButtonArrow className="icon" />
            </a>
          </div>
        )}

        {viewMode !== 'form_cta' && productGroup !== 'car' && (
          <div className="dealer-item__actions">
            <a className="button button--primary" onClick={selectDealerHandler}>
              Meer info
              <ButtonArrow className="icon" />
            </a>

            <a
              className="button button--secondary"
              href={`https://www.google.nl/maps/dir//${street_name}+${house_number}${
                house_number_suffix ? '+' + house_number_suffix : ''
              },+${zipcode?.replace(/\s/g, '')}+${city}/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Route
              <ButtonArrow className="icon" />
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default DealerItem
