import { Middleware, PayloadAction } from '@reduxjs/toolkit'
import { removeTradeInCar, setTradeInCar, updateTradeInCar } from '~src/store/trade-in/actions'
import {
  addAccessory,
  addUpsell,
  removeAccessory,
  removeUpsell,
  setColor,
  setEdition,
  setModel,
  updateBasicConfiguration,
} from './configuration/actions'
import { RootState } from './index'
import { gotoPage, setFinancialPlan } from './navigation/actions'
import { serializeState } from './url-serializers'

// Add redux actions to this list if you want the url to be updated upon that action.
const urlActions = [
  addAccessory.toString(),
  addUpsell.toString(),
  gotoPage.toString(),
  removeAccessory.toString(),
  removeTradeInCar.toString(),
  removeUpsell.toString(),
  setColor.toString(),
  setEdition.toString(),
  setFinancialPlan.toString(),
  setModel.toString(),
  setTradeInCar.toString(),
  updateTradeInCar.toString(),
  updateBasicConfiguration.toString(),
]

// Add redux actions to this list if you want the next/previous browser buttons to be affected.
const newHistoryActions = [gotoPage.toString()]

/**
 * Updates the url upon redux actions and saves (a part of) the store in the query parameters.
 */
export const urlMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action: PayloadAction<any>) => {
    const result = next(action)

    if (urlActions.includes(action.type)) {
      const queryParams = serializeState(store.getState())
      const url = `${window.location.pathname}${queryParams ? `?${queryParams}` : ''}`

      if (newHistoryActions.includes(action.type)) {
        window.history.pushState(null, '', url)
      } else {
        window.history.replaceState(null, '', url)
      }
    }

    return result
  }
