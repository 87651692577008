import React from 'react'

function CheckboxGroup<T>({
  options,
  value = [],
  name,
  onChange,
  getValue,
  getLabel,
  disabled = false,
}: {
  options: T[]
  value?: string[]
  name: string
  getValue: (i: T) => string
  getLabel: (i: T) => string
  onChange: (i: string[]) => void
  disabled?: boolean
}) {
  return (
    <div>
      {options.map((o) => (
        <div key={getValue(o)} className="form-group">
          <div className="form-check form-check-inline input-checkbox">
            <input
              id={`${name}_${getValue(o)}`}
              className="input-checkbox__input"
              type="checkbox"
              checked={value.includes(getValue(o))}
              onChange={(e) =>
                onChange(
                  e.target.checked
                    ? [...value, getValue(o)]
                    : value.filter((v) => v !== getValue(o))
                )
              }
              disabled={disabled}
            />
            <label htmlFor={`${name}_${getValue(o)}`} className="input-checkbox__label">
              <span>{getLabel(o)}</span>
            </label>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CheckboxGroup
