import { getDaysBetween } from '~src/utils/date'

interface GetRemainingWarranty {
  isNew: boolean
  mileage: number
  firstAdmission: Date
}

export const getRemainingWarranty = ({ isNew, mileage, firstAdmission }: GetRemainingWarranty) => {
  const MAX_WARRANTY = 5
  if (isNew || !!!firstAdmission) {
    return undefined
  }

  const firstAdmissionDate = new Date(firstAdmission)
  const currentDate = new Date()

  const dueDate = new Date(firstAdmissionDate)
  dueDate.setFullYear(firstAdmissionDate.getFullYear() + 6)

  if (firstAdmissionDate.getFullYear() == new Date().getFullYear() && mileage < 50) {
    return 6
  }

  const remainingWarrantyInDays = getDaysBetween(currentDate, dueDate)
  const remainingWarrantyInYears = Math.max(0, Math.floor(remainingWarrantyInDays / 365))

  // Limit max returned value to the max of the warranty for occasion (5 years), because 6 years will be returned due to leap years for a 1 day old car
  return Math.min(remainingWarrantyInYears, MAX_WARRANTY)
}

export const determineWarrantyDate = (firstAdmission: Date, year: number): Date => {
  if (!firstAdmission && !year) {
    return undefined
  }

  const warrantyDate = firstAdmission ? firstAdmission : new Date(year, 11, 31)

  return warrantyDate
}
