import EmblaCarousel, { EmblaOptionsType } from 'embla-carousel'
import ClassNames from 'embla-carousel-class-names'
import { AlignmentOptionType } from 'embla-carousel/components/Alignment'
import { ui } from '~src/utils/ui'

type Elements = {
  component: HTMLDivElement
  viewportNode: HTMLDivElement
  nav: HTMLDivElement
  prevButton: HTMLButtonElement
  nextButton: HTMLButtonElement
  thumbNodes: HTMLButtonElement[]
}

ui(
  {
    component: '.js-embla',
    children: {
      viewportNode: '.embla__viewport',
      nav: '.embla__nav',
      prevButton: '.embla__prev',
      nextButton: '.embla__next',
      thumbNodes: ['.embla-thumbs__button'],
    },
  },
  ({ component, viewportNode, nav, prevButton, nextButton, thumbNodes }: Elements) => {
    if (!component || !viewportNode) return

    const { loop, mobileOnly, skipSnaps, align } = component.dataset

    const options: EmblaOptionsType = {
      loop: loop ? JSON.parse(loop) : false,
      align: align ? (align as AlignmentOptionType) : 'start',
      containScroll: 'trimSnaps',
      skipSnaps: skipSnaps ? JSON.parse(skipSnaps) : false,
      inViewThreshold: 1,
      ...(mobileOnly && {
        breakpoints: {
          '(min-width: 768px)': { active: false },
        },
      }),
    }

    const emblaApi = EmblaCarousel(viewportNode, options, [ClassNames()])

    const toggleActiveWhenScrollable = () => {
      const isScrollable = emblaApi.internalEngine().scrollSnaps.length > 1
      nav.classList.toggle('d-none', !isScrollable)
      emblaApi.reInit({ active: isScrollable })
    }

    if (nav) {
      emblaApi.on('resize', toggleActiveWhenScrollable)
      toggleActiveWhenScrollable()
    }

    if (thumbNodes.length > 0) {
      thumbNodes.forEach((thumb, index) =>
        thumb.addEventListener('click', () => emblaApi.scrollTo(index), false)
      )

      const toggleActiveState = () => {
        const previous = emblaApi.previousScrollSnap()
        const selected = emblaApi.selectedScrollSnap()
        thumbNodes[previous].classList.remove('active')
        thumbNodes[selected].classList.add('active')
      }

      emblaApi.on('select', toggleActiveState).on('init', toggleActiveState)
    }

    if (prevButton && nextButton) {
      const scrollPrev = () => emblaApi.scrollPrev()
      const scrollNext = () => emblaApi.scrollNext()
      prevButton.addEventListener('click', scrollPrev, false)
      nextButton.addEventListener('click', scrollNext, false)

      const togglePrevNextBtnsState = () => {
        if (emblaApi.canScrollPrev()) prevButton.removeAttribute('disabled')
        else prevButton.setAttribute('disabled', 'disabled')

        if (emblaApi.canScrollNext()) nextButton.removeAttribute('disabled')
        else nextButton.setAttribute('disabled', 'disabled')
      }

      emblaApi
        .on('select', togglePrevNextBtnsState)
        .on('init', togglePrevNextBtnsState)
        .on('reInit', togglePrevNextBtnsState)
    }
  }
)
