/***
 * @name Dealer Map - App
 *
 * @description Main application that loads API data and determines functionality based
 * upon number of dealers
 *
 */

// == Local imports
import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'
import { apiGet } from '~src/api/client'
import { IApp, IDealerInfo, SearchType } from '~src/apps/DealerMap/types'
import IGeolocation from '~src/apps/DealerMap/types/IGeolocation'
import { isSalesForm } from '~src/utils/dealer-form'
import GoogleMap from './GoogleMap'
import Loader from './Loader'
import SidePanel from './SidePanel'

// Used for debug and demonstration purposes
const MIN_DEALERS_FOR_SEARCH = 0

//
const App: React.FC<IApp> = ({
  overviewUrl,
  dealerApiUrl,
  dealerApiBaseUrl,
  dealerId,
  configuratorQueryString,
  googleApiKey,
  viewMode,
  productGroup,
  louwmanhubBrand,
  formType,
  filter: queryStringFilterString,
  dealerDetailUrl,
  subtitle,
  isModal = false,
}) => {
  //
  // == State:
  const NO_FILTER_PHRASE = 'Alles'
  const [dealer, setDealer] = useState<IDealerInfo>(null)
  const [dealers, setDealers] = useState<{ locations: IDealerInfo[] }>(null)
  const [allDealers, setAllDealers] = useState<{ locations: IDealerInfo[] }>(null) //Used to keep track of total list, for when filters are reset
  const [locations, setLocations] = useState<IDealerInfo[]>(null)
  const [geoCoords, setGeoCoords] = useState(null)
  const [addressKeyword, setAddressKeyword] = useState('')
  const [showInfo, setShowInfo] = useState(false)
  const [filter, setFilter] = useState(NO_FILTER_PHRASE)
  const [googleError, setGoogleError] = useState(false)
  const filterOptions: { [key: string]: string } = {
    all: NO_FILTER_PHRASE,
    motor_type_road: 'Wegmotoren',
    motor_type_atv: 'ATV',
    motor_type_cross: 'Motorcross',
  }
  const filteredDealersLocations =
    filter === NO_FILTER_PHRASE ? dealers?.locations : applyFilter(dealers.locations)
  const filteredLocations = filter === NO_FILTER_PHRASE ? locations : applyFilter(locations)

  // Filter dealers on any variable
  // Example: "is_after_sales_dealer:true,is_sales_dealer:true"
  const queryStringFilter: string[][] =
    queryStringFilterString && queryStringFilterString.split(',').map((i) => i.split(':'))

  // == Method: Fetch dealers via API
  const fetchDealers = async (keyword?: string) => {
    const response = await apiGet<IDealerInfo[]>(dealerApiUrl)

    // Remove dealers that don't have lat/long data and are inactive
    const newLocations = response.data.filter(
      (item) => item.location_lat !== null && item.location_long !== null && item.is_active == true
    )

    if (!newLocations.length) {
      throw new Error(`Dealer map didn't receive any locations (that had lat/long)`)
    }

    // Apply filters if available
    let queryFilteredLocations = !!queryStringFilterString
      ? newLocations.filter(
          // @ts-ignore
          (item) => !queryStringFilter.find((cur) => String(item[cur[0]]) != cur[1])
        )
      : newLocations

    //Set list of all found dealers, in order to reset after filtering by name without calling api again
    setAllDealers({
      locations: queryFilteredLocations.filter((e) =>
        isSalesForm(formType) ? e.is_sales_dealer : e
      ),
    })

    // Apply keyword filter if keyword is given
    if (keyword && keyword.trim() !== '') {
      queryFilteredLocations = queryFilteredLocations.filter((location) =>
        location.name.toLocaleLowerCase().includes(keyword.toLowerCase())
      )
    }

    setDealers({
      locations: queryFilteredLocations.filter((e) =>
        isSalesForm(formType) ? e.is_sales_dealer : e
      ),
    })
    setLocations(
      queryFilteredLocations.filter((e) => (isSalesForm(formType) ? e.is_sales_dealer : e))
    )
  }

  // == Method: Fetch one dealer, select it and show info
  const fetchSingleDealer = async () => {
    const response = await apiGet<IDealerInfo>(dealerApiBaseUrl + `dealers/${dealerId}/`)
    const newLocations = [response.data]
    setDealers({ locations: newLocations })
    setLocations(newLocations)
    setDealer(response.data)
    setShowInfo(true)
  }

  // == Method: Send postcode, receive a dealer number, and activate that dealer
  const fetchPostcodeResult = async (postcode: string) => {
    try {
      const response = await apiGet<IDealerInfo>(
        dealerApiBaseUrl + `dealers/by-postalcode/${louwmanhubBrand}/${postcode}/`
      )

      const foundDealer = filteredDealersLocations.find(
        (d: IDealerInfo) => d.dealer_number === response?.data?.dealer_number
      )

      if (foundDealer) {
        setDealer(foundDealer)
        setShowInfo(true)
      }
    } catch (error) {
      // Postcode not found, use map search
      setAddressKeyword(postcode)
    }
  }

  // == Method: Send location, receive a dealer number, and activate that dealer
  const fetchLocationResult = async ({
    latitude,
    longitude,
  }: {
    latitude: number
    longitude: number
  }) => {
    try {
      const response = await apiGet<IDealerInfo>(
        dealerApiBaseUrl + `dealers/by-location/${louwmanhubBrand}/${latitude}/${longitude}/`
      )

      const foundDealer = filteredDealersLocations.find(
        (d) => d.dealer_number === response?.data?.dealer_number
      )

      if (foundDealer) {
        setDealer(foundDealer)
        setShowInfo(true)
      }
    } catch (error) {
      // Can not find location in API
    }
  }

  // == Method - event handler: When map changed (by user) -> updated filtered list
  const viewChangeHandler = (items: IDealerInfo[]) => {
    setLocations(items)
  }

  // == Method - event handler: When a marker is selected -> show dealer information
  const markerSelectHandler = (item: IDealerInfo) => {
    setDealer(item)
    setShowInfo(true)
  }

  // == Method - event handler: When a dealer in the list is selected -> show dealer information
  const dealerSelectHandler = (id: number) => {
    const item = dealers.locations.find((el) => el.dealer_number === id)

    if (item) {
      setDealer(item)
      setShowInfo(true)
    }
  }

  // == Method - event handler:
  const dealerSearchHandler = (value?: string, searchType?: SearchType) => {
    const postcodeRegex = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i

    if (value) {
      if (searchType === 'location') {
        const postcode = value.replace(/\s/g, '').toUpperCase()

        if (postcodeRegex.test(postcode)) {
          // Search value is a postcode.
          // Remove spaces, set to uppercase, then fetch postcode result
          fetchPostcodeResult(postcode)
        } else {
          // Anything other than a postcode
          setAddressKeyword(value)
        }
        // Reset list to all dealers, in case it was filtered by name first
        setDealers(allDealers)
      } else if (searchType === 'dealer') {
        fetchDealers(value)
        setGeoCoords(null)
        setAddressKeyword('')
      }
    } else {
      // Empty value, reset
      setDealers(allDealers)
      setLocations([...allDealers.locations])
      setGeoCoords(null)
      setAddressKeyword('')
    }
  }

  // == Method - event handler: When go home is triggered -> Go back to list
  const goHomeHandler = () => {
    setShowInfo(false)
  }

  // Method - event handler:
  const geolocationRequestHandler = (coords: IGeolocation) => {
    setGeoCoords(coords)
    fetchLocationResult(coords)
  }

  // Method: Filter dealers on type
  function applyFilter(dealersList: IDealerInfo[]) {
    // Filter dealers on current filter type
    return dealersList.filter((d) => {
      // Get current filter type
      const filterType = Object.keys(filterOptions).find(
        (filterName) => filterOptions[filterName] === filter
      ) as keyof IDealerInfo

      // Return if dealer has filtertype
      return d[filterType] === true
    })
  }

  // == Life-cycle hook: When mounted -> fetch dealer(s) via API
  useEffect(() => {
    // If dealerId has been set, only fetch that one dealer
    if (dealerId) {
      fetchSingleDealer()
    } else {
      fetchDealers()
    }
  }, [])

  // === Life-cycle hook: Jump dealer info if only 1 location
  useEffect(() => {
    if (dealers && dealers.locations.length === 1 && dealer === null) {
      setShowInfo(true)
      setDealer(dealers.locations[0])
    }
  }, [dealers])

  // Not memoizing this will return an infinite loop since applyFilter always returns a new object
  const filteredDealerLocations = useMemo(
    () => (filter === NO_FILTER_PHRASE ? dealers?.locations : applyFilter(dealers.locations)),
    [filter, dealers]
  )

  // == Life-cycle hook: Render
  if (!locations) {
    return <Loader />
  }

  return (
    <div
      className={clsx(
        `dealers-map dealer-map--${productGroup}`,
        viewMode === 'contact_detail' && 'contact-detail'
      )}
    >
      <GoogleMap
        activeDealer={dealer}
        addressKeyword={addressKeyword}
        enableSearch={filteredDealersLocations.length >= MIN_DEALERS_FOR_SEARCH}
        googleApiKey={googleApiKey}
        geoCoords={geoCoords}
        markers={filteredDealerLocations}
        showInfo={showInfo}
        center={{ lat: 52.1326, lng: 5.2913 }}
        onItemSelect={markerSelectHandler}
        onViewChanged={filteredLocations.length >= MIN_DEALERS_FOR_SEARCH && viewChangeHandler}
        productGroup={productGroup}
        setGoogleError={setGoogleError}
        viewMode={viewMode}
      />
      <SidePanel
        overviewUrl={overviewUrl}
        dealer={dealer}
        enableSearch={filteredDealersLocations.length >= MIN_DEALERS_FOR_SEARCH}
        showDistances={addressKeyword !== '' || geoCoords !== null}
        locations={filteredLocations}
        showBackButton={viewMode !== 'contact_detail' && filteredDealersLocations.length > 1}
        showInfo={showInfo}
        viewMode={viewMode}
        //
        onDealerSelect={dealerSelectHandler}
        onDealerSearch={dealerSearchHandler}
        onGeolocationRequest={geolocationRequestHandler}
        onGoHome={goHomeHandler}
        productGroup={productGroup}
        filterOptions={filterOptions}
        filter={filter}
        setFilter={setFilter}
        googleError={googleError}
        formType={formType}
        queryStringFilter={queryStringFilter}
        dealerDetailUrl={dealerDetailUrl}
        subtitle={subtitle}
        configuratorQueryString={configuratorQueryString}
        isModal={isModal}
      />
    </div>
  )
}

export default App
