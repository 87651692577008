import React from 'react'
import ArrowLeftIcon from '~svg/arrow-left.svg'

const Pagination = ({
  onClickLeft,
  onClickRight,
  page,
  pageCount,
}: {
  onClickLeft?: () => void
  onClickRight?: () => void
  page: number
  pageCount: number
}) => {
  return (
    <nav className="pagination">
      <ul className="pagination__list">
        <li className={`pagination__item ${!onClickLeft ? 'pagination__item--disabled' : ''}`}>
          <button
            onClick={onClickLeft}
            className="pagination__button pagination__button--left"
            disabled={!onClickLeft}
            aria-label="vorige pagina"
          >
            <ArrowLeftIcon width={48} height={41} viewBox="0 0 48 41" />
          </button>
        </li>

        <li className="pagination__item">
          <div className="pagination__text">
            {page + 1} van {pageCount}
          </div>
        </li>
        <li className={`pagination__item ${!onClickRight ? 'pagination__item--disabled' : ''}`}>
          <button
            onClick={onClickRight}
            className="pagination__button pagination__button--right"
            disabled={!onClickRight}
            aria-label="volgende pagina"
          >
            <ArrowLeftIcon width={48} height={41} viewBox="0 0 48 41" />
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
