import React from 'react'
import { useDispatch } from 'react-redux'
import TradeInForm from '~src/apps/TradeIn/TradeInForm'
import { setTradeInModalStatus } from '~src/store/trade-in/actions'
import { CarTradeInModal } from './CarTradeInModal'

type Props = {
  modelApiUrl: string
  licensePlateApiUrl: string
  dealersApiUrl: string
  quoteApiUrl: string
  voucherFormApiUrl: string
  apiRootUrl: string
  prcodeApi: string
  louwmanHubBrand?: string
}

export const CarTradeInTool = ({
  modelApiUrl,
  licensePlateApiUrl,
  dealersApiUrl,
  quoteApiUrl,
  voucherFormApiUrl,
  apiRootUrl,
  prcodeApi,
  louwmanHubBrand,
}: Props) => {
  const dispatch = useDispatch()

  return (
    <>
      <TradeInForm
        licensePlateApiUrl={licensePlateApiUrl}
        onFormValid={(oldCar) => {
          if (oldCar.fuel_type === 'Diesel' || oldCar.mileage >= 200000) {
            dispatch(setTradeInModalStatus('no-trade-in-offer'))
          } else if (!oldCar.alternative_versions.length) {
            dispatch(setTradeInModalStatus('choose-new-car'))
          } else {
            dispatch(setTradeInModalStatus('request-trade-in-offer'))
          }
        }}
        prcodeApi={prcodeApi}
      />
      <CarTradeInModal
        modelApiUrl={modelApiUrl}
        licensePlateApiUrl={licensePlateApiUrl}
        dealersApiUrl={dealersApiUrl}
        quoteApiUrl={quoteApiUrl}
        voucherFormApiUrl={voucherFormApiUrl}
        apiRootUrl={apiRootUrl}
        prcodeApi={prcodeApi}
        louwmanHubBrand={louwmanHubBrand}
      />
    </>
  )
}
