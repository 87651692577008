import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { IAccessory, IColor, IEdition, IModelProducts, IPackage } from '~src/api/types/configurator'
import { RootState } from '~src/store'
import { setInitialState } from '~src/store/initialization'
import {
  addAccessory,
  addPackage,
  addUpsell,
  removeAccessory,
  removePackage,
  removeUpsell,
  setColor,
  setEdition,
  setModel,
  updateBasicConfiguration,
} from './actions'
import { defaultBasicConfiguration } from './initialState'
import { IBasicConfiguration, IConfigurationState } from './types'

export const initialData: IConfigurationState = {
  productGroup: 'car',
  model: null,
  basic: defaultBasicConfiguration,
  car: {
    edition: null,
    color: null,
    accessoryCodes: [],
    packageCodes: [],
    upsellCodes: [],
  },
}

const configurationReducer = createReducer(initialData, {
  [setModel.toString()]: (state, action: PayloadAction<IModelProducts>) => ({
    ...state,
    model: action.payload.code,
    car: {
      color: null,
      accessoryCodes: [],
      packageCodes: [],
      upsellCodes: [],
      edition: null,
    },
  }),
  [setEdition.toString()]: (state, action: PayloadAction<IEdition>) => ({
    ...state,
    car: {
      ...state.car,
      edition: action.payload.code,
      color: action.payload.colors[0].code,
      accessoryCodes: [],
      packageCodes: [],
      upsellCodes: [],
    },
  }),
  [addAccessory.toString()]: (state, action: PayloadAction<IAccessory>) => ({
    ...state,
    car: {
      ...state.car,
      accessoryCodes: [...state.car.accessoryCodes, action.payload.code],
    },
  }),
  [removeAccessory.toString()]: (state, action: PayloadAction<IAccessory>) => ({
    ...state,
    car: {
      ...state.car,
      accessoryCodes: state.car.accessoryCodes.filter((c) => c !== action.payload.code),
    },
  }),
  [addPackage.toString()]: (state, action: PayloadAction<IPackage>) => ({
    ...state,
    car: {
      ...state.car,
      packageCodes: [...state.car.packageCodes, action.payload.code],
    },
  }),
  [removePackage.toString()]: (state, action: PayloadAction<IPackage>) => ({
    ...state,
    car: {
      ...state.car,
      packageCodes: state.car.packageCodes.filter((c) => c !== action.payload.code),
    },
  }),
  [addUpsell.toString()]: (state, action: PayloadAction<string>) => ({
    ...state,
    car: {
      ...state.car,
      upsellCodes: [...state.car.upsellCodes, action.payload],
    },
  }),
  [removeUpsell.toString()]: (state, action: PayloadAction<string>) => ({
    ...state,
    car: {
      ...state.car,
      upsellCodes: state.car.upsellCodes.filter((c) => c !== action.payload),
    },
  }),
  [updateBasicConfiguration.toString()]: (state, action: PayloadAction<IBasicConfiguration>) => ({
    ...state,
    basic: {
      ...state.basic,
      ...action.payload,
    },
  }),
  [setColor.toString()]: (state, action: PayloadAction<IColor>) => ({
    ...state,
    car: {
      ...state.car,
      color: action.payload.code,
    },
  }),
  [setInitialState.toString()]: (state, action: PayloadAction<RootState>) => ({
    ...state,
    ...action.payload.configuration,
  }),
})

export default configurationReducer
