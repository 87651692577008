import { camelCase } from 'change-case'
import React, { createElement, FunctionComponent, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  components: Record<string, FunctionComponent<any>>
  componentSelector?: string
  propSelector?: string
}

const PortalComponents = ({
  components,
  componentSelector = 'data-component',
  propSelector = 'data-prop',
}: Props): JSX.Element => {
  const [, setRerender] = useState(false)

  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      const newDomComponents = mutations.some((m) =>
        [...m.addedNodes].some((m) => m instanceof Element && m.getAttribute(componentSelector))
      )
      if (newDomComponents) {
        setRerender((x) => !x)
      }
    })
    observer.observe(document, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [])

  return (
    <>
      {[...document.querySelectorAll(`[${componentSelector}]`)].map((domElement) => {
        const componentName = domElement.getAttribute(componentSelector)

        if (!(componentName && componentName in components)) {
          console.error(`Unregistered component ${componentName}`, domElement)
          return null
        }

        domElement.replaceChildren()

        return createPortal(
          createElement(components[componentName], getElementProps(domElement, propSelector)),
          domElement
        )
      })}
    </>
  )
}

export default PortalComponents

export const getElementProps = (el: Element, propSelector: string): Record<string, string> =>
  Object.fromEntries(
    Array.from(el.attributes).map((attribute) => [
      camelCase(attribute.name.replace(propSelector, '')),
      jsonTryParse(attribute.value),
    ])
  )

const jsonTryParse = (source: string) => {
  try {
    return JSON.parse(source)
  } catch {
    return source
  }
}
